import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { sendmailforgotpassword } from "../../api/authApi";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ForgotPasswordDesktopPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const changeEmail = (e) => {
    try {
      setEmail(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickSendPassword = async () => {
    try {
      if (email === "") {
        return toast.error("กรุณาระบุอีเมลของท่าน!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const resSendMail = await sendmailforgotpassword(email);
      if (resSendMail?.message === "ส่งอีเมลสำเร็จ") {
        setLoading(false);
        setTimeout(() => {
          history.replace("/");
        }, 3000);
        return toast.success("โปรดตรวจสอบรหัสผ่านทางอีเมลของท่าน", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ไม่พบอีเมลนี้ในระบบ!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div id="forgotpassword-desktop">
      <ToastContainer />
      <div className="bg-login">
        <div className="bg-login-right">
          <div className="container-resetpassword-desktop">
            <div className="heard-resetpassword">
              <img
                src="./logo_1.png"
                alt="logo"
                width={50}
                height={40}
                className="logo-login"
              />
              <div className="text-resetpassword-1">Forgot Password</div>
            </div>
            <input
              type="text"
              className="input-resetpassword-2"
              placeholder="โปรดระบุอีเมลที่ลงทะเบียน"
              value={email}
              onChange={changeEmail}
            />
            <button
              className="btn-login-2"
              onClick={clickSendPassword}
              disabled={loading}
            >
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                "ส่งรหัสผ่านใหม่"
              )}
            </button>
          </div>
        </div>
        <div className="bg-login-left">
          <img src="./bg_2.png" alt="logo" className="bg-login-desktop" />
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordDesktopPage;
