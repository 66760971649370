import React, { useState, useEffect, useCallback } from "react";
import MenuBarDesktop from "../../components/MenuBarDesktop";
import {
  all,
  callphone,
  brand,
  shade,
  film,
  size,
  province,
  district,
} from "../../api/productApi";
import { refreshtoken } from "../../api/authApi";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

const HomeDesktopPage = () => {
  // wood-stain = สีย้อมไม้, external = ภายนอก, inside = ภายใน, spray = สเปรย์, glazed-paint = สีเคลือบเงา
  const [typecolor, setTypeColor] = useState("all-data");
  const [masterpost, setMasterpost] = useState([]);
  const [postmatch, setPostMatch] = useState([]);
  const [masterbrand, setMasterBrand] = useState([]);
  const [mastershade, setMasterShade] = useState([]);
  const [masterfilm, setMasterFilm] = useState([]);
  const [mastersize, setMasterSize] = useState([]);
  const [masterbrandtemp, setMasterBrandTemp] = useState([]);
  const [mastershadetemp, setMasterShadeTemp] = useState([]);
  const [masterfilmtemp, setMasterFilmTemp] = useState([]);
  const [mastersizetemp, setMasterSizeTemp] = useState([]);
  const [mastershadeselect, setMasterShadeSelect] = useState([]);
  const [masterprovince, setMasterProvince] = useState([]);
  const [provinceshow, setProvinceShow] = useState([]);
  const [masterprovinceselect, setMasterProvinceSelect] = useState([]);
  const [masterdistrict, setMasterDistrict] = useState([]);
  const [masterdistricttemp, setMasterDistrictTemp] = useState([]);
  const [masterdistrictselect, setMasterDistrictSelect] = useState([]);
  const [showmodalbrand, setShowModalBrand] = useState(false);
  const [showmodalshade, setShowModalShade] = useState(false);
  const [showmodalfilmtype, setShowModalFilmType] = useState(false);
  const [showmodalsize, setShowModalSize] = useState(false);
  const [showmodalprovince, setShowModalProvince] = useState(false);
  const [showmodaldistrict, setShowModalDistrict] = useState(false);
  const [showmodalviewimage, setShowModalViewImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(false);
  const [amountsearch, setAmountSearch] = useState("");
  const [amountbrand, setAmountBrand] = useState([]);
  const [amountshade, setAmountShade] = useState([]);
  const [amountfilm, setAmountFilm] = useState([]);
  const [amountsize, setAmountSize] = useState([]);
  const [amountprovince, setAmountProvince] = useState([]);
  const [amountdistrict, setAmountDistrict] = useState([]);
  const [viewimage, setViewImage] = useState([]);
  let controllerRef = null;

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const postRes = await fetchAllPost(controllerRef?.signal);
      if (postRes) {
        if (typecolor === "all-data") {
          setPostMatch(postRes);
        } else if (typecolor === "wood-stain") {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of postRes.entries()) {
            if (
              JSON.stringify(item?.product_color)
                .toLowerCase()
                .includes("สีย้อมไม้") > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostMatch(dataSame);
        } else if (typecolor === "external") {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of postRes.entries()) {
            if (
              JSON.stringify(item?.product_color)
                .toLowerCase()
                .includes("สีน้ำ") > 0 &&
              JSON.stringify(item?.type_outside_inside)
                .toLowerCase()
                .includes("ภายนอก") > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostMatch(dataSame);
        } else if (typecolor === "inside") {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of postRes.entries()) {
            if (
              JSON.stringify(item?.product_color)
                .toLowerCase()
                .includes("สีน้ำ") > 0 &&
              JSON.stringify(item?.type_outside_inside)
                .toLowerCase()
                .includes("ภายใน") > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostMatch(dataSame);
        } else if (typecolor === "spray") {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of postRes.entries()) {
            if (
              JSON.stringify(item?.product_color)
                .toLowerCase()
                .includes("สเปรย์") > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostMatch(dataSame);
        } else if (typecolor === "spray-paint") {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of postRes.entries()) {
            if (
              JSON.stringify(item?.product_color)
                .toLowerCase()
                .includes("สีพ่นอุตสาหกรรม") > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostMatch(dataSame);
        } else if (typecolor === "glazed-paint") {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of postRes.entries()) {
            if (
              JSON.stringify(item?.product_color)
                .toLowerCase()
                .includes("สีเคลือบ") > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostMatch(dataSame);
        }
        setMasterpost(postRes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDataMaster = async () => {
    try {
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const brandRes = await fetchAllBrand(controllerRef?.signal);
      if (brandRes) {
        setMasterBrand(brandRes);
        setMasterBrandTemp(brandRes);
      }
      const shadeRes = await fetchAllShade(controllerRef?.signal);
      if (shadeRes) {
        setMasterShade(shadeRes);
        setMasterShadeTemp(shadeRes);
      }
      const filmRes = await fetchAllFilm(controllerRef?.signal);
      if (filmRes) {
        setMasterFilm(filmRes);
        setMasterFilmTemp(filmRes);
      }
      const sizeRes = await fetchAllSize(controllerRef?.signal);
      if (sizeRes) {
        setMasterSize(sizeRes);
        setMasterSizeTemp(sizeRes);
      }
      const provinceRes = await fetchAllProvince(controllerRef?.signal);
      if (provinceRes) {
        let data_clone = [...provinceRes];
        data_clone.sort((a, b) =>
          a.province_thai.localeCompare(b.province_thai, "th")
        );
        setMasterProvince(data_clone);
        setProvinceShow(data_clone);
      }
      const districtRes = await fetchAllDistrict(controllerRef?.signal);
      if (districtRes) {
        let data_clone = [...districtRes];
        data_clone.sort((a, b) =>
          a.district_thai.localeCompare(b.district_thai, "th")
        );
        setMasterDistrictTemp(data_clone);
        setMasterDistrict(data_clone);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllPost = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await all(signal);
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllBrand = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await brand(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllShade = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await shade(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllFilm = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await film(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllSize = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await size(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllProvince = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await province(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllDistrict = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await district(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const clickPhone = async (data) => {
    try {
      await refreshtoken(controllerRef?.signal);
      const resPhone = await callphone(data?.tel, data?.id);
      if (resPhone?.status_code === 201) {
        window.open(`tel:${data?.tel}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortDataAll = async () => {
    try {
      let master_temp = [];
      if (masterpost?.length > 0) {
        master_temp = [...masterpost];
      }

      // start check type product
      if (typecolor === "wood-stain") {
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            JSON.stringify(item?.product_color)
              .toLowerCase()
              .includes("สีย้อมไม้") > 0
          ) {
            dataSame.push(item);
          }
        }
        master_temp = dataSame;
      } else if (typecolor === "external") {
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            JSON.stringify(item?.product_color)
              .toLowerCase()
              .includes("สีน้ำ") > 0 &&
            JSON.stringify(item?.type_outside_inside)
              .toLowerCase()
              .includes("ภายนอก") > 0
          ) {
            dataSame.push(item);
          }
        }
        master_temp = dataSame;
      } else if (typecolor === "inside") {
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            JSON.stringify(item?.product_color)
              .toLowerCase()
              .includes("สีน้ำ") > 0 &&
            JSON.stringify(item?.type_outside_inside)
              .toLowerCase()
              .includes("ภายใน") > 0
          ) {
            dataSame.push(item);
          }
        }
        master_temp = dataSame;
      } else if (typecolor === "spray") {
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            JSON.stringify(item?.product_color)
              .toLowerCase()
              .includes("สเปรย์") > 0
          ) {
            dataSame.push(item);
          }
        }
        master_temp = dataSame;
      } else if (typecolor === "spray-paint") {
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            JSON.stringify(item?.product_color)
              .toLowerCase()
              .includes("สีพ่นอุตสาหกรรม") > 0
          ) {
            dataSame.push(item);
          }
        }
        master_temp = dataSame;
      } else if (typecolor === "glazed-paint") {
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of master_temp.entries()) {
          if (
            JSON.stringify(item?.product_color)
              .toLowerCase()
              .includes("สีเคลือบ") > 0
          ) {
            dataSame.push(item);
          }
        }
        master_temp = dataSame;
      }
      // end check type product

      // start check search
      if (amountsearch !== "" && amountsearch !== null) {
        // ค้นหาที่ master post
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of master_temp.entries()) {
          if (
            JSON.stringify(rmp?.product_name_th)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same.push(rmp);
          } else if (
            JSON.stringify(rmp?.number_batch)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_amount).includes(
              amountsearch.toLowerCase()
            ) > 0
          ) {
            data_same.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_shade)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_size)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same.push(rmp);
          } else if (
            JSON.stringify(rmp?.companyname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same.push(rmp);
          } else if (
            JSON.stringify(rmp?.provincename)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same.push(rmp);
          } else if (
            JSON.stringify(rmp?.districtname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same.push(rmp);
          }
        }
        const uniqueData = data_same.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        master_temp = uniqueData;
      }
      // end check search

      // start check brand
      if (amountbrand?.length > 0) {
        // ค้นหาที่ master post
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of master_temp.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountbrand.entries()) {
            if (
              JSON.stringify(rmp?.product_name_en)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same.push(rmp);
            }
          }
        }
        master_temp = data_same;
      }
      // end check brand

      // start check film type
      if (amountfilm?.length > 0) {
        // ค้นหาที่ master post
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of master_temp.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountfilm.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same.push(rmp);
            }
          }
        }
        master_temp = data_same;
      }
      // end check film type

      // start check size
      if (amountsize?.length > 0) {
        // ค้นหาที่ master post
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of master_temp.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountsize.entries()) {
            if (
              JSON.stringify(rmp?.product_size).includes(
                JSON.stringify(rs?.value_search)
              ) > 0
            ) {
              data_same.push(rmp);
            }
          }
        }
        master_temp = data_same;
      }
      // end check size

      // start check shade
      if (amountshade?.length > 0) {
        // ค้นหาที่ master post
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of master_temp.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountshade.entries()) {
            if (
              JSON.stringify(rmp?.product_shade)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same.push(rmp);
            }
          }
        }
        master_temp = data_same;
      }
      // end check shade

      // start check province
      if (amountprovince?.length > 0) {
        // ค้นหาที่ master post
        let data_same = [];
        let data_same_province = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of master_temp.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountprovince.entries()) {
            // eslint-disable-next-line no-unused-vars
            for await (const [_, rdt] of masterdistricttemp.entries()) {
              // เอาเฉพาะอำเภอที่ตรงกับจังหวัด
              if (
                JSON.stringify(rs?.province_id).includes(
                  JSON.stringify(rdt?.province_id)
                ) > 0
              ) {
                data_same_province.push(rdt);
              }
            }
            if (
              JSON.stringify(rmp?.province_id).includes(
                JSON.stringify(rs?.province_id)
              ) > 0
            ) {
              data_same.push(rmp);
            }
          }
        }
        const uniqueData = data_same_province.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        master_temp = data_same;
        setMasterDistrict(uniqueData);
      } else {
        setMasterDistrict(masterdistricttemp);
      }
      // end check province

      // start check district
      if (amountdistrict?.length > 0) {
        // ค้นหาที่ master post
        let data_same = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of master_temp.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountdistrict.entries()) {
            if (
              JSON.stringify(rmp?.district_id).includes(
                JSON.stringify(rs?.district_id)
              ) > 0
            ) {
              data_same.push(rmp);
            }
          }
        }
        master_temp = data_same;
      }
      // end check district

      setPostMatch(master_temp);
    } catch (error) {
      console.log(error);
    }
  };

  const clickSelectBrand = async () => {
    try {
      setLoading(true);
      if (masterbrand?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await masterbrand.filter((x) => x.checked === true);
        setAmountBrand(data_selete);
        setShowModalBrand(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectShade = async () => {
    try {
      setLoading(true);
      if (mastershade?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await mastershadeselect.filter(
          (x) => x.checked === true
        );
        setAmountShade(data_selete);
        setShowModalShade(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectFilmType = async () => {
    try {
      setLoading(true);
      if (masterfilm?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await masterfilm.filter((x) => x.checked === true);
        setAmountFilm(data_selete);
        setShowModalFilmType(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectSize = async () => {
    try {
      setLoading(true);
      if (mastersize?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await mastersize.filter((x) => x.checked === true);
        setAmountSize(data_selete);
        setShowModalSize(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectProvince = async () => {
    try {
      setLoading(true);
      if (provinceshow?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await masterprovinceselect.filter(
          (x) => x.checked === true
        );
        setAmountProvince(data_selete);
        setShowModalProvince(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectDistrict = async () => {
    try {
      setLoading(true);
      if (masterdistrict?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await masterdistrictselect.filter(
          (x) => x.checked === true
        );
        setAmountDistrict(data_selete);
        setShowModalDistrict(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeSearch = async (e) => {
    try {
      setLoading(true);
      setAmountSearch(e.target.value);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeBrand = async (data, index) => {
    try {
      let data_mock = [...masterbrand];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterBrand(data_mock);
    } catch (error) {
      console.log(error);
    }
  };

  const changeShade = async (e, data, index) => {
    try {
      let data_mock = [...mastershade];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterShade(data_mock);
      if (e.target.checked === true) {
        let data_clone = [...mastershadeselect];
        data_clone.push({ ...data, checked: e.target.checked });
        setMasterShadeSelect(data_clone);
      } else {
        let data_clone = [...mastershadeselect];
        let indexShade = data_clone.findIndex((x) => x.id === data?.id);
        data_clone.splice(indexShade, 1);
        setMasterShadeSelect(data_clone);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeFilm = async (data, index) => {
    try {
      let data_mock = [...masterfilm];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterFilm(data_mock);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSize = async (data, index) => {
    try {
      let data_mock = [...mastersize];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterSize(data_mock);
    } catch (error) {
      console.log(error);
    }
  };

  const changeProvince = async (e, data, index) => {
    try {
      let data_mock = [...provinceshow];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setProvinceShow(data_mock);
      if (e.target.checked === true) {
        let data_clone = [...masterprovinceselect];
        data_clone.push({ ...data, checked: e.target.checked });
        setMasterProvinceSelect(data_clone);
      } else {
        let data_clone = [...masterprovinceselect];
        let indexShade = data_clone.findIndex((x) => x.id === data?.id);
        data_clone.splice(indexShade, 1);
        setMasterProvinceSelect(data_clone);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeDistrict = async (e, data, index) => {
    try {
      let data_mock = [...masterdistrict];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterDistrict(data_mock);
      if (e.target.checked === true) {
        let data_clone = [...masterdistrictselect];
        data_clone.push({ ...data, checked: e.target.checked });
        setMasterDistrictSelect(data_clone);
      } else {
        let data_clone = [...masterdistrictselect];
        let indexShade = data_clone.findIndex((x) => x.id === data?.id);
        data_clone.splice(indexShade, 1);
        setMasterDistrictSelect(data_clone);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickViewImage = async (data) => {
    try {
      if (JSON.parse(data)?.length > 0) {
        let data_images = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rimg] of JSON.parse(data).entries()) {
          data_images.push({
            original: `${process.env.REACT_APP_API_HOST}/images/${rimg}`,
            thumbnail: `${process.env.REACT_APP_API_HOST}/images/${rimg}`,
          });
        }
        setViewImage(data_images);
        setShowModalViewImage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickSortDateNow = () => {
    try {
      setLoading(true);
      let data_clone = [...postmatch];
      data_clone.sort((a, b) => b.id - a.id);
      setPostMatch(data_clone);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSortDateLast = () => {
    try {
      setLoading(true);
      let data_clone = [...postmatch];
      data_clone.sort((a, b) => a.id - b.id);
      setPostMatch(data_clone);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSortFirstCharacter = () => {
    try {
      setLoading(true);
      let data_clone = [...postmatch];
      data_clone.sort((a, b) =>
        a.product_name_th.localeCompare(b.product_name_th, "th")
      );
      setPostMatch(data_clone);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSortLastCharacter = () => {
    try {
      setLoading(true);
      let data_clone = [...postmatch];
      data_clone.sort((a, b) =>
        b.product_name_th.localeCompare(a.product_name_th, "th")
      );
      setPostMatch(data_clone);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeSearchShade = async (e) => {
    try {
      setLoading(true);
      if (e.target.value?.length > 0) {
        let dataSame = [];
        const data_selete = await mastershadeselect.filter(
          (x) => x.checked === true
        );
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of mastershadetemp.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let new_data = {
              ...item,
              checked: !item.checked,
            };
            if (
              JSON.stringify(item?.product_shade)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(new_data);
            }
          } else {
            if (
              JSON.stringify(item?.product_shade)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            }
          }
        }
        setMasterShade(dataSame);
      } else {
        const data_selete = await mastershadeselect.filter(
          (x) => x.checked === true
        );
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of mastershadetemp.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let data_ok = {
              ...item,
              checked: !item?.checked,
            };
            dataSame.push(data_ok);
          } else {
            dataSame.push(item);
          }
        }
        setMasterShade(dataSame);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeSearchProvince = async (e) => {
    try {
      setLoading(true);
      if (e.target.value?.length > 0) {
        let dataSame = [];
        const data_selete = await masterprovinceselect.filter(
          (x) => x.checked === true
        );
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of masterprovince.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let new_data = {
              ...item,
              checked: !item.checked,
            };
            if (
              JSON.stringify(item?.province_thai)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(new_data);
            }
          } else {
            if (
              JSON.stringify(item?.province_thai)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            }
          }
        }
        setProvinceShow(dataSame);
      } else {
        const data_selete = await masterprovinceselect.filter(
          (x) => x.checked === true
        );
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of masterprovince.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let data_ok = {
              ...item,
              checked: !item?.checked,
            };
            dataSame.push(data_ok);
          } else {
            dataSame.push(item);
          }
        }
        setProvinceShow(dataSame);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeSearchDistrict = async (e) => {
    try {
      setLoading(true);
      if (e.target.value?.length > 0) {
        let dataSame = [];
        const data_selete = await masterdistrictselect.filter(
          (x) => x.checked === true
        );
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of masterdistricttemp.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let new_data = {
              ...item,
              checked: !item.checked,
            };
            if (
              JSON.stringify(item?.district_thai)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(new_data);
            }
          } else {
            if (
              JSON.stringify(item?.district_thai)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            }
          }
        }
        setMasterDistrict(dataSame);
      } else {
        const data_selete = await masterdistrictselect.filter(
          (x) => x.checked === true
        );
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of masterdistricttemp.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let data_ok = {
              ...item,
              checked: !item?.checked,
            };
            dataSame.push(data_ok);
          } else {
            dataSame.push(item);
          }
        }
        setMasterDistrict(dataSame);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickShowModalProvince = async () => {
    try {
      const data_selete = await amountprovince.filter(
        (x) => x.checked === true
      );
      let news_data = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of masterprovince.entries()) {
        const data_same = await data_selete.filter((x) => x.id === item?.id);
        if (data_same?.length > 0) {
          news_data.push({
            ...item,
            checked: data_same[0]?.checked,
          });
        } else {
          news_data.push(item);
        }
      }
      setProvinceShow(news_data);
      setShowModalProvince(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowModalDistrict = async () => {
    try {
      const data_selete = await amountdistrict.filter(
        (x) => x.checked === true
      );
      let news_data = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of masterdistrict.entries()) {
        const data_same = await data_selete.filter((x) => x.id === item?.id);
        if (data_same?.length > 0) {
          news_data.push({
            ...item,
            checked: data_same[0]?.checked,
          });
        } else {
          news_data.push(item);
        }
      }
      setMasterDistrict(news_data);
      setShowModalDistrict(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowModalShade = async () => {
    try {
      const data_selete = await amountshade.filter((x) => x.checked === true);
      let news_data = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of mastershadetemp.entries()) {
        const data_same = await data_selete.filter((x) => x.id === item?.id);
        if (data_same?.length > 0) {
          news_data.push({
            ...item,
            checked: data_same[0]?.checked,
          });
        } else {
          news_data.push(item);
        }
      }
      setMasterShade(news_data);
      setShowModalShade(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickClearSelectProduct = () => {
    try {
      setMasterBrand(masterbrandtemp);
      setMasterShade(mastershadetemp);
      setMasterShadeSelect([]);
      setMasterFilm(masterfilmtemp);
      setMasterSize(mastersizetemp);
      setAmountBrand([]);
      setAmountShade([]);
      setAmountFilm([]);
      setAmountSize([]);
    } catch (error) {
      console.log(error);
    }
  };

  const clickClearSelectArea = () => {
    try {
      setProvinceShow(masterprovince);
      setMasterProvinceSelect([]);
      setMasterDistrict(masterdistricttemp);
      setMasterDistrictSelect([]);
      setAmountProvince([]);
      setAmountDistrict([]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataDefault();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDataMaster();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortDataAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amountsearch,
    amountbrand,
    amountshade,
    amountfilm,
    amountsize,
    amountprovince,
    amountdistrict,
    typecolor,
  ]);

  return (
    <div id="home-desktop">
      <MenuBarDesktop getdata={getDataDefault} />
      <div className="container-home-search">
        <input
          type="text"
          className="input-home-1"
          placeholder="ค้นหาสินค้าที่ต้องการ"
          onChange={changeSearch}
        />
        <img
          src="./icon_7.png"
          alt="logo"
          width={18}
          height={18}
          className="logo-search"
        />
      </div>
      <div className="container-home-desktop-2">
        <div className="container-home-desktop-3">
          <div className="heard-home-4">
            <div className="container-home-desktop-5">
              <div className="container-home-select-product">
                <div className="text-home-12">เลือกสินค้า</div>
                {amountbrand?.length > 0 ||
                amountshade?.length > 0 ||
                amountfilm?.length > 0 ||
                amountsize?.length > 0 ? (
                  <button
                    className="btn-filerter-home-1"
                    onClick={clickClearSelectProduct}
                  >
                    ล้างค่า
                  </button>
                ) : null}
              </div>
            </div>
            <div
              className="container-home-desktop-5"
              onClick={() => setShowModalBrand(true)}
            >
              <div className="container-home-desktop-11">
                <div className="text-home-13">ยี่ห้อ/แบรนด์</div>
                {amountbrand?.length > 0 && (
                  <div className="text-home-26">{amountbrand?.length}</div>
                )}
              </div>
            </div>
            <div
              className="container-home-desktop-5"
              onClick={() => setShowModalFilmType(true)}
            >
              <div className="container-home-desktop-11">
                <div className="text-home-13">ประเภทฟิล์ม</div>
                {amountfilm?.length > 0 && (
                  <div className="text-home-26">{amountfilm?.length}</div>
                )}
              </div>
            </div>
            <div
              className="container-home-desktop-5"
              onClick={() => setShowModalSize(true)}
            >
              <div className="container-home-desktop-11">
                <div className="text-home-13">ขนาด</div>
                {amountsize?.length > 0 && (
                  <div className="text-home-26">{amountsize?.length}</div>
                )}
              </div>
            </div>
            <div
              className="container-home-desktop-6"
              onClick={clickShowModalShade}
            >
              <div className="container-home-desktop-11">
                <div className="text-home-13">เบอร์เฉด</div>
                {amountshade?.length > 0 && (
                  <div className="text-home-26">{amountshade?.length}</div>
                )}
              </div>
            </div>
          </div>
          <div className="heard-home-4">
            <div className="container-home-desktop-5">
              <div className="container-home-select-product">
                <div className="text-home-12">เลือกพื้นที่</div>
                {amountprovince?.length > 0 || amountdistrict?.length > 0 ? (
                  <button
                    className="btn-filerter-home-1"
                    onClick={clickClearSelectArea}
                  >
                    ล้างค่า
                  </button>
                ) : null}
              </div>
            </div>
            <div
              className="container-home-desktop-5"
              onClick={clickShowModalProvince}
            >
              <div className="container-home-desktop-11">
                <div className="text-home-13">จังหวัด</div>
                {amountprovince?.length > 0 && (
                  <div className="text-home-26">{amountprovince?.length}</div>
                )}
              </div>
            </div>
            <div
              className="container-home-desktop-6"
              onClick={clickShowModalDistrict}
            >
              <div className="container-home-desktop-11">
                <div className="text-home-13">เขต/อำเภอ</div>
                {amountdistrict?.length > 0 && (
                  <div className="text-home-26">{amountdistrict?.length}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container-home-desktop-4">
          <div className="container-home-desktop-7">
            <div
              className={
                typecolor === "all-data" ? "text-home-15" : "text-home-14"
              }
              onClick={() => setTypeColor("all-data")}
            >
              ทั้งหมด
            </div>
            <div
              className={
                typecolor === "external" ? "text-home-15" : "text-home-14"
              }
              onClick={() => setTypeColor("external")}
            >
              สีน้ำทาภายนอก
            </div>
            <div
              className={
                typecolor === "inside" ? "text-home-15" : "text-home-14"
              }
              onClick={() => setTypeColor("inside")}
            >
              สีน้ำทาภายใน
            </div>
            <div
              className={
                typecolor === "wood-stain" ? "text-home-15" : "text-home-14"
              }
              onClick={() => setTypeColor("wood-stain")}
            >
              สีย้อมไม้
            </div>
            <div
              className={
                typecolor === "glazed-paint" ? "text-home-15" : "text-home-14"
              }
              onClick={() => setTypeColor("glazed-paint")}
            >
              สีเคลือบเงา
            </div>
            <div
              className={
                typecolor === "spray-paint" ? "text-home-15" : "text-home-14"
              }
              onClick={() => setTypeColor("spray-paint")}
            >
              สีพ่นอุตสาหกรรม
            </div>
            <div
              className={
                typecolor === "spray" ? "text-home-15" : "text-home-14"
              }
              onClick={() => setTypeColor("spray")}
            >
              สเปรย์
            </div>
            <div
              className="container-home-desktop-11"
              onClick={() => setSort(!sort)}
            >
              <div className="text-home-13">เรียงลำดับ</div>
              <img
                src="./icon_10.png"
                alt="tools"
                className="logo-select"
                height={10}
                width={20}
              />
            </div>
            {sort === true && (
              <div className="container-home-9">
                <div className="text-home-20" onClick={clickSortDateNow}>
                  เรียงตามวันที่โพสต์ล่าสุด
                </div>
                <div className="text-home-20" onClick={clickSortDateLast}>
                  เรียงตามวันที่โพสต์ก่อนหน้า
                </div>
                <div className="text-home-20" onClick={clickSortFirstCharacter}>
                  เรียงตามตัวอักษร ก > ฮ
                </div>
                <div className="text-home-20" onClick={clickSortLastCharacter}>
                  เรียงตามตัวอักษร ฮ > ก
                </div>
              </div>
            )}
          </div>
          <div className="heard-home-5">
            {/* start body data */}
            {postmatch?.length > 0 ? (
              postmatch.map((item, index) => {
                return (
                  <div className="heard-home-6" key={index}>
                    <div
                      className="heard-home-16"
                      onClick={() => clickViewImage(item?.product_picture)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_HOST}/images/${
                          JSON.parse(item?.product_picture)[0]
                        }`}
                        height={170}
                        width={200}
                        alt="logo"
                        className="logo-image-home"
                      />
                      <div className="heard-home-12">
                        <div className="text-post-5">ดูเพิ่มเติม</div>
                        <img
                          src="./icon_18.png"
                          alt="view"
                          width={17}
                          height={15}
                          className="logo-phone-2"
                        />
                      </div>
                    </div>
                    <div className="heard-home-14">
                      <div className="text-home-16">
                        {item?.product_name_th}
                      </div>
                      <div className="text-home-34">
                        เฉดสี: {item?.product_shade}
                      </div>
                      <div className="text-home-34">
                        ขนาด: {item?.product_size}
                      </div>
                      <div className="text-home-34">
                        จำนวน: {item?.product_amount} ถัง
                      </div>
                      <div className="text-home-34">
                        Batch: {item?.number_batch}
                      </div>
                      <div className="heard-home-15">
                        <div className="text-home-18">การจัดส่ง</div>
                        <div className="text-home-34">
                          {item?.delivery_details === 1 &&
                            "ร้านค้าต้นทางจัดส่งสินค้าให้"}
                          {item?.delivery_details === 2 &&
                            "ร้านค้าปลายทางมารับสินค้า"}
                        </div>
                      </div>
                    </div>
                    <div className="heard-home-13">
                      <div className="text-home-32">{item?.companyname}</div>
                      <div className="heard-home-10">
                        <div className="text-home-32">
                          จังหวัด: {item?.provincename}
                        </div>
                        <div className="text-home-35">
                          เขต/อำเภอ: {item?.districtname}
                        </div>
                      </div>
                      <div
                        className="btn-post-home-5"
                        onClick={() => clickPhone(item)}
                      >
                        <img
                          src="./icon_11.png"
                          alt="phone"
                          width={23}
                          height={20}
                          className="logo-phone-2"
                        />
                        โทร: {item?.tel}
                      </div>
                      <div className="text-home-33">
                        หมายเหตุ: {item?.message_detail}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bottom-login">
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <div className="text-home-16">ไม่พบข้อมูล</div>
                )}
              </div>
            )}
            {/* end body data */}
          </div>
        </div>
      </div>
      {/* start modal brand */}
      <Modal
        show={showmodalbrand}
        onHide={() => setShowModalBrand(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">ยี่ห้อ/แบรนด์</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              {masterbrand?.length > 0 ? (
                masterbrand.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">
                        {item?.product_brand_name}
                      </div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={() => changeBrand(item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {masterbrand?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectBrand}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal brand */}
      {/* start modal film type */}
      <Modal
        show={showmodalfilmtype}
        onHide={() => setShowModalFilmType(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">ประเภทฟิล์ม</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              {masterfilm?.length > 0 ? (
                masterfilm.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">{item?.product_film}</div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={() => changeFilm(item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {masterfilm?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectFilmType}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal film type */}
      {/* start modal size */}
      <Modal
        show={showmodalsize}
        onHide={() => setShowModalSize(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">ขนาด</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              {mastersize?.length > 0 ? (
                mastersize.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">
                        {item?.product_size_name}
                      </div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={() => changeSize(item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {mastersize?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectSize}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal size */}
      {/* start modal shade */}
      <Modal
        show={showmodalshade}
        onHide={() => setShowModalShade(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">เบอร์เฉด</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="container-home-search-2">
              <input
                type="text"
                className="input-home-5"
                placeholder="ค้นหาสินค้าที่ต้องการ"
                onChange={changeSearchShade}
              />
              <img
                src="./icon_7.png"
                alt="logo"
                width={18}
                height={18}
                className="logo-search"
              />
            </div>
            <div className="body-select-8">
              {mastershade?.length > 0 ? (
                mastershade.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">{item?.product_shade}</div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={(e) => changeShade(e, item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {mastershade?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectShade}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal shade */}
      {/* start modal province */}
      <Modal
        show={showmodalprovince}
        onHide={() => setShowModalProvince(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">จังหวัด</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="container-home-search-2">
              <input
                type="text"
                className="input-home-5"
                placeholder="ค้นหาสินค้าที่ต้องการ"
                onChange={changeSearchProvince}
              />
              <img
                src="./icon_7.png"
                alt="logo"
                width={18}
                height={18}
                className="logo-search"
              />
            </div>
            <div className="body-select-8">
              {provinceshow?.length > 0 ? (
                provinceshow.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">{item?.province_thai}</div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={(e) => changeProvince(e, item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {provinceshow?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectProvince}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal province */}
      {/* start modal district */}
      <Modal
        show={showmodaldistrict}
        onHide={() => setShowModalDistrict(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">เขต/อำเภอ</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="container-home-search-2">
              <input
                type="text"
                className="input-home-5"
                placeholder="ค้นหาสินค้าที่ต้องการ"
                onChange={changeSearchDistrict}
              />
              <img
                src="./icon_7.png"
                alt="logo"
                width={18}
                height={18}
                className="logo-search"
              />
            </div>
            <div className="body-select-8">
              {masterdistrict?.length > 0 ? (
                masterdistrict.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">{item?.district_thai}</div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={(e) => changeDistrict(e, item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {masterdistrict?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectDistrict}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal district */}
      {/* start modal view image */}
      <Modal
        show={showmodalviewimage}
        onHide={() => setShowModalViewImage(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">รูปภาพ</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={viewimage} />
        </Modal.Body>
      </Modal>
      {/* end modal view image */}
    </div>
  );
};

export default HomeDesktopPage;
