import React from "react";

// router
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// guard auth
import PrivateRoute from "./guard/auth";

// pages mobile
import LoginMobilePage from "./pages/mobiles/LoginMobilePage";
import ForgotPasswordMobilePage from "./pages/mobiles/ForgotPasswordMobilePage";
import HomeMobilePage from "./pages/mobiles/HomeMobilePage";
import HistoryPostMobilePage from "./pages/mobiles/HistoryPostMobilePage";
import NewPasswordMobilePage from "./pages/mobiles/NewPasswordMobilePage";
import LoginEmployeeMobilePage from "./pages/mobiles/LoginEmployeeMobilePage";
import HomeEmployeeMobilePage from "./pages/mobiles/HomeEmployeeMobilePage";
import AdminMobilePage from "./pages/mobiles/AdminMobilePage";

// pages desktop
import LoginDesktopPage from "./pages/desktop/LoginDesktopPage";
import ForgotPasswordDesktopPage from "./pages/desktop/ForgotPasswordDesktopPage";
import HomeDesktopPage from "./pages/desktop/HomeDesktopPage";
import HistoryPostDesktopPage from "./pages/desktop/HistoryPostDesktopPage";
import NewPasswordDesktopPage from "./pages/desktop/NewPasswordDesktopPage";
import AdminDesktopPage from "./pages/desktop/AdminDesktopPage";
import ListStoreDesktopPage from "./pages/desktop/ListStoreDesktopPage";
import ListPostDesktopPage from "./pages/desktop/ListPostDesktopPage";
import ListBrandDesktopPage from "./pages/desktop/ListBrandDesktopPage";
import ListProductDesktopPage from "./pages/desktop/ListProductDesktopPage";
import ListFilmDesktopPage from "./pages/desktop/ListFilmDesktopPage";
import ListSizeDesktopPage from "./pages/desktop/ListSizeDesktopPage";
import ListShadeDesktopPage from "./pages/desktop/ListShadeDesktopPage";
import ListSalesGroupDesktopPage from "./pages/desktop/ListSalesGroupDesktopPage";
import LoginEmployeeDesktopPage from "./pages/desktop/LoginEmployeeDesktopPage";
import HomeEmployeeDesktopPage from "./pages/desktop/HomeEmployeeDesktopPage";
import ListEmployeeDesktopPage from "./pages/desktop/ListEmployeeDesktopPage";
import ReportPhoneDesktopPage from "./pages/desktop/ReportPhoneDesktopPage";
import ReportInOutDesktopPage from "./pages/desktop/ReportInOutDesktopPage";

// redux setup
import { Provider } from "react-redux";
import configureStore from "./redux/configureStore";

const { store } = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          {/* start admin */}
          <Route exact path="/admin">
            <AdminDesktopPage />
            <AdminMobilePage />
          </Route>
          <PrivateRoute exact path="/liststore">
            <ListStoreDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listemployee">
            <ListEmployeeDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listproduct">
            <ListProductDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listbrand">
            <ListBrandDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listfilm">
            <ListFilmDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listsize">
            <ListSizeDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listshade">
            <ListShadeDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listsalesgroup">
            <ListSalesGroupDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/reportphone">
            <ReportPhoneDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/reportinout">
            <ReportInOutDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/listpost">
            <ListPostDesktopPage />
          </PrivateRoute>
          {/* end admin */}
          {/* start employee */}
          <Route exact path="/employee">
            <LoginEmployeeDesktopPage />
            <LoginEmployeeMobilePage />
          </Route>
          <PrivateRoute exact path="/employeehome">
            <HomeEmployeeDesktopPage />
            <HomeEmployeeMobilePage />
          </PrivateRoute>
          {/* start employee */}
          <Route exact path="/">
            <LoginMobilePage />
            <LoginDesktopPage />
          </Route>
          <Route exact path="/forgotpassword">
            <ForgotPasswordMobilePage />
            <ForgotPasswordDesktopPage />
          </Route>
          <Route exact path="/newpassword/:token">
            <NewPasswordMobilePage />
            <NewPasswordDesktopPage />
          </Route>
          <PrivateRoute exact path="/home">
            <HomeMobilePage />
            <HomeDesktopPage />
          </PrivateRoute>
          <PrivateRoute exact path="/history">
            <HistoryPostMobilePage />
            <HistoryPostDesktopPage />
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
