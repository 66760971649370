import axios from "axios";
const prefix = "products";

const ALL_URL = `${prefix}/all`;
const ALL_SALESGROUP_URL = `${prefix}/allsalesgroup`;
const ACTIVEBYUSER_URL = `${prefix}/activebyuser`;
const INACTIVEBYUSER_URL = `${prefix}/inactivebyuser`;
const CLOSEPOST_URL = `${prefix}/closepost`;
const BYBARCODE_URL = `${prefix}/bybarcode`;
const CALLPHONE_URL = `${prefix}/callphone`;
const ANNOUNCE_URL = `${prefix}/announce`;
const UPDATEPOST_URL = `${prefix}/updatepost`;
const UPLOAD_URL = `${prefix}/upload`;
const PROVINCE_URL = `${prefix}/province`;
const DISTRICT_URL = `${prefix}/district`;
const BRAND_URL = `${prefix}/brand`;
const SHADE_URL = `${prefix}/shade`;
const FILM_URL = `${prefix}/film`;
const SIZE_URL = `${prefix}/size`;

export async function all(signal) {
  try {
    const { data } = await axios.get(ALL_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function allsalesgroup(signal) {
  try {
    const { data } = await axios.get(ALL_SALESGROUP_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function activebyuser(startdate, enddate, signal) {
  try {
    const { data } = await axios.post(ACTIVEBYUSER_URL, {
      startdate,
      enddate,
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function inactivebyuser(startdate, enddate, signal) {
  try {
    const { data } = await axios.post(INACTIVEBYUSER_URL, {
      startdate,
      enddate,
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function closepost(id, signal) {
  try {
    const { data } = await axios.put(CLOSEPOST_URL, {
      id,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function bybarcode(barcode) {
  try {
    const { data } = await axios.post(BYBARCODE_URL, {
      barcode: barcode,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function callphone(number_phone, post_match_id) {
  try {
    const { data } = await axios.post(CALLPHONE_URL, {
      number_phone: number_phone,
      post_match_id: post_match_id,
    });
    if (data?.status_code === 201) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function announce(
  barcode,
  product_name_th,
  product_name_en,
  product_size,
  product_shade,
  product_amount,
  number_batch,
  sales_group_id,
  province_id,
  district_id,
  delivery_details,
  message_detail,
  tel,
  product_picture
) {
  try {
    const { data } = await axios.post(ANNOUNCE_URL, {
      barcode: barcode,
      product_name_th: product_name_th,
      product_name_en: product_name_en,
      product_size: product_size,
      product_shade: product_shade,
      product_amount: product_amount,
      number_batch: number_batch,
      sales_group_id: sales_group_id,
      province_id: province_id,
      district_id: district_id,
      delivery_details: delivery_details,
      message_detail: message_detail,
      tel: tel,
      product_picture: product_picture,
    });
    if (data?.status_code === 201) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function updatepost(
  id,
  barcode,
  product_name_th,
  product_name_en,
  product_size,
  product_shade,
  product_amount,
  number_batch,
  sales_group_id,
  province_id,
  district_id,
  delivery_details,
  message_detail,
  tel,
  product_picture
) {
  try {
    const { data } = await axios.put(UPDATEPOST_URL, {
      id,
      barcode,
      product_name_th,
      product_name_en,
      product_size,
      product_shade,
      product_amount,
      number_batch,
      sales_group_id,
      province_id,
      district_id,
      delivery_details,
      message_detail,
      tel,
      product_picture,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function upload(photo) {
  try {
    const { data } = await axios.post(UPLOAD_URL, {
      photo: photo,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function province(signal) {
  try {
    const { data } = await axios.get(PROVINCE_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function district(signal) {
  try {
    const { data } = await axios.get(DISTRICT_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function brand(signal) {
  try {
    const { data } = await axios.get(BRAND_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function shade(signal) {
  try {
    const { data } = await axios.get(SHADE_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function film(signal) {
  try {
    const { data } = await axios.get(FILM_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function size(signal) {
  try {
    const { data } = await axios.get(SIZE_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}
