import React from "react";
import MenuBarAdminDesktop from "../../components/MenuBarAdminDesktop";
import MaterialTable from "material-table";

const ListPostDesktopPage = () => {
  const clickEdit = (data) => {
    try {
      console.log("data", data);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = (data) => {
    try {
      console.log("data", data);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <MenuBarAdminDesktop />
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 15,
          paddingBottom: 30,
        }}
      >
        <button className="btn-post-5">+ สร้าง</button>
        <MaterialTable
          title="โพสต์สินค้าทั้งหมด"
          localization={{
            body: {
              emptyDataSourceMessage: "ไม่พบข้อมูล",
            },
            toolbar: {
              searchPlaceholder: "ค้นหา...",
            },
            pagination: {
              labelRowsSelect: "แถว",
              firstTooltip: "หน้าแรก",
              previousTooltip: "หน้าที่แล้ว",
              nextTooltip: "หน้าต่อไป",
              lastTooltip: "หน้าสุดท้าย",
            },
            header: {
              actions: "Actions",
            },
          }}
          columns={[
            { title: "รหัส barcode", field: "barcode" },
            { title: "ชื่อสินค้า", field: "name" },
            { title: "ขนาด", field: "size" },
            { title: "เฉดสี", field: "shade" },
            { title: "จำนวน", field: "amount" },
            { title: "เลขที่ Batch", field: "batch" },
            { title: "จังหวัด", field: "province" },
            { title: "เขต/อำเภอ", field: "district" },
            { title: "ประเภทจัดส่ง", field: "delivery" },
            { title: "หมายเหตุ", field: "remark" },
          ]}
          data={[
            {
              barcode: "8850106484331",
              name: "7IN1 สีน้ำยืดหยุ่น กึ่งเงา ภายนอก",
              size: "3.75 ลิตร",
              shade: "#V101",
              amount: "15",
              batch: "F04014012900V101",
              province: "กรุงเทพมหานคร",
              district: "เขตพระนคร",
              delivery: "ร้านค้าต้นทางจัดส่งสินค้าให้",
              remark:
                "ร้านค้าที่ต้องการแลกเปลี่ยนสามารถมารับได้ ทุกวันจันทร์-เสา หยุดวัน อาทิตย์",
            },
            {
              barcode: "8850106484332",
              name: "7IN1 สีน้ำยืดหยุ่น กึ่งเงา ภายนอก",
              size: "3.75 ลิตร",
              shade: "#V101",
              amount: "15",
              batch: "F04014012900V101",
              province: "กรุงเทพมหานคร",
              district: "เขตพระนคร",
              delivery: "ร้านค้าต้นทางจัดส่งสินค้าให้",
              remark:
                "ร้านค้าที่ต้องการแลกเปลี่ยนสามารถมารับได้ ทุกวันจันทร์-เสา หยุดวัน อาทิตย์",
            },
            {
              barcode: "8850106484333",
              name: "7IN1 สีน้ำยืดหยุ่น กึ่งเงา ภายนอก",
              size: "3.75 ลิตร",
              shade: "#V101",
              amount: "15",
              batch: "F04014012900V101",
              province: "กรุงเทพมหานคร",
              district: "เขตพระนคร",
              delivery: "ร้านค้าต้นทางจัดส่งสินค้าให้",
              remark:
                "ร้านค้าที่ต้องการแลกเปลี่ยนสามารถมารับได้ ทุกวันจันทร์-เสา หยุดวัน อาทิตย์",
            },
            {
              barcode: "8850106484334",
              name: "7IN1 สีน้ำยืดหยุ่น กึ่งเงา ภายนอก",
              size: "3.75 ลิตร",
              shade: "#V101",
              amount: "15",
              batch: "F04014012900V101",
              province: "กรุงเทพมหานคร",
              district: "เขตพระนคร",
              delivery: "ร้านค้าต้นทางจัดส่งสินค้าให้",
              remark:
                "ร้านค้าที่ต้องการแลกเปลี่ยนสามารถมารับได้ ทุกวันจันทร์-เสา หยุดวัน อาทิตย์",
            },
            {
              barcode: "8850106484335",
              name: "7IN1 สีน้ำยืดหยุ่น กึ่งเงา ภายนอก",
              size: "3.75 ลิตร",
              shade: "#V101",
              amount: "15",
              batch: "F04014012900V101",
              province: "กรุงเทพมหานคร",
              district: "เขตพระนคร",
              delivery: "ร้านค้าต้นทางจัดส่งสินค้าให้",
              remark:
                "ร้านค้าที่ต้องการแลกเปลี่ยนสามารถมารับได้ ทุกวันจันทร์-เสา หยุดวัน อาทิตย์",
            },
          ]}
          actions={[
            {
              icon: "save",
              tooltip: "Save User",
              onClick: (event, rowData) => "",
            },
          ]}
          components={{
            Action: (props) => (
              <>
                <button
                  onClick={(event) => clickEdit(props.data)}
                  className="btn-post-3"
                >
                  แก้ไข
                </button>
                <button
                  onClick={(event) => clickDelete(props.data)}
                  className="btn-post-4"
                >
                  ลบ
                </button>
              </>
            ),
          }}
        />
      </div>
    </>
  );
};

export default ListPostDesktopPage;
