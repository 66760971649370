export default async function setupAxios(axios) {
  axios.interceptors.request.use(
    async (config) => {
      config.baseURL = `${process.env.REACT_APP_API_HOST}/api/v1/`;
      if (config.url === "users/refresh-token") {
        const localStorageToken = await localStorage.getItem("refreshtoken");
        if (localStorageToken !== null) {
          config.headers.Authorization = `Bearer ${JSON.parse(
            localStorageToken
          )}`;
        }
      } else if (config.url === "users/forgot-password") {
        const tokenNewPassword = window.location.href.split("newpassword/");
        if (tokenNewPassword[1] !== null) {
          config.headers.Authorization = `Bearer ${tokenNewPassword[1]}`;
        }
      } else {
        const authToken = await localStorage.getItem("authToken");
        if (authToken !== null) {
          config.headers.Authorization = `Bearer ${JSON.parse(authToken)}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.error === "Unauthorized"
      ) {
        console.log(error);
        // localStorage.clear();
        // window.location.reload();
      }
      return Promise.reject(error);
    }
  );
}
