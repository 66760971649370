import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { refreshtoken } from "../api/authApi";
import { forgotpasswordadminauthen } from "../api/adminApi";
import { Spinner } from "react-bootstrap";

const MenuBarAdminDesktop = ({ getdata }) => {
  const [menu, setMenu] = useState(false);
  const history = useHistory();
  const [logoutmodal, setLogOutModal] = useState(false);
  const [showmodalnewpassword, setShowModalNewPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  let controllerRef = null;
  controllerRef = new AbortController();

  const clickMenu = () => {
    try {
      setMenu(!menu);
    } catch (error) {
      console.log(error);
    }
  };

  const clickStore = () => {
    try {
      history.replace("/liststore");
    } catch (error) {
      console.log(error);
    }
  };

  const clickEmployee = () => {
    try {
      history.replace("/listemployee");
    } catch (error) {
      console.log(error);
    }
  };

  const clickProduct = () => {
    try {
      history.replace("/listproduct");
    } catch (error) {
      console.log(error);
    }
  };

  const clickBrand = () => {
    try {
      history.replace("/listbrand");
    } catch (error) {
      console.log(error);
    }
  };

  const clickFilm = () => {
    try {
      history.replace("/listfilm");
    } catch (error) {
      console.log(error);
    }
  };

  const clickSize = () => {
    try {
      history.replace("/listsize");
    } catch (error) {
      console.log(error);
    }
  };

  const clickShade = () => {
    try {
      history.replace("/listshade");
    } catch (error) {
      console.log(error);
    }
  };

  const clickSalesGroup = () => {
    try {
      history.replace("/listsalesgroup");
    } catch (error) {
      console.log(error);
    }
  };

  // const clickPost = () => {
  //   try {
  //     history.replace("/listpost");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const clickConfirmLogout = async () => {
    try {
      // await refreshtoken(controllerRef?.signal);
      // await logout();
      setMenu(!menu);
      setLogOutModal(false);
      history.replace("/admin");
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelLogout = () => {
    try {
      setLogOutModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = (e) => {
    try {
      setPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeConfirmPassword = (e) => {
    try {
      setConfirmPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirm = async () => {
    try {
      if (password === "" || password === null || password.length < 6) {
        return toast.error("กรุณาระบุรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (
        confirmpassword === "" ||
        confirmpassword === null ||
        confirmpassword.length < 6
      ) {
        return toast.error("กรุณาระบุยืนยันรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      await refreshtoken(controllerRef?.signal);
      const resForgotPassword = await forgotpasswordadminauthen(
        password,
        confirmpassword
      );
      if (resForgotPassword?.status_code === 200) {
        setLoading(false);
        setShowModalNewPassword(false);
        toast.success(resForgotPassword?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickReportPhone = () => {
    try {
      history.replace("/reportphone");
    } catch (error) {
      console.log(error);
    }
  };

  const clickReportInOut = () => {
    try {
      history.replace("/reportinout");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="heard-home-desktop">
      <ToastContainer />
      {/* start modal logout */}
      <Modal
        show={logoutmodal}
        onHide={() => setLogOutModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              <div className="text-home-20">คุณต้องการออกจากระบบ?</div>
              <button className="btn-post-1" onClick={clickConfirmLogout}>
                ยืนยัน
              </button>
              <button className="btn-post-2" onClick={clickCancelLogout}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal logout */}
      {/* start modal newpassword */}
      <Modal
        show={showmodalnewpassword}
        onHide={() => setShowModalNewPassword(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">New Password</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-home-desktop-2">
            <div className="content-home-desktop-1">
              <input
                type="password"
                className="input-resetpassword-3"
                placeholder="รหัสผ่าน *"
                value={password}
                onChange={changePassword}
              />
              <input
                type="password"
                className="input-resetpassword-2"
                placeholder="ยืนยันรหัสผ่าน *"
                value={confirmpassword}
                onChange={changeConfirmPassword}
              />
              <button
                className="btn-login-2"
                onClick={clickConfirm}
                disabled={loading}
              >
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "ยืนยัน"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal newpassword */}
      <img
        src="./logo_1.png"
        alt="logo"
        width={50}
        height={42}
        className="logo-toa"
      />
      <div className="text-home-11" onClick={clickStore}>
        ร้านค้า
      </div>
      <div className="text-home-11" onClick={clickEmployee}>
        พนักงาน
      </div>
      <div className="dropdown">
        <div className="text-home-11 dropbtn">ข้อมูลหลัก</div>
        <div className="dropdown-content">
          <div className="text-home-36" onClick={clickProduct}>
            สินค้า
          </div>
          <div className="text-home-36" onClick={clickBrand}>
            ยี่ห้อ/แบรนด์
          </div>
          <div className="text-home-36" onClick={clickFilm}>
            ประเภทฟิล์ม
          </div>
          <div className="text-home-36" onClick={clickSize}>
            ขนาด
          </div>
          <div className="text-home-36" onClick={clickShade}>
            เบอร์เฉด
          </div>
          <div className="text-home-36" onClick={clickSalesGroup}>
            กลุ่มงานขาย
          </div>
        </div>
      </div>
      <div className="dropdown">
        <div className="text-home-11 dropbtn">รายงาน</div>
        <div className="dropdown-content">
          <div className="text-home-36" onClick={clickReportPhone}>
            ร้านค้าโทรออก
          </div>
          <div className="text-home-36" onClick={clickReportInOut}>
            ร้านค้าเข้า-ออก
          </div>
        </div>
      </div>
      <img
        src="./icon_9.png"
        alt="logo"
        width={30}
        height={27}
        className="logo-profile"
        onClick={clickMenu}
      />
      <div
        className="container-home-8"
        style={{ display: menu === true ? "block" : "none" }}
      >
        {/* <div className="heard-home-11">
          <img
            src="./icon_13.png"
            alt="logo"
            height={16}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
            }}
          >
            โปรไฟล์
          </div>
        </div> */}
        <div className="heard-home-11">
          <img
            src="./icon_14.png"
            alt="logo"
            width={20}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
              setPassword("");
              setConfirmPassword("");
              setShowModalNewPassword(true);
            }}
          >
            เปลี่ยนรหัสผ่าน
          </div>
        </div>
        <div className="heard-home-11">
          <img
            src="./icon_15.png"
            alt="logo"
            height={20}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
              setLogOutModal(true);
            }}
          >
            ออกจากระบบ
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBarAdminDesktop;
