import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { loginadmin, profileadmin } from "../../api/authApi";

const AdminDesktopPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  let controllerRef = null;

  useEffect(() => {
    if (
      localStorage.getItem("authToken") !== null ||
      localStorage.getItem("refreshtoken") !== null
    ) {
      localStorage.clear();
    }
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeUsername = (e) => {
    try {
      setUsername(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = (e) => {
    try {
      setPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickLogin = async () => {
    try {
      if (username === "") {
        return toast.error("กรุณาระบุรหัสร้านค้า,รหัสพนักงาน!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (password.length < 6) {
        return toast.error("กรุณาระบุรหัสผ่านอย่างน้อย 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const resLogin = await loginadmin(username, password);
      if (resLogin !== null) {
        localStorage.setItem(
          "authToken",
          JSON.stringify(resLogin?.access_token)
        );
        localStorage.setItem(
          "refreshtoken",
          JSON.stringify(resLogin?.refresh_token)
        );
        // eslint-disable-next-line no-undef
        controllerRef = new AbortController();
        const resProfile = await profileadmin(controllerRef?.signal);
        if (resProfile?.id > 0) {
          localStorage.setItem("profile", JSON.stringify(resProfile));
        }
        setLoading(false);
        setTimeout(() => {
          history.replace("/liststore");
        }, 300);
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาตรวจสอบข้อมูล!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div id="login-desktop">
      <div className="bg-login">
        <ToastContainer />
        <div className="bg-admin-left">
          <img src="./bg_3.png" alt="logo" className="bg-login-desktop" />
        </div>
        <div className="bg-login-right">
          <div className="container-bottom-login">
            <div className="bottom-login">
              <img
                src="./logo_1.png"
                alt="logo"
                width={70}
                height={62}
                className="logo-login"
              />
              <div className="text-login-4">Admin / Sign in</div>
              <input
                type="text"
                className="input-login-3"
                placeholder="รหัสพนักงาน"
                value={username}
                onChange={changeUsername}
              />
              <input
                type="password"
                className="input-login-3"
                placeholder="รหัสผ่าน"
                value={password}
                onChange={changePassword}
              />
              <button
                className="btn-login-2"
                onClick={clickLogin}
                disabled={loading}
              >
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "เข้าสู่ระบบ"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDesktopPage;
