import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { login, profile } from "../../api/authApi";

const LoginMobilePage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  let controllerRef = null;

  useEffect(() => {
    if (
      localStorage.getItem("authToken") !== null ||
      localStorage.getItem("refreshtoken") !== null
    ) {
      localStorage.clear();
    }
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeUsername = (e) => {
    try {
      setUsername(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = (e) => {
    try {
      setPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickLogin = async () => {
    try {
      if (username === "") {
        return toast.error("กรุณาระบุรหัสร้านค้า,รหัสพนักงาน!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (password.length < 6) {
        return toast.error("กรุณาระบุรหัสผ่านอย่างน้อย 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const resLogin = await login(username, password);
      if (resLogin !== null) {
        localStorage.setItem(
          "authToken",
          JSON.stringify(resLogin?.access_token)
        );
        localStorage.setItem(
          "refreshtoken",
          JSON.stringify(resLogin?.refresh_token)
        );
        // eslint-disable-next-line no-undef
        controllerRef = new AbortController();
        const resProfile = await profile(controllerRef?.signal);
        if (resProfile?.token?.id > 0) {
          localStorage.setItem("profile", JSON.stringify(resProfile));
        }
        setLoading(false);
        setTimeout(() => {
          history.replace("/home");
        }, 300);
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาตรวจสอบข้อมูล!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const clickForgotPassword = () => {
  //   try {
  //     history.push("/forgotpassword");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <div id="login-mobile">
      <ToastContainer />
      <div className="heard-login" />
      <div className="bottom-login">
        <img
          src="./logo_1.png"
          alt="logo"
          width={50}
          height={42}
          className="logo-login"
        />
        <div className="text-login-1">Hello, Welcome to MDC</div>
        <input
          type="text"
          className="input-login-1"
          placeholder="รหัสร้านค้า"
          value={username}
          onChange={changeUsername}
        />
        <input
          type="password"
          className="input-login-2"
          placeholder="รหัสผ่าน"
          value={password}
          onChange={changePassword}
        />
        <button className="btn-login" onClick={clickLogin} disabled={loading}>
          {loading === true ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            "เข้าสู่ระบบ"
          )}
        </button>
      </div>
      {/* <div className="footer-login">
        <div className="text-login-3" onClick={clickForgotPassword}>
          ลืมรหัสผ่าน?
        </div>
      </div> */}
    </div>
  );
};

export default LoginMobilePage;
