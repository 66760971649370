import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import * as http from "../src/axios";

// set axios
http.setupAxios(axios);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<App />);
