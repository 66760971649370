import React, { useState, useEffect, useCallback } from "react";
import MenuBarAdminDesktop from "../../components/MenuBarAdminDesktop";
import MaterialTable from "material-table";
import { refreshtoken } from "../../api/authApi";
import {
  store,
  provinceadmin,
  districtadmin,
  salesgroupadmin,
  registerstore,
  updatestatusstore,
  deletestore,
  updatestore,
} from "../../api/adminApi";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

const ListStoreDesktopPage = () => {
  const [loading, setLoading] = useState(false);
  const [allstore, setAllStore] = useState([]);
  const [showmodalstore, setShowModalStore] = useState(false);
  const [showmodaldelete, setShowModalDelete] = useState(false);
  const [showmodaledit, setShowModalEdit] = useState(false);
  const [numberstore, setNumBerStore] = useState("");
  const [namestore, setNameStore] = useState("");
  const [passwordstore, setPasswordStore] = useState("");
  const [emailstore, setEmailStore] = useState("");
  const [phonestore, setPhoneStore] = useState("");
  const [provincestore, setProVinceStore] = useState([]);
  const [masterdistrictstore, setMasterDistrictStore] = useState([]);
  const [disabledistrictstore, setDisableDistrictStore] = useState(true);
  const [districtstore, setDistrictStore] = useState([]);
  const [groupsalestore, setGroupSaleStore] = useState([]);
  const [statusstore, setStatusStore] = useState(true);
  const [selectprovince, setSelectProvince] = useState({});
  const [selectdistrict, setSelectDistrict] = useState({});
  const [selectgroupsale, setSelectGroupSale] = useState({});
  const [datadelete, setDataDelete] = useState({});
  const [editnumberstore, setEditNumBerStore] = useState("");
  const [editpasswordstore, setEditPasswordStore] = useState("");
  const [editnamestore, setEditNameStore] = useState("");
  const [editemailstore, setEditEmailStore] = useState("");
  const [editphonestore, setEditPhoneStore] = useState("");
  const [editselectgroupsale, setEditSelectGroupSale] = useState({});
  const [editselectprovince, setEditSelectProvince] = useState({});
  const [editselectdistrict, setEditSelectDistrict] = useState({});
  const [editstatusstore, setEditStatusStore] = useState(true);
  const [editdisabledistrictstore, setEditDisableDistrictStore] =
    useState(true);

  let controllerRef = null;

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #b5adad",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 250,
      marginLeft: 19,
      height: 40,
      fontSize: 14,
      fontFamily: "Kanit",
      "&:hover": {
        border: "2px solid #1C5BBD",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C5BBD" : "white",
      fontFamily: "Kanit",
      fontSize: 14,
      color: state.isSelected ? "white" : "#1C5BBD",
      "&:hover": {
        backgroundColor: "#1C5BBD",
        color: "#FFFFFF",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1C5BBD",
    }),
  };

  const customstyleserror = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      border: "2px solid red",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 250,
      marginLeft: 19,
      height: 40,
      fontSize: 14,
      fontFamily: "Kanit",
      "&:hover": {
        border: "2px solid #1C5BBD",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C5BBD" : "white",
      fontFamily: "Kanit",
      fontSize: 14,
      color: state.isSelected ? "white" : "#1C5BBD",
      "&:hover": {
        backgroundColor: "#1C5BBD",
        color: "#FFFFFF",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1C5BBD",
    }),
  };

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const storeRes = await fetchAllStore(controllerRef?.signal);
      if (storeRes) {
        setAllStore(storeRes);
      }
      const provineRes = await fetchAllProvine(controllerRef?.signal);
      if (provineRes) {
        setProVinceStore(provineRes);
      }
      const districtRes = await fetchAllDistrict(controllerRef?.signal);
      if (districtRes) {
        setDistrictStore(districtRes);
        setMasterDistrictStore(districtRes);
      }
      const salesgroupRes = await fetchAllSalesGroup(controllerRef?.signal);
      if (salesgroupRes) {
        setGroupSaleStore(salesgroupRes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAllStore = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await store(signal);
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllProvine = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await provinceadmin(signal);
      let mock = [];
      _data.map((item) => {
        mock.push({
          value: item?.province_id,
          label: item?.province_thai,
        });
        return null;
      });
      if (mock) {
        return mock;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllDistrict = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await districtadmin(signal);
      let mock = [];
      _data.map((item) => {
        mock.push({
          value: item?.district_id,
          label: item?.district_thai,
          province_id: item?.province_id,
        });
        return null;
      });
      if (mock) {
        return mock;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllSalesGroup = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await salesgroupadmin(signal);
      let mock = [];
      _data.map((item) => {
        mock.push({
          value: item?.sales_group_id,
          label: item?.sales_group_description,
        });
        return null;
      });
      if (mock) {
        return mock;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const tableOptions = {
    actionsColumnIndex: 0, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 10, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [10, 30, 50, 100], // rows selection options
  };

  useEffect(() => {
    getDataDefault();

    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickEdit = async (data) => {
    try {
      let mock_phonestore = "";
      if (data?.tel !== null) {
        const myArray = data?.tel.split("-");
        mock_phonestore = myArray[0] + myArray[1] + myArray[2];
      }
      setEditNumBerStore(data?.bp_code);
      setEditNameStore(data?.name);
      setEditEmailStore(data?.email !== null ? data?.email : "");
      setEditPhoneStore(mock_phonestore);
      setEditSelectGroupSale({
        value: JSON.stringify(data?.sales_group_id),
        label: data?.sales_group_description,
      });
      setEditSelectProvince({
        value: data?.province_id,
        label: data?.province_name,
      });
      setEditSelectDistrict({
        value: data?.district_id,
        label: data?.district_name,
      });
      setEditStatusStore(data?.store_status === 1 ? true : false);
      setEditPasswordStore("");
      let data_mock = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, r] of masterdistrictstore.entries()) {
        if (r?.province_id === data?.province_id) {
          data_mock.push(r);
        }
      }
      setDistrictStore(data_mock);
      setEditDisableDistrictStore(false);
      setShowModalEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmDelete = async () => {
    try {
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resDelete = await deletestore(
        datadelete?.id,
        controllerRef?.signal
      );
      if (resDelete?.status_code === 200) {
        const storeRes = await fetchAllStore(controllerRef?.signal);
        if (storeRes) {
          setAllStore(storeRes);
          setShowModalDelete(false);
          return successAlert(resDelete?.message);
        }
      } else {
        return errorAlert("ผิดพลาด กรุณาลองใหม่อีกครั้ง!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelDelete = () => {
    try {
      setShowModalDelete(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = async (data) => {
    try {
      setDataDelete(data);
      setShowModalDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeNumberStore = (e) => {
    try {
      if (!e.target.value.match(/^([0-9])+$/i)) {
        setNumBerStore("");
      } else {
        setNumBerStore(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditNumberStore = (e) => {
    try {
      if (!e.target.value.match(/^([0-9])+$/i)) {
        setEditNumBerStore("");
      } else {
        setEditNumBerStore(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeNameStore = (e) => {
    try {
      setNameStore(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditNameStore = (e) => {
    try {
      setEditNameStore(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changePasswordStore = (e) => {
    try {
      setPasswordStore(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditPasswordStore = (e) => {
    try {
      setEditPasswordStore(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEmailStore = (e) => {
    try {
      setEmailStore(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditEmailStore = (e) => {
    try {
      setEditEmailStore(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changePhoneStore = (e) => {
    try {
      if (!e.target.value.match(/^([0-9])+$/i)) {
        setPhoneStore("");
      } else {
        setPhoneStore(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditPhoneStore = (e) => {
    try {
      if (!e.target.value.match(/^([0-9])+$/i)) {
        setEditPhoneStore("");
      } else {
        setEditPhoneStore(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusStore = (e) => {
    try {
      setStatusStore(e.target.checked);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditStatusStore = (e) => {
    try {
      setEditStatusStore(e.target.checked);
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancel = () => {
    try {
      setShowModalStore(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clickEditCancel = () => {
    try {
      setShowModalEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectProvince = async (data) => {
    try {
      setSelectProvince(data);
      if (data !== null) {
        let data_mock = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, r] of masterdistrictstore.entries()) {
          if (r?.province_id === data?.value) {
            data_mock.push(r);
          }
        }
        setDistrictStore(data_mock);
        setSelectDistrict({});
        setDisableDistrictStore(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditSelectProvince = async (data) => {
    try {
      setEditSelectProvince(data);
      if (data !== null) {
        let data_mock = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, r] of masterdistrictstore.entries()) {
          if (r?.province_id === data?.value) {
            data_mock.push(r);
          }
        }
        setDistrictStore(data_mock);
        setEditSelectDistrict({});
        setEditDisableDistrictStore(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectDistrict = (data) => {
    try {
      setSelectDistrict(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditSelectDistrict = (data) => {
    try {
      setEditSelectDistrict(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectGroupSale = (data) => {
    try {
      setSelectGroupSale(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditSelectGroupSale = (data) => {
    try {
      setEditSelectGroupSale(data);
    } catch (error) {
      console.log(error);
    }
  };

  const errorAlert = (message) => {
    return toast.error(message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const successAlert = (message) => {
    return toast.success(message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const clickSubmitCreate = async () => {
    try {
      if (numberstore.length < 1) {
        return errorAlert("กรุณาระบุรหัสร้านค้า!");
      }

      if (passwordstore.length < 6) {
        return errorAlert("กรุณาระบุรหัสผ่านอย่างน้อย 6 หลัก!");
      }

      if (namestore.length < 1) {
        return errorAlert("กรุณาระบุชื่อร้านค้า!");
      }

      // if (emailstore.length < 1) {
      //   return errorAlert("กรุณาระบุอีเมล!");
      // }

      if (phonestore.length > 0) {
        if (phonestore.length < 9) {
          return errorAlert("กรุณาระบุเบอร์โทรศัพท์!");
        }
      }

      if (selectgroupsale?.value === undefined) {
        return errorAlert("กรุณาระบุกลุ่มงานขาย!");
      }

      if (selectprovince?.value === undefined) {
        return errorAlert("กรุณาระบุจังหวัด!");
      }

      if (selectdistrict?.value === undefined) {
        return errorAlert("กรุณาระบุเขต/อำเภอ!");
      }

      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);

      let mock_format_phone_store = null;
      if (phonestore?.length === 9) {
        const num1 = phonestore.slice(0, 2);
        const num2 = phonestore.slice(2, 5);
        const num3 = phonestore.slice(5, 9);
        mock_format_phone_store = num1 + "-" + num2 + "-" + num3;
      } else if (phonestore?.length === 10) {
        const num1 = phonestore.slice(0, 3);
        const num2 = phonestore.slice(3, 6);
        const num3 = phonestore.slice(6, 10);
        mock_format_phone_store = num1 + "-" + num2 + "-" + num3;
      }

      const resRegis = await registerstore(
        numberstore,
        passwordstore,
        namestore,
        emailstore,
        mock_format_phone_store,
        selectgroupsale,
        selectprovince,
        selectdistrict,
        statusstore
      );

      if (resRegis?.status_code === 200) {
        successAlert("บันทึกสำเร็จ");
        getDataDefault();
        setShowModalStore(false);
      } else {
        errorAlert(resRegis?.response?.data?.error?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSubmitEdit = async () => {
    try {
      if (editnumberstore.length < 1) {
        return errorAlert("กรุณาระบุรหัสร้านค้า!");
      }

      if (editpasswordstore.length > 0) {
        if (editpasswordstore.length < 6) {
          return errorAlert("กรุณาระบุรหัสผ่านอย่างน้อย 6 หลัก!");
        }
      }

      if (editnamestore.length < 1) {
        return errorAlert("กรุณาระบุชื่อร้านค้า!");
      }

      // if (editemailstore.length < 1) {
      //   return errorAlert("กรุณาระบุอีเมล!");
      // }

      if (editphonestore.length > 0) {
        if (editphonestore.length < 9) {
          return errorAlert("กรุณาระบุเบอร์โทรศัพท์!");
        }
      }

      if (editselectgroupsale?.value === undefined) {
        return errorAlert("กรุณาระบุกลุ่มงานขาย!");
      }

      if (editselectprovince?.value === undefined) {
        return errorAlert("กรุณาระบุจังหวัด!");
      }

      if (editselectdistrict?.value === undefined) {
        return errorAlert("กรุณาระบุเขต/อำเภอ!");
      }

      let mock_edit_format_phone_store = null;
      if (editphonestore?.length === 9) {
        const num1 = editphonestore.slice(0, 2);
        const num2 = editphonestore.slice(2, 5);
        const num3 = editphonestore.slice(5, 9);
        mock_edit_format_phone_store = num1 + "-" + num2 + "-" + num3;
      } else if (editphonestore?.length === 10) {
        const num1 = editphonestore.slice(0, 3);
        const num2 = editphonestore.slice(3, 6);
        const num3 = editphonestore.slice(6, 10);
        mock_edit_format_phone_store = num1 + "-" + num2 + "-" + num3;
      }

      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resUpdate = await updatestore(
        editnumberstore,
        editpasswordstore,
        editnamestore,
        editemailstore,
        mock_edit_format_phone_store,
        editselectgroupsale,
        editselectprovince,
        editselectdistrict,
        editstatusstore
      );
      if (resUpdate?.status_code === 200) {
        const storeRes = await fetchAllStore(controllerRef?.signal);
        if (storeRes) {
          setAllStore(storeRes);
          setShowModalEdit(false);
          return successAlert(resUpdate?.message);
        }
      } else {
        return errorAlert("ผิดพลาด กรุณาลองใหม่อีกครั้ง!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickCreateStore = async () => {
    try {
      setNumBerStore("");
      setPasswordStore("");
      setNameStore("");
      setEmailStore("");
      setPhoneStore("");
      setSelectGroupSale({});
      setSelectProvince({});
      setSelectDistrict({});
      setStatusStore(true);
      setDisableDistrictStore(true);
      setShowModalStore(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSwiftStatus = async (e, data) => {
    try {
      let data_mock = [...allstore];
      let indexAllStore = data_mock.findIndex((x) => x.id === data?.id);
      data_mock[indexAllStore] = {
        ...data_mock[indexAllStore],
        store_status: e.target.checked === true ? 1 : 0,
      };
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resUpdate = await updatestatusstore(
        data?.id,
        e.target.checked !== true ? 1 : 0,
        controllerRef?.signal
      );
      if (resUpdate?.status_code === 200) {
        setAllStore(data_mock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MenuBarAdminDesktop />
      <ToastContainer />
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 15,
          paddingBottom: 30,
        }}
      >
        <button className="btn-post-5" onClick={clickCreateStore}>
          + สร้าง
        </button>
        <MaterialTable
          title="ร้านค้า"
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: "ไม่พบข้อมูล",
            },
            toolbar: {
              searchPlaceholder: "ค้นหา...",
            },
            pagination: {
              labelRowsSelect: "แถว",
              firstTooltip: "หน้าแรก",
              previousTooltip: "หน้าที่แล้ว",
              nextTooltip: "หน้าต่อไป",
              lastTooltip: "หน้าสุดท้าย",
            },
            header: {
              actions: "Actions",
            },
          }}
          columns={[
            {
              title: "รหัสร้านค้า",
              field: "bp_code",
            },
            { title: "ชื่อร้านค้า", field: "name" },
            { title: "อีเมล", field: "email" },
            { title: "เบอร์โทรศัพท์", field: "tel" },
            { title: "กลุ่มงานขาย", field: "sales_group_description" },
            { title: "จังหวัด", field: "province_name" },
            { title: "เขต/อำเภอ", field: "district_name" },
            {
              title: "สถานะ",
              field: "status",
              render: (rowData) => (
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={rowData?.store_status === 1 ? true : false}
                    onChange={(e) => changeSwiftStatus(e, rowData)}
                  />
                  <span className="slider round"></span>
                </label>
              ),
            },
          ]}
          data={allstore}
          actions={[
            {
              icon: "save",
              tooltip: "Save User",
              onClick: (event, rowData) => "",
            },
          ]}
          components={{
            Action: (props) => (
              <>
                <button
                  onClick={(event) => clickEdit(props.data)}
                  className="btn-post-3"
                >
                  แก้ไข
                </button>
                <button
                  onClick={(event) => clickDelete(props.data)}
                  className="btn-post-4"
                >
                  ลบ
                </button>
              </>
            ),
          }}
          options={tableOptions}
        />
      </div>
      {/* start modal create */}
      <Modal
        show={showmodalstore}
        onHide={() => setShowModalStore(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">สร้างร้านค้า</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-liststore-1">
            <div className="container-liststore-1">
              <div className="text-liststore-1">รหัสร้านค้า :</div>
              <div>
                <input
                  type="text"
                  className={
                    numberstore.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={numberstore}
                  maxLength={20}
                  onChange={changeNumberStore}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">รหัสผ่าน :</div>
              <div>
                <input
                  type="password"
                  className={
                    passwordstore.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={passwordstore}
                  maxLength={200}
                  onChange={changePasswordStore}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">ชื่อร้านค้า :</div>
              <div>
                <input
                  type="text"
                  className={
                    namestore.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={namestore}
                  maxLength={200}
                  onChange={changeNameStore}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">อีเมล :</div>
              <div>
                <input
                  type="text"
                  className={
                    emailstore.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={emailstore}
                  maxLength={80}
                  onChange={changeEmailStore}
                  placeholder="example@hotmail.com"
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">เบอร์โทรศัพท์ :</div>
              <div>
                <input
                  type="text"
                  className={
                    phonestore.length < 9
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={phonestore}
                  maxLength={10}
                  onChange={changePhoneStore}
                  placeholder="0812345678 หรือ 021234567"
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">กลุ่มงานขาย :</div>
              <div>
                <Select
                  value={selectgroupsale}
                  styles={
                    selectgroupsale?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={groupsalestore}
                  onChange={changeSelectGroupSale}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">จังหวัด :</div>
              <div>
                <Select
                  value={selectprovince}
                  styles={
                    selectprovince?.value > 0 ? customstyles : customstyleserror
                  }
                  options={provincestore}
                  onChange={changeSelectProvince}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">เขต/อำเภอ :</div>
              <div>
                <Select
                  value={selectdistrict}
                  styles={
                    selectdistrict?.value > 0 ? customstyles : customstyleserror
                  }
                  options={districtstore}
                  onChange={changeSelectDistrict}
                  isSearchable={true}
                  placeholder=""
                  isDisabled={disabledistrictstore}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">สถานะ :</div>
              <div style={{ marginLeft: 18 }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={statusstore}
                    onChange={changeStatusStore}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="container-liststore-3">
              <button className="btn-liststore-2" onClick={clickSubmitCreate}>
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "ตกลง"
                )}
              </button>
              <button className="btn-liststore-1" onClick={clickCancel}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal create */}
      {/* start modal delete */}
      <Modal
        show={showmodaldelete}
        onHide={() => setShowModalDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              <div className="text-home-20">
                คุณต้องการลบ รหัสร้านค้า {datadelete?.bp_code} ใช่หรือไม่?
              </div>
              <button className="btn-post-1" onClick={clickConfirmDelete}>
                ยืนยัน
              </button>
              <button className="btn-post-2" onClick={clickCancelDelete}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal delete */}
      {/* start modal edit */}
      <Modal
        show={showmodaledit}
        onHide={() => setShowModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">แก้ไขร้านค้า</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-liststore-1">
            <div className="container-liststore-1">
              <div className="text-liststore-1">รหัสร้านค้า :</div>
              <div>
                <input
                  type="text"
                  className={
                    editnumberstore.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editnumberstore}
                  maxLength={20}
                  onChange={changeEditNumberStore}
                  disabled={true}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">รหัสผ่าน :</div>
              <div>
                <input
                  type="password"
                  className="input-liststore-1"
                  value={editpasswordstore}
                  maxLength={200}
                  onChange={changeEditPasswordStore}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">ชื่อร้านค้า :</div>
              <div>
                <input
                  type="text"
                  className={
                    editnamestore.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editnamestore}
                  maxLength={200}
                  onChange={changeEditNameStore}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">อีเมล :</div>
              <div>
                <input
                  type="text"
                  className={
                    editemailstore.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editemailstore}
                  maxLength={80}
                  onChange={changeEditEmailStore}
                  placeholder="example@hotmail.com"
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">เบอร์โทรศัพท์ :</div>
              <div>
                <input
                  type="text"
                  className={
                    editphonestore.length < 9
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editphonestore}
                  maxLength={10}
                  onChange={changeEditPhoneStore}
                  placeholder="0812345678 หรือ 021234567"
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">กลุ่มงานขาย :</div>
              <div>
                <Select
                  value={editselectgroupsale}
                  styles={
                    editselectgroupsale?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={groupsalestore}
                  onChange={changeEditSelectGroupSale}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">จังหวัด :</div>
              <div>
                <Select
                  value={editselectprovince}
                  styles={
                    editselectprovince?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={provincestore}
                  onChange={changeEditSelectProvince}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">เขต/อำเภอ :</div>
              <div>
                <Select
                  value={editselectdistrict}
                  styles={
                    editselectdistrict?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={districtstore}
                  onChange={changeEditSelectDistrict}
                  isSearchable={true}
                  placeholder=""
                  isDisabled={editdisabledistrictstore}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">สถานะ :</div>
              <div style={{ marginLeft: 18 }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={editstatusstore}
                    onChange={changeEditStatusStore}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="container-liststore-3">
              <button className="btn-liststore-2" onClick={clickSubmitEdit}>
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "ตกลง"
                )}
              </button>
              <button className="btn-liststore-1" onClick={clickEditCancel}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
    </>
  );
};

export default ListStoreDesktopPage;
