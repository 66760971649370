import React from "react";

const AdminMobilePage = () => {
  return (
    <div id="login-mobile">
      <div className="contain-admin-center">
        รองรับเฉพาะหน้าจอ Desktop เท่านั้น
      </div>
    </div>
  );
};

export default AdminMobilePage;
