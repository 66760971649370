import React, { useState, useEffect, useCallback } from "react";
import MenuBarAdminDesktop from "../../components/MenuBarAdminDesktop";
import DatePicker from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import MaterialTable from "material-table";
import XLSX from "xlsx";
import { refreshtoken } from "../../api/authApi";
import { reportinout } from "../../api/adminApi";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const ReportInOutDesktopPage = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [allinout, setAllInOut] = useState([]);
  let controllerRef = null;

  const theme = createMuiTheme({
    overrides: {
      MuiPaper: {
        root: {
          zIndex: 0, // Set your desired z-index value
        },
      },
    },
  });

  const changeStartDate = async (date) => {
    try {
      setStartDate(date);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const inputRes = await reportinout(date, endDate, controllerRef?.signal);
      if (inputRes) {
        setAllInOut(inputRes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEndDate = async (date) => {
    try {
      setEndDate(date);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const inoutRes = await reportinout(
        startDate,
        date,
        controllerRef?.signal
      );
      if (inoutRes) {
        setAllInOut(inoutRes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickExportFile = async () => {
    let data_new = [];
    // eslint-disable-next-line no-unused-vars
    for await (const [_, item] of allinout.entries()) {
      const news1 = item?.date_click.substring(0, 4);
      const news2 = item?.date_click.substring(5, 7);
      const news3 = item?.date_click.substring(8, 10);
      data_new.push({
        วันที่: news3 + "/" + news2 + "/" + news1,
        รหัสร้านค้า: item?.id_customer,
        ชื่อร้านค้า: item?.name_customer,
        จำนวนเข้าระบบ: item?.login_click,
        จำนวนออกระบบ: item?.logout_click,
      });
    }

    const dataWS = XLSX.utils.json_to_sheet(data_new, {
      header: [
        "วันที่",
        "รหัสร้านค้า",
        "ชื่อร้านค้า",
        "จำนวนเข้าระบบ",
        "จำนวนออกระบบ",
      ],
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataWS, "ReportInOut");
    XLSX.writeFile(wb, "reportinout.xlsx");
  };

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const inputRes = await fetchAllInOut(controllerRef?.signal);
      if (inputRes) {
        setAllInOut(inputRes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAllInOut = useCallback(
    async (signal) => {
      try {
        await refreshtoken(signal);
        const _data = await reportinout(startDate, endDate, signal);
        if (_data) {
          return _data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const tableOptions = {
    actionsColumnIndex: 0, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 10, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [10, 30, 50, 100], // rows selection options
  };

  useEffect(() => {
    getDataDefault();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MenuBarAdminDesktop />
      <div className="heard-reportphone">
        <div>
          <div className="container-home-desktop-10">
            <div className="text-home-13">เลือกวันที่ Start</div>
            <img
              src="./icon_12.png"
              height={20}
              alt="clock"
              className="logo-clock"
            />
          </div>
          <DatePicker
            selected={startDate}
            onChange={changeStartDate}
            dateFormat="dd/MM/yyyy"
          />
        </div>
        <div>
          <div className="container-home-desktop-10">
            <div className="text-home-13">เลือกวันที่ End</div>
            <img
              src="./icon_12.png"
              height={20}
              alt="clock"
              className="logo-clock"
            />
          </div>
          <DatePicker
            selected={endDate}
            onChange={changeEndDate}
            dateFormat="dd/MM/yyyy"
          />
        </div>
      </div>
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 15,
          paddingBottom: 30,
        }}
      >
        <div className="bg-login">
          <button
            className="btn-post-6"
            onClick={clickExportFile}
            disabled={allinout?.length > 0 ? false : true}
          >
            ส่งออก
          </button>
        </div>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
            title="รายงานร้านค้าเข้า-ออก"
            isLoading={loading}
            localization={{
              body: {
                emptyDataSourceMessage: "ไม่พบข้อมูล",
              },
              toolbar: {
                searchPlaceholder: "ค้นหา...",
              },
              pagination: {
                labelRowsSelect: "แถว",
                firstTooltip: "หน้าแรก",
                previousTooltip: "หน้าที่แล้ว",
                nextTooltip: "หน้าต่อไป",
                lastTooltip: "หน้าสุดท้าย",
              },
              header: {
                actions: "Actions",
              },
            }}
            columns={[
              {
                title: "วันที่",
                field: "date_click",
                render: (rowData) => {
                  const news1 = rowData?.date_click.substring(0, 4);
                  const news2 = rowData?.date_click.substring(5, 7);
                  const news3 = rowData?.date_click.substring(8, 10);
                  return <div>{news3 + "/" + news2 + "/" + news1}</div>;
                },
              },
              { title: "รหัสร้านค้า", field: "id_customer" },
              { title: "ชื่อร้านค้า", field: "name_customer" },
              { title: "จำนวนเข้าระบบ", field: "login_click" },
              { title: "จำนวนออกระบบ", field: "logout_click" },
            ]}
            data={allinout}
            options={tableOptions}
          />
        </MuiThemeProvider>
      </div>
    </>
  );
};

export default ReportInOutDesktopPage;
