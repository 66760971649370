import axios from "axios";
const prefix = "users";
const prefixadmin = "admins";
const prefixemployees = "employees";

const LOGIN_URL = `${prefix}/login`;
const LOGIN_ADMIN_URL = `${prefixadmin}/login-admin`;
const LOGIN_EMPLOYEE_URL = `${prefixemployees}/login-employee`;
const LOGOUT_URL = `${prefix}/logout`;
const REGISTER_URL = `${prefix}/register`;
const ME_URL = `${prefix}/me`;
const ME_ADMIN_URL = `${prefixadmin}/me-admin`;
const ME_EMPLOYEE_URL = `${prefixemployees}/me-employee`;
const REFRESH_TOKEN_URL = `${prefix}/refresh-token`;
const FORGOT_PASSWORD_URL = `${prefix}/forgot-password`;
const FORGOT_PASSWORD_AUTHEN_URL = `${prefix}/forgot-password-authen`;
const FORGOT_PASSWORD_EMPLOYEE_AUTHEN_URL = `${prefixemployees}/forgot-password-employee-authen`;
const SEND_MAIL_FORGOT_PASSWORD_URL = `${prefix}/send-mail-forgot-password`;

export async function login(bp_code, password) {
  try {
    const { data } = await axios.post(LOGIN_URL, {
      bp_code: bp_code,
      password: password,
    });

    if (data?.status_code === 200) {
      return {
        access_token: data?.access_token,
        refresh_token: data?.refresh_token,
        expires_in: data?.expires_in,
        token_type: data?.token_type,
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function loginemployee(emp_id, password) {
  try {
    const { data } = await axios.post(LOGIN_EMPLOYEE_URL, {
      emp_id: emp_id,
      password: password,
    });

    if (data?.status_code === 200) {
      return {
        access_token: data?.access_token,
        refresh_token: data?.refresh_token,
        expires_in: data?.expires_in,
        token_type: data?.token_type,
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function loginadmin(username, password) {
  try {
    const { data } = await axios.post(LOGIN_ADMIN_URL, {
      username: username,
      password: password,
    });

    if (data?.status_code === 200) {
      return {
        access_token: data?.access_token,
        refresh_token: data?.refresh_token,
        expires_in: data?.expires_in,
        token_type: data?.token_type,
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function logout() {
  try {
    const { data } = await axios.post(LOGOUT_URL);

    if (data?.status_code === 201) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function register(
  bp_code,
  name,
  email,
  password,
  tel,
  sales_group_id,
  sales_group_description,
  sales_distric,
  district_id,
  province_id
) {
  try {
    const { data } = await axios.post(REGISTER_URL, {
      bp_code: bp_code,
      name: name,
      email: email,
      password: password,
      tel: tel,
      sales_group_id: sales_group_id,
      sales_group_description: sales_group_description,
      sales_distric: sales_distric,
      district_id: district_id,
      province_id: province_id,
    });
    if (data?.status_code === 201) {
      return data?.user;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function profile(signal) {
  try {
    const { data } = await axios.get(ME_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.profile;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function profileadmin(signal) {
  try {
    const { data } = await axios.get(ME_ADMIN_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.admin;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function profileemployee(signal) {
  try {
    const { data } = await axios.get(ME_EMPLOYEE_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.employee;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function refreshtoken(signal) {
  try {
    const { data } = await axios.get(REFRESH_TOKEN_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      await localStorage.setItem(
        "authToken",
        JSON.stringify(data?.access_token)
      );
      return {
        access_token: data?.access_token,
        expires_in: data?.expires_in,
        token_type: data?.token_type,
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function forgotpassword(new_password, confirm_new_password) {
  try {
    const { data } = await axios.post(FORGOT_PASSWORD_URL, {
      new_password: new_password,
      confirm_new_password: confirm_new_password,
    });
    if (data?.status_code === 200) {
      return {
        status_code: 200,
        message: "แก้ไขข้อมูลสำเร็จ",
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function forgotpasswordauthen(new_password, confirm_new_password) {
  try {
    const { data } = await axios.post(FORGOT_PASSWORD_AUTHEN_URL, {
      new_password: new_password,
      confirm_new_password: confirm_new_password,
    });
    if (data?.status_code === 200) {
      return {
        status_code: 200,
        message: "แก้ไขข้อมูลสำเร็จ",
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function forgotpasswordemployeeauthen(
  new_password,
  confirm_new_password
) {
  try {
    const { data } = await axios.post(FORGOT_PASSWORD_EMPLOYEE_AUTHEN_URL, {
      new_password: new_password,
      confirm_new_password: confirm_new_password,
    });
    if (data?.status_code === 200) {
      return {
        status_code: 200,
        message: "แก้ไขข้อมูลสำเร็จ",
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function sendmailforgotpassword(email) {
  try {
    const { data } = await axios.post(SEND_MAIL_FORGOT_PASSWORD_URL, {
      email: email,
    });
    if (data?.status_code === 200) {
      return {
        message: data.message,
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}
