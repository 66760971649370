import React, { useState, useEffect, useCallback } from "react";
import MenuBarAdminDesktop from "../../components/MenuBarAdminDesktop";
import MaterialTable from "material-table";
import { refreshtoken } from "../../api/authApi";
import {
  registeremployee,
  employee,
  salesgroupadmin,
  deleteemployee,
  updateemployee,
  updatestatusemployee,
} from "../../api/adminApi";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";

const ListEmployeeDesktopPage = () => {
  const [loading, setLoading] = useState(false);
  const [allemployee, setAllEmployee] = useState([]);
  const [showmodalemployee, setShowModalEmployee] = useState(false);
  const [showmodaldelete, setShowModalDelete] = useState(false);
  const [showmodaledit, setShowModalEdit] = useState(false);

  const [empid, setEmpId] = useState("");
  const [passwordemployee, setPasswordEmployee] = useState("");
  const [nameemployee, setNameEmployee] = useState("");
  const [surnameemployee, setSurNameEmployee] = useState("");
  const [emailemployee, setEmailEmployee] = useState("");
  const levelemployee = [
    { label: "S1", value: "1" },
    { label: "S2", value: "2" },
    { label: "S3", value: "3" },
    { label: "M1", value: "4" },
    { label: "M2", value: "5" },
    { label: "M3", value: "6" },
  ];
  const levelposition = [
    { label: "Professional Sales Representative", value: "1" },
    { label: "Sr.Supervisor", value: "2" },
    { label: "Asst.Manager", value: "3" },
    { label: "Senior Supervisor", value: "4" },
  ];
  const [statusemployee, setStatusEmployee] = useState(true);

  const [selectlevel, setSelectLevel] = useState({});
  const [selectposition, setSelectPosition] = useState({});
  const [groupsalestore, setGroupSaleStore] = useState([]);
  const [selectgroupsale, setSelectGroupSale] = useState({});
  const [datadelete, setDataDelete] = useState({});

  const [editselectlevel, setEditSelectLevel] = useState({});
  const [editselectposition, setEditSelectPosition] = useState({});
  const [editselectgroupsale, setEditSelectGroupSale] = useState({});

  const [editempid, setEditEmpId] = useState("");
  const [editpasswordemployee, setEditPasswordEmployee] = useState("");
  const [editnameemployee, setEditNameEmployee] = useState("");
  const [editsurnameemployee, setEditSurNameEmployee] = useState("");
  const [editemailemployee, setEditEmailEmployee] = useState("");
  const [editstatusemployee, setEditStatusEmployee] = useState(true);

  let controllerRef = null;

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      border: "1px solid #b5adad",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 250,
      marginLeft: 19,
      height: 40,
      fontSize: 14,
      fontFamily: "Kanit",
      "&:hover": {
        border: "2px solid #1C5BBD",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C5BBD" : "white",
      fontFamily: "Kanit",
      fontSize: 14,
      color: state.isSelected ? "white" : "#1C5BBD",
      "&:hover": {
        backgroundColor: "#1C5BBD",
        color: "#FFFFFF",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1C5BBD",
    }),
  };

  const customstyleserror = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: "10px",
      border: "2px solid red",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 250,
      marginLeft: 19,
      height: 40,
      fontSize: 14,
      fontFamily: "Kanit",
      "&:hover": {
        border: "2px solid #1C5BBD",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C5BBD" : "white",
      fontFamily: "Kanit",
      fontSize: 14,
      color: state.isSelected ? "white" : "#1C5BBD",
      "&:hover": {
        backgroundColor: "#1C5BBD",
        color: "#FFFFFF",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1C5BBD",
    }),
  };

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const employeeRes = await fetchAllEmployee(controllerRef?.signal);
      if (employeeRes) {
        setAllEmployee(employeeRes);
      }
      const salesgroupRes = await fetchAllSalesGroup(controllerRef?.signal);
      if (salesgroupRes) {
        setGroupSaleStore(salesgroupRes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAllEmployee = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await employee(signal);
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllSalesGroup = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await salesgroupadmin(signal);
      let mock = [];
      _data.map((item) => {
        mock.push({
          value: item?.sales_group_id,
          label: item?.sales_group_description,
        });
        return null;
      });
      if (mock) {
        return mock;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const tableOptions = {
    actionsColumnIndex: 0, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 10, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [10, 30, 50, 100], // rows selection options
  };

  useEffect(() => {
    getDataDefault();

    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickEdit = async (data) => {
    try {
      const resLevel = levelemployee.filter((x) => x?.label === data?.level);
      const resPosition = levelposition.filter(
        (x) => x?.label === data?.position
      );
      setEditEmpId(data?.emp_id);
      setEditPasswordEmployee("");
      setEditNameEmployee(data?.firstname);
      setEditSurNameEmployee(data?.lastname);
      setEditEmailEmployee(data?.email !== null ? data?.email : "");
      setEditSelectLevel({
        value: resLevel[0]?.value,
        label: data?.level,
      });
      setEditSelectPosition({
        value: resPosition[0]?.value,
        label: data?.position,
      });

      setEditSelectGroupSale({
        value: JSON.stringify(data?.sales_group_id),
        label: data?.sales_group_description,
      });
      setEditStatusEmployee(data?.status === 1 ? true : false);
      setShowModalEdit(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmDelete = async () => {
    try {
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resDelete = await deleteemployee(
        datadelete?.id,
        controllerRef?.signal
      );
      if (resDelete?.status_code === 200) {
        const employeeRes = await fetchAllEmployee(controllerRef?.signal);
        if (employeeRes) {
          setAllEmployee(employeeRes);
          setShowModalDelete(false);
          return successAlert(resDelete?.message);
        }
      } else {
        return errorAlert("ผิดพลาด กรุณาลองใหม่อีกครั้ง!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelDelete = () => {
    try {
      setShowModalDelete(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clickDelete = async (data) => {
    try {
      setDataDelete(data);
      setShowModalDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEmpId = (e) => {
    try {
      if (!e.target.value.match(/^([0-9])+$/i)) {
        setEmpId("");
      } else {
        setEmpId(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditEmpId = (e) => {
    try {
      if (!e.target.value.match(/^([0-9])+$/i)) {
        setEmpId("");
      } else {
        setEmpId(e.target.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeNameEmployee = (e) => {
    try {
      setNameEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditNameEmployee = (e) => {
    try {
      setEditNameEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSurNameEmployee = (e) => {
    try {
      setSurNameEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditSurNameEmployee = (e) => {
    try {
      setEditSurNameEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changePasswordEmployee = (e) => {
    try {
      setPasswordEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditPasswordEmployee = (e) => {
    try {
      setEditPasswordEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEmailEmployee = (e) => {
    try {
      setEmailEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditEmailEmployee = (e) => {
    try {
      setEditEmailEmployee(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeStatusEmployee = (e) => {
    try {
      setStatusEmployee(e.target.checked);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditStatusEmployee = (e) => {
    try {
      setEditStatusEmployee(e.target.checked);
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancel = () => {
    try {
      setShowModalEmployee(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clickEditCancel = () => {
    try {
      setShowModalEdit(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectLevel = (data) => {
    try {
      setSelectLevel(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditSelectLevel = (data) => {
    try {
      setEditSelectLevel(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectPosotion = (data) => {
    try {
      setSelectPosition(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditSelectPosotion = (data) => {
    try {
      setEditSelectPosition(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSelectGroupSale = (data) => {
    try {
      setSelectGroupSale(data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeEditSelectGroupSale = (data) => {
    try {
      setEditSelectGroupSale(data);
    } catch (error) {
      console.log(error);
    }
  };

  const errorAlert = (message) => {
    return toast.error(message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const successAlert = (message) => {
    return toast.success(message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const clickSubmitCreate = async () => {
    try {
      if (empid.length < 1) {
        return errorAlert("กรุณาระบุรหัสพนักงาน!");
      }

      if (passwordemployee.length < 6) {
        return errorAlert("กรุณาระบุรหัสผ่านอย่างน้อย 6 หลัก!");
      }

      if (nameemployee.length < 1) {
        return errorAlert("กรุณาระบุชื่อ!");
      }

      if (surnameemployee.length < 1) {
        return errorAlert("กรุณาระบุนามสกุล!");
      }

      if (emailemployee.length < 1) {
        return errorAlert("กรุณาระบุอีเมล!");
      }

      if (selectlevel?.value === undefined) {
        return errorAlert("กรุณาระบุlevel!");
      }

      if (selectposition?.value === undefined) {
        return errorAlert("กรุณาระบุตำแหน่ง!");
      }

      if (selectgroupsale?.value === undefined) {
        return errorAlert("กรุณาระบุกลุ่มงานขาย!");
      }

      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resRegis = await registeremployee(
        empid,
        passwordemployee,
        nameemployee,
        surnameemployee,
        emailemployee,
        selectlevel,
        selectposition,
        selectgroupsale,
        statusemployee
      );
      if (resRegis?.status_code === 200) {
        successAlert("บันทึกสำเร็จ");
        getDataDefault();
        setShowModalEmployee(false);
      } else {
        errorAlert(resRegis?.response?.data?.error?.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSubmitEdit = async () => {
    try {
      if (editempid.length < 1) {
        return errorAlert("กรุณาระบุรหัสพนักงาน!");
      }
      if (editpasswordemployee.length > 0) {
        if (editpasswordemployee.length < 6) {
          return errorAlert("กรุณาระบุรหัสผ่านอย่างน้อย 6 หลัก!");
        }
      }
      if (editnameemployee.length < 1) {
        return errorAlert("กรุณาระบุชื่อ!");
      }
      if (editsurnameemployee.length < 1) {
        return errorAlert("กรุณาระบุนามสกุล!");
      }
      if (editemailemployee.length < 1) {
        return errorAlert("กรุณาระบุอีเมล!");
      }
      if (editselectlevel?.value === undefined) {
        return errorAlert("กรุณาระบุlevel!");
      }
      if (editselectposition?.value === undefined) {
        return errorAlert("กรุณาระบุตำแหน่ง!");
      }
      if (editselectgroupsale?.value === undefined) {
        return errorAlert("กรุณาระบุกลุ่มงานขาย!");
      }
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resUpdate = await updateemployee(
        editempid,
        editpasswordemployee,
        editnameemployee,
        editsurnameemployee,
        editemailemployee,
        editselectlevel?.label,
        editselectposition?.label,
        editselectgroupsale,
        editstatusemployee
      );
      if (resUpdate?.status_code === 200) {
        const employeeRes = await fetchAllEmployee(controllerRef?.signal);
        if (employeeRes) {
          setAllEmployee(employeeRes);
          setShowModalEdit(false);
          return successAlert(resUpdate?.message);
        }
      } else {
        return errorAlert("ผิดพลาด กรุณาลองใหม่อีกครั้ง!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickCreateEmployee = async () => {
    try {
      setEmpId("");
      setPasswordEmployee("");
      setNameEmployee("");
      setSurNameEmployee("");
      setEmailEmployee("");
      setSelectLevel({});
      setSelectPosition({});
      setSelectGroupSale({});
      setStatusEmployee(true);
      setShowModalEmployee(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSwiftStatus = async (e, data) => {
    try {
      let data_mock = [...allemployee];
      let indexAllStore = data_mock.findIndex((x) => x.id === data?.id);
      data_mock[indexAllStore] = {
        ...data_mock[indexAllStore],
        status: e.target.checked === true ? 1 : 0,
      };
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resUpdate = await updatestatusemployee(
        data?.id,
        e.target.checked !== true ? 1 : 0,
        controllerRef?.signal
      );
      if (resUpdate?.status_code === 200) {
        setAllEmployee(data_mock);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MenuBarAdminDesktop />
      <ToastContainer />
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 15,
          paddingBottom: 30,
        }}
      >
        <button className="btn-post-5" onClick={clickCreateEmployee}>
          + สร้าง
        </button>
        <MaterialTable
          title="พนักงาน"
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: "ไม่พบข้อมูล",
            },
            toolbar: {
              searchPlaceholder: "ค้นหา...",
            },
            pagination: {
              labelRowsSelect: "แถว",
              firstTooltip: "หน้าแรก",
              previousTooltip: "หน้าที่แล้ว",
              nextTooltip: "หน้าต่อไป",
              lastTooltip: "หน้าสุดท้าย",
            },
            header: {
              actions: "Actions",
            },
          }}
          columns={[
            {
              title: "รหัสพนักงาน",
              field: "emp_id",
            },
            { title: "ชื่อ", field: "firstname" },
            { title: "นามสกุล", field: "lastname" },
            { title: "อีเมล", field: "email" },
            { title: "level", field: "level" },
            { title: "ตำแหน่ง", field: "position" },
            { title: "กลุ่มงานขาย", field: "sales_group_description" },
            {
              title: "สถานะ",
              field: "status",
              render: (rowData) => (
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={rowData?.status === 1 ? true : false}
                    onChange={(e) => changeSwiftStatus(e, rowData)}
                  />
                  <span className="slider round"></span>
                </label>
              ),
            },
          ]}
          data={allemployee}
          actions={[
            {
              icon: "save",
              tooltip: "Save User",
              onClick: (event, rowData) => "",
            },
          ]}
          components={{
            Action: (props) => (
              <>
                <button
                  onClick={(event) => clickEdit(props.data)}
                  className="btn-post-3"
                >
                  แก้ไข
                </button>
                <button
                  onClick={(event) => clickDelete(props.data)}
                  className="btn-post-4"
                >
                  ลบ
                </button>
              </>
            ),
          }}
          options={tableOptions}
        />
      </div>
      {/* start modal create */}
      <Modal
        show={showmodalemployee}
        onHide={() => setShowModalEmployee(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">สร้างพนักงาน</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-liststore-1">
            <div className="container-liststore-1">
              <div className="text-liststore-1">รหัสพนักงาน :</div>
              <div>
                <input
                  type="text"
                  className={
                    empid.length < 1 ? "input-liststore-2" : "input-liststore-1"
                  }
                  value={empid}
                  maxLength={20}
                  onChange={changeEmpId}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">รหัสผ่าน :</div>
              <div>
                <input
                  type="password"
                  className={
                    passwordemployee.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={passwordemployee}
                  maxLength={200}
                  onChange={changePasswordEmployee}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">ชื่อ :</div>
              <div>
                <input
                  type="text"
                  className={
                    nameemployee.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={nameemployee}
                  maxLength={200}
                  onChange={changeNameEmployee}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">นามสกุล :</div>
              <div>
                <input
                  type="text"
                  className={
                    surnameemployee.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={surnameemployee}
                  maxLength={200}
                  onChange={changeSurNameEmployee}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">อีเมล :</div>
              <div>
                <input
                  type="text"
                  className={
                    emailemployee.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={emailemployee}
                  maxLength={80}
                  onChange={changeEmailEmployee}
                  placeholder="example@hotmail.com"
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">level :</div>
              <div>
                <Select
                  value={selectlevel}
                  styles={
                    selectlevel?.value > 0 ? customstyles : customstyleserror
                  }
                  options={levelemployee}
                  onChange={changeSelectLevel}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">ตำแหน่ง :</div>
              <div>
                <Select
                  value={selectposition}
                  styles={
                    selectposition?.value > 0 ? customstyles : customstyleserror
                  }
                  options={levelposition}
                  onChange={changeSelectPosotion}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">กลุ่มงานขาย :</div>
              <div>
                <Select
                  value={selectgroupsale}
                  styles={
                    selectgroupsale?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={groupsalestore}
                  onChange={changeSelectGroupSale}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">สถานะ :</div>
              <div style={{ marginLeft: 18 }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={statusemployee}
                    onChange={changeStatusEmployee}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="container-liststore-3">
              <button className="btn-liststore-2" onClick={clickSubmitCreate}>
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "ตกลง"
                )}
              </button>
              <button className="btn-liststore-1" onClick={clickCancel}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal create */}
      {/* start modal delete */}
      <Modal
        show={showmodaldelete}
        onHide={() => setShowModalDelete(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              <div className="text-home-20">
                คุณต้องการลบ รหัสพนักงาน {datadelete?.emp_id} ใช่หรือไม่?
              </div>
              <button className="btn-post-1" onClick={clickConfirmDelete}>
                ยืนยัน
              </button>
              <button className="btn-post-2" onClick={clickCancelDelete}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal delete */}
      {/* start modal edit */}
      <Modal
        show={showmodaledit}
        onHide={() => setShowModalEdit(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">แก้ไขพนักงาน</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-liststore-1">
            <div className="container-liststore-1">
              <div className="text-liststore-1">รหัสพนักงาน :</div>
              <div>
                <input
                  type="text"
                  className={
                    editempid.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editempid}
                  maxLength={20}
                  onChange={changeEditEmpId}
                  disabled={true}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">รหัสผ่าน :</div>
              <div>
                <input
                  type="password"
                  className="input-liststore-1"
                  value={editpasswordemployee}
                  maxLength={200}
                  onChange={changeEditPasswordEmployee}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">ชื่อ :</div>
              <div>
                <input
                  type="text"
                  className={
                    editnameemployee.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editnameemployee}
                  maxLength={200}
                  onChange={changeEditNameEmployee}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">นามสกุล :</div>
              <div>
                <input
                  type="text"
                  className={
                    editsurnameemployee.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editsurnameemployee}
                  maxLength={200}
                  onChange={changeEditSurNameEmployee}
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">อีเมล :</div>
              <div>
                <input
                  type="text"
                  className={
                    editemailemployee.length < 1
                      ? "input-liststore-2"
                      : "input-liststore-1"
                  }
                  value={editemailemployee}
                  maxLength={80}
                  onChange={changeEditEmailEmployee}
                  placeholder="example@hotmail.com"
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">level :</div>
              <div>
                <Select
                  value={editselectlevel}
                  styles={
                    editselectlevel?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={levelemployee}
                  onChange={changeEditSelectLevel}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">ตำแหน่ง :</div>
              <div>
                <Select
                  value={editselectposition}
                  styles={
                    editselectposition?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={levelposition}
                  onChange={changeEditSelectPosotion}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">กลุ่มงานขาย :</div>
              <div>
                <Select
                  value={editselectgroupsale}
                  styles={
                    editselectgroupsale?.value > 0
                      ? customstyles
                      : customstyleserror
                  }
                  options={groupsalestore}
                  onChange={changeEditSelectGroupSale}
                  isSearchable={true}
                  placeholder=""
                />
              </div>
            </div>
            <div className="container-liststore-2">
              <div className="text-liststore-1">สถานะ :</div>
              <div style={{ marginLeft: 18 }}>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={editstatusemployee}
                    onChange={changeEditStatusEmployee}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="container-liststore-3">
              <button className="btn-liststore-2" onClick={clickSubmitEdit}>
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "ตกลง"
                )}
              </button>
              <button className="btn-liststore-1" onClick={clickEditCancel}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit */}
    </>
  );
};

export default ListEmployeeDesktopPage;
