import React, { useState, useEffect, useCallback } from "react";
import MenuBarAdminDesktop from "../../components/MenuBarAdminDesktop";
import MaterialTable from "material-table";
import { refreshtoken } from "../../api/authApi";
import { salesgroupadmin, importsalesgroupadmin } from "../../api/adminApi";
import XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const ListSalesGroupDesktopPage = () => {
  const [loading, setLoading] = useState(false);
  const [allsalesgroup, setAllSalesGroup] = useState([]);
  const [importmodal, setImportModal] = useState(false);
  const [mockupload, setMockUpload] = useState([]);
  let controllerRef = null;

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const salesgroupRes = await fetchAllSalesGroup(controllerRef?.signal);
      if (salesgroupRes) {
        setAllSalesGroup(salesgroupRes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAllSalesGroup = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await salesgroupadmin(signal);
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const tableOptions = {
    actionsColumnIndex: 0, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 10, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [10, 30, 50, 100], // rows selection options
  };

  useEffect(() => {
    getDataDefault();

    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickExportFile = async () => {
    let data_new = [];
    // eslint-disable-next-line no-unused-vars
    for await (const [_, item] of allsalesgroup.entries()) {
      data_new.push({
        รหัสกลุ่มงานขาย: item?.sales_group_id,
        ชื่อกลุ่มงานขาย: item?.sales_group_description,
      });
    }

    const dataWS = XLSX.utils.json_to_sheet(data_new, {
      header: ["รหัสกลุ่มงานขาย", "ชื่อกลุ่มงานขาย"],
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataWS, "SalesGroup");
    XLSX.writeFile(wb, "salesgroupmaster.xlsx");
  };

  const changeFileImport = (e) => {
    try {
      const filesImport = e.target.files[0];
      if (
        filesImport.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setMockUpload([]);
        return toast.error("รองรับนามสกุลไฟล์ .xls และ .xlsx!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (filesImport) {
        setLoading(true);
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(filesImport);
        fileReader.onload = (event) => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach((sheet) => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            setMockUpload(rowObject);
          });
        };
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmUpload = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      let data_ok = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of mockupload.entries()) {
        data_ok.push({
          sales_group_id: item["รหัสกลุ่มงานขาย"],
          sales_group_description: item["ชื่อกลุ่มงานขาย"],
        });
      }
      await refreshtoken(controllerRef?.signal);
      const resImport = await importsalesgroupadmin(
        data_ok,
        controllerRef?.signal
      );
      if (resImport?.status_code === 200) {
        const salesgroupRes = await fetchAllSalesGroup(controllerRef?.signal);
        if (salesgroupRes) {
          setAllSalesGroup(salesgroupRes);
        }
        setMockUpload([]);
        setImportModal(false);
        setLoading(false);
        return toast.success(resImport?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickCancelUpload = async () => {
    try {
      setMockUpload([]);
      setImportModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <MenuBarAdminDesktop />
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 15,
          paddingBottom: 30,
        }}
      >
        <div className="bg-login">
          <button
            className="btn-post-5"
            onClick={() => setImportModal(!importmodal)}
          >
            นำเข้า
          </button>
          <button
            className="btn-post-6"
            onClick={clickExportFile}
            disabled={allsalesgroup?.length > 0 ? false : true}
          >
            ส่งออก
          </button>
        </div>
        <MaterialTable
          title="กลุ่มงานขาย"
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: "ไม่พบข้อมูล",
            },
            toolbar: {
              searchPlaceholder: "ค้นหา...",
            },
            pagination: {
              labelRowsSelect: "แถว",
              firstTooltip: "หน้าแรก",
              previousTooltip: "หน้าที่แล้ว",
              nextTooltip: "หน้าต่อไป",
              lastTooltip: "หน้าสุดท้าย",
            },
            header: {
              actions: "Actions",
            },
          }}
          columns={[
            { title: "รหัสกลุ่มงานขาย", field: "sales_group_id" },
            { title: "ชื่อกลุ่มงานขาย", field: "sales_group_description" },
          ]}
          data={allsalesgroup}
          options={tableOptions}
        />
      </div>
      {/* start modal import */}
      <Modal
        show={importmodal}
        onHide={clickCancelUpload}
        backdrop="static"
        keyboard={false}
        size={mockupload?.length > 0 ? "xl" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">นำเข้า</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <input
              id="importBrandInput"
              className={mockupload?.length > 0 ? "file-input" : ""}
              type="file"
              accept=".xls,.xlsx"
              onChange={changeFileImport}
            />
            {mockupload?.length > 0 && (
              <MaterialTable
                title=""
                isLoading={loading}
                localization={{
                  body: {
                    emptyDataSourceMessage: "ไม่พบข้อมูล",
                  },
                  toolbar: {
                    searchPlaceholder: "ค้นหา...",
                  },
                  pagination: {
                    labelRowsSelect: "แถว",
                    firstTooltip: "หน้าแรก",
                    previousTooltip: "หน้าที่แล้ว",
                    nextTooltip: "หน้าต่อไป",
                    lastTooltip: "หน้าสุดท้าย",
                  },
                  header: {
                    actions: "Actions",
                  },
                }}
                columns={[
                  { title: "รหัสกลุ่มงานขาย", field: "รหัสกลุ่มงานขาย" },
                  { title: "ชื่อกลุ่มงานขาย", field: "ชื่อกลุ่มงานขาย" },
                ]}
                data={mockupload}
                options={tableOptions}
              />
            )}
          </div>
        </Modal.Body>
        {mockupload?.length > 0 && (
          <Modal.Footer className="d-flex justify-content-center">
            <button className="btn-liststore-2" onClick={clickConfirmUpload}>
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                "ตกลง"
              )}
            </button>
            <button className="btn-liststore-1" onClick={clickCancelUpload}>
              ยกเลิก
            </button>
          </Modal.Footer>
        )}
      </Modal>
      {/* end modal import */}
    </>
  );
};

export default ListSalesGroupDesktopPage;
