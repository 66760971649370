import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { sendmailforgotpassword } from "../../api/authApi";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const ForgotPasswordMobilePage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [isreset, setIsReset] = useState(false);
  const history = useHistory();

  const changeEmail = (e) => {
    try {
      setEmail(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickSendPassword = async () => {
    try {
      if (email === "") {
        return toast.error("กรุณาระบุอีเมลของท่าน!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const resSendMail = await sendmailforgotpassword(email);
      if (resSendMail?.message === "ส่งอีเมลสำเร็จ") {
        setLoading(false);
        setIsReset(true);
        setTimeout(() => {
          history.replace("/");
        }, 3000);
        return toast.success("โปรดตรวจสอบรหัสผ่านทางอีเมลของท่าน", {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ไม่พบอีเมลนี้ในระบบ!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div id="forgotpassword-mobile">
      <ToastContainer />
      <img src="./bg_1.png" alt="logo" className="bg-resetpassword" />
      <div className="container-resetpassword">
        <div className="heard-resetpassword">
          <img
            src="./logo_1.png"
            alt="logo"
            width={50}
            height={40}
            className="logo-login"
          />
          <div className="text-resetpassword-1">Forgot Password</div>
        </div>
        {isreset === false ? (
          <>
            <input
              type="text"
              className="input-resetpassword-1"
              placeholder="โปรดระบุอีเมล"
              value={email}
              onChange={changeEmail}
            />
            <button
              className="btn-login"
              onClick={clickSendPassword}
              disabled={loading}
            >
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                "ส่งรหัสผ่านใหม่"
              )}
            </button>
          </>
        ) : (
          <>
            <div className="text-resetpassword-2">ระบบได้ส่งลิ้งค์</div>
            <div className="text-resetpassword-3">
              โปรดตรวจสอบอีเมลของท่านอีกครั้ง
            </div>
            <img
              src="./icon_1.png"
              alt="logo"
              width={50}
              height={40}
              className="logo-resetpassword"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordMobilePage;
