import React, { useState, useEffect, useCallback, useRef } from "react";
import MenuBarDesktop from "../../components/MenuBarDesktop";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { refreshtoken } from "../../api/authApi";
import {
  activebyuser,
  inactivebyuser,
  closepost,
  bybarcode,
  upload,
  updatepost,
} from "../../api/productApi";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";

const HistoryPostDesktopPage = () => {
  const [typeproduct, setTypeProduct] = useState("product-active");
  const [sort, setSort] = useState(false);
  const [loading, setLoading] = useState(false);
  const [masterpostactive, setMasterPostActive] = useState([]);
  const [masterpostinactive, setMasterPostInActive] = useState([]);
  const [postactive, setPostActive] = useState([]);
  const [postinactive, setPostInActive] = useState([]);
  const [showmodalviewimage, setShowModalViewImage] = useState(false);
  const [showmodaleditpost, setShowModalEditPost] = useState(false);
  const [viewimage, setViewImage] = useState([]);
  const [closepostmodal, setClosePostModal] = useState(false);
  const [mockpostclose, setMockPostClose] = useState({});
  const [barcode, setBarcode] = useState("");
  const [nameproductth, setNameProductTh] = useState("");
  const [nameproducten, setNameProductEn] = useState("");
  const [sizeproduct, setSizeProduct] = useState("");
  const [shadeproduct, setShadeProduct] = useState("");
  const [amountproduct, setAmountProduct] = useState("");
  const [numberbatchproduct, setNumberBatchProduct] = useState("");
  const [selectprovin, setSelectProvin] = useState({});
  const [selectdistrict, setSelectDistrict] = useState({});
  const [salesgroupid, setSalesGroupId] = useState("");
  const [transporttype, setTranSportType] = useState("destination");
  const [messagedetail, setMessageDetail] = useState("");
  const [storetel, setStoreTel] = useState("");
  const [imageedit, setImageEdit] = useState("");
  const [imageupload, setImageUpload] = useState([]);
  const [ideditpost, setIdEditPost] = useState(0);
  const fileinputref = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  let controllerRef = null;

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "20px",
      borderRadius: "10px",
      border: "1px solid #b5adad",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 235,
      height: 40,
      fontSize: 14,
      fontFamily: "Kanit",
      "&:hover": {
        border: "2px solid #1C5BBD",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C5BBD" : "white",
      fontFamily: "Kanit",
      fontSize: 14,
      color: state.isSelected ? "white" : "#1C5BBD",
      "&:hover": {
        backgroundColor: "#1C5BBD",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1C5BBD",
    }),
  };

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const postactiveRes = await fetchActivePost(controllerRef?.signal);
      if (postactiveRes) {
        setMasterPostActive(postactiveRes);
        setPostActive(postactiveRes);
      }
      const postinactiveRes = await fetchInActivePost(controllerRef?.signal);
      if (postinactiveRes) {
        setMasterPostInActive(postinactiveRes);
        setPostInActive(postinactiveRes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchActivePost = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await activebyuser(startDate, endDate, signal);
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInActivePost = useCallback(
    async (signal) => {
      try {
        await refreshtoken(signal);
        const _data = await inactivebyuser(startDate, endDate, signal);
        if (_data) {
          return _data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clickSortDateNow = () => {
    try {
      setLoading(true);
      let data_clone1 = [...postactive];
      data_clone1.sort((a, b) => b.id - a.id);
      setPostActive(data_clone1);
      let data_clone2 = [...postinactive];
      data_clone2.sort((a, b) => b.id - a.id);
      setPostInActive(data_clone2);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSortDateLast = () => {
    try {
      setLoading(true);
      let data_clone1 = [...postactive];
      data_clone1.sort((a, b) => a.id - b.id);
      setPostActive(data_clone1);
      let data_clone2 = [...postinactive];
      data_clone2.sort((a, b) => a.id - b.id);
      setPostInActive(data_clone2);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSortFirstCharacter = () => {
    try {
      setLoading(true);
      let data_clone1 = [...postactive];
      data_clone1.sort((a, b) =>
        a.product_name_th.localeCompare(b.product_name_th, "th")
      );
      setPostActive(data_clone1);
      let data_clone2 = [...postinactive];
      data_clone2.sort((a, b) =>
        a.product_name_th.localeCompare(b.product_name_th, "th")
      );
      setPostInActive(data_clone2);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSortLastCharacter = () => {
    try {
      setLoading(true);
      let data_clone1 = [...postactive];
      data_clone1.sort((a, b) =>
        b.product_name_th.localeCompare(a.product_name_th, "th")
      );
      setPostActive(data_clone1);
      let data_clone2 = [...postinactive];
      data_clone2.sort((a, b) =>
        b.product_name_th.localeCompare(a.product_name_th, "th")
      );
      setPostInActive(data_clone2);
      setSort(!sort);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeSearch = async (e) => {
    try {
      setLoading(true);
      if (masterpostactive?.length > 0) {
        if (e.target.value?.length > 0) {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of masterpostactive.entries()) {
            if (
              JSON.stringify(item?.product_name_th)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.number_batch)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.product_amount).includes(
                e.target.value.toLowerCase()
              ) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.product_shade)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.product_size)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.companyname)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.provincename)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.districtname)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostActive(dataSame);
        } else {
          getDataDefault();
        }
      }
      if (masterpostinactive?.length > 0) {
        if (e.target.value?.length > 0) {
          let dataSame = [];
          // eslint-disable-next-line no-unused-vars
          for await (const [_, item] of masterpostinactive.entries()) {
            if (
              JSON.stringify(item?.product_name_th)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.number_batch)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.product_amount).includes(
                e.target.value.toLowerCase()
              ) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.product_shade)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.product_size)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.companyname)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.provincename)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            } else if (
              JSON.stringify(item?.districtname)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            }
          }
          setPostInActive(dataSame);
        } else {
          getDataDefault();
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickViewImage = async (data) => {
    try {
      if (JSON.parse(data)?.length > 0) {
        let data_images = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rimg] of JSON.parse(data).entries()) {
          data_images.push({
            original: `${process.env.REACT_APP_API_HOST}/images/${rimg}`,
            thumbnail: `${process.env.REACT_APP_API_HOST}/images/${rimg}`,
          });
        }
        setViewImage(data_images);
        setShowModalViewImage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickClosePost = (data) => {
    try {
      setClosePostModal(true);
      setMockPostClose(data);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmClosePost = async () => {
    try {
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);
      const resClose = await closepost(
        mockpostclose?.id,
        controllerRef?.signal
      );
      if (resClose?.status_code === 200) {
        toast.success("ปิดการโพสต์สำเร็จ!", {
          position: "top-center",
          autoClose: 3000,
        });
        getDataDefault();
        setClosePostModal(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const urlToFile = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();
      const filename = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
      const file = new File([blob], filename, { type: blob.type });
      return file;
    } catch (error) {
      console.error(`Failed to fetch and add image: ${error}`);
    }
  };

  const onChangeBarcode = async (e) => {
    try {
      setBarcode(e.target.value);
      if (e.target.value.length > 12) {
        await refreshtoken(controllerRef?.signal);
        const data_bybarcode = await bybarcode(e.target.value);
        if (data_bybarcode) {
          setNameProductTh(data_bybarcode?.product_name_th);
          setNameProductEn(data_bybarcode?.product_name_en);
          setSizeProduct(data_bybarcode?.product_size_th);
          setShadeProduct(data_bybarcode?.product_shade);
        } else {
          setBarcode("");
          setNameProductTh("");
          setNameProductEn("");
          setSizeProduct("");
          setShadeProduct("");
          return toast.error("ไม่พบรหัส Barcode นี้ในระบบ!", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      } else {
        setNameProductTh("");
        setNameProductEn("");
        setSizeProduct("");
        setShadeProduct("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadImageAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        let base64Image = e.target.result;
        await refreshtoken(controllerRef?.signal);
        const resPhoto = await upload(base64Image);
        resolve(resPhoto?.data);
      };
      reader.readAsDataURL(file);
    });
  };

  const clickConfirmPost = async () => {
    try {
      if (imageupload?.length < 1) {
        return toast.error("อัพโหลดรูปภาพอย่างน้อย 1 รูป!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (barcode === "" || barcode === null || barcode.length < 13) {
        return toast.error("กรุณาระบุบาร์โค้ดสินค้า!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (amountproduct === "" || amountproduct === null || amountproduct < 1) {
        return toast.error("กรุณาระบุจำนวน!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (numberbatchproduct === "" || numberbatchproduct === null) {
        return toast.error("กรุณาระบุเลขที่ Batch!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      let data_images = [];
      let data_images_new = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, r] of imageupload.entries()) {
        data_images.push(r?.name);
      }
      const new_imageedit = JSON.stringify(JSON.parse(imageedit));
      const new_images = JSON.stringify(data_images);
      // เช็คว่ามีการเปลี่ยนแปลงรูปภาพรึป่าว ถ้ามีให้ upload รูปใหม่
      if (new_imageedit !== new_images) {
        // eslint-disable-next-line no-unused-vars
        for await (const [_, r] of imageupload.entries()) {
          const resImage = await loadImageAsync(r);
          data_images_new.push(resImage);
        }
      }
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      await refreshtoken(controllerRef?.signal);

      const resUpdatePost = await updatepost(
        ideditpost,
        barcode,
        nameproductth,
        nameproducten,
        sizeproduct,
        shadeproduct,
        amountproduct,
        numberbatchproduct,
        salesgroupid,
        selectprovin?.value,
        selectdistrict?.value,
        transporttype === "destination" ? 1 : 2,
        messagedetail,
        storetel,
        new_imageedit !== new_images
          ? JSON.stringify(data_images_new)
          : imageedit
      );

      if (resUpdatePost?.status_code === 200) {
        setIdEditPost("");
        setBarcode("");
        setNameProductTh("");
        setNameProductEn("");
        setSizeProduct("");
        setShadeProduct("");
        setAmountProduct("");
        setNumberBatchProduct("");
        setTranSportType("destination");
        setMessageDetail("");
        setLoading(false);
        setShowModalEditPost(false);
        getDataDefault();
        toast.success(resUpdatePost?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickEditPost = async (data) => {
    try {
      setIdEditPost(data?.id);
      const profile = await localStorage.getItem("profile");
      const beforeImage = JSON.parse(data?.product_picture);
      if (beforeImage?.length > 0) {
        let data_images = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [ri, r] of beforeImage.entries()) {
          const resFile = await urlToFile(
            `https://api.qatoamatch.com/images/${r}`
          );
          if (resFile) {
            data_images.push(resFile);
          }
        }
        setImageUpload(data_images);
      }
      setBarcode(data?.barcode);
      setNameProductTh(data?.product_name_th);
      setNameProductEn(data?.product_name_en);
      setSizeProduct(data?.product_size);
      setShadeProduct(data?.product_shade);
      setAmountProduct(data?.product_amount);
      setNumberBatchProduct(data?.number_batch);
      setSelectProvin({
        value: JSON.parse(profile)?.token?.province_id,
        label: JSON.parse(profile)?.province_name,
      });
      setSelectDistrict({
        value: JSON.parse(profile)?.token?.district_id,
        label: JSON.parse(profile)?.district_name,
      });
      setSalesGroupId(JSON.parse(profile)?.token?.sales_group_id);
      setStoreTel(JSON.parse(profile)?.token?.tel);
      setTranSportType(
        data?.delivery_details === 1 ? "destination" : "yourself"
      );
      setMessageDetail(data?.message_detail);
      setImageEdit(data?.product_picture);
      setShowModalEditPost(true);
    } catch (error) {
      console.log(error);
    }
  };

  const clickUploadImage = () => {
    try {
      fileinputref.current.click();
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowFile = async (event) => {
    try {
      let lastArray = [...event.target.files];
      if (lastArray?.length > 0) {
        let data_ok = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, r] of lastArray.entries()) {
          if (r?.size / 1024 / 1024 > 2) {
            document.getElementById("fileInput").value = "";
            return toast.error("ขนาดรูปภาพเกิน 2MB!", {
              position: "top-center",
              autoClose: 3000,
            });
          } else if (
            r?.type !== "image/jpg" &&
            r?.type !== "image/jpeg" &&
            r?.type !== "image/png"
          ) {
            return toast.error("รองรับเฉพาะ .jpg, .jpeg, .png!", {
              position: "top-center",
              autoClose: 3000,
            });
          } else {
            data_ok.push(r);
          }
        }
        let clone_data = [...imageupload];
        const new_data = data_ok.concat(clone_data);
        if (new_data?.length > 6) {
          document.getElementById("fileInput").value = "";
          return toast.error("เพิ่มรูปภาพสูงสุด 6 รูปเท่านั้น!", {
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          setImageUpload(new_data);
        }
      }
      document.getElementById("fileInput").value = "";
    } catch (error) {
      console.log(error);
    }
  };

  const clickDeleteImage = (index) => {
    try {
      let clone_data = [...imageupload];
      clone_data.splice(index, 1);
      setImageUpload(clone_data);
    } catch (error) {
      console.log(error);
    }
  };

  const changeStartDate = async (date) => {
    try {
      setStartDate(date);
      if (typeproduct === "product-active") {
        // eslint-disable-next-line no-undef
        controllerRef = new AbortController();
        await refreshtoken(controllerRef?.signal);
        const postactiveRes = await activebyuser(
          date,
          endDate,
          controllerRef?.signal
        );
        if (postactiveRes) {
          setMasterPostActive(postactiveRes);
          setPostActive(postactiveRes);
        }
      }
      if (typeproduct === "product-nonactive") {
        // eslint-disable-next-line no-undef
        controllerRef = new AbortController();
        await refreshtoken(controllerRef?.signal);
        const postinactiveRes = await inactivebyuser(
          date,
          endDate,
          controllerRef?.signal
        );
        if (postinactiveRes) {
          setMasterPostInActive(postinactiveRes);
          setPostInActive(postinactiveRes);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeEndDate = async (date) => {
    try {
      setEndDate(date);
      if (typeproduct === "product-active") {
        // eslint-disable-next-line no-undef
        controllerRef = new AbortController();
        await refreshtoken(controllerRef?.signal);
        const postactiveRes = await activebyuser(
          startDate,
          date,
          controllerRef?.signal
        );
        if (postactiveRes) {
          setMasterPostActive(postactiveRes);
          setPostActive(postactiveRes);
        }
      }
      if (typeproduct === "product-nonactive") {
        // eslint-disable-next-line no-undef
        controllerRef = new AbortController();
        await refreshtoken(controllerRef?.signal);
        const postinactiveRes = await inactivebyuser(
          startDate,
          date,
          controllerRef?.signal
        );
        if (postinactiveRes) {
          setMasterPostInActive(postinactiveRes);
          setPostInActive(postinactiveRes);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataDefault();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="historypost-desktop">
      <ToastContainer />
      <MenuBarDesktop getdata={getDataDefault} />
      <div className="container-home-search">
        <input
          type="text"
          className="input-home-1"
          placeholder="ค้นหาสินค้าที่ต้องการ"
          onChange={changeSearch}
        />
        <img
          src="./icon_7.png"
          alt="logo"
          width={18}
          height={18}
          className="logo-search"
        />
      </div>
      <div className="container-home-desktop-2">
        <div className="container-home-desktop-3">
          <div className="heard-home-4">
            <div className="container-home-desktop-5">
              <div className="text-home-12">ประวัติการโพสต์</div>
            </div>
            <div className="heard-historypost">
              <div>
                <div className="container-home-desktop-10">
                  <div className="text-home-13">เลือกวันที่ Start</div>
                  <img
                    src="./icon_12.png"
                    height={20}
                    alt="clock"
                    className="logo-clock"
                  />
                </div>
                <DatePicker
                  selected={startDate}
                  onChange={changeStartDate}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                <div className="container-home-desktop-10">
                  <div className="text-home-13">เลือกวันที่ End</div>
                  <img
                    src="./icon_12.png"
                    height={20}
                    alt="clock"
                    className="logo-clock"
                  />
                </div>
                <DatePicker
                  selected={endDate}
                  onChange={changeEndDate}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-home-desktop-4">
          <div className="container-home-desktop-9">
            <div
              className={
                typeproduct === "product-active"
                  ? "text-home-23"
                  : "text-home-22"
              }
              onClick={() => setTypeProduct("product-active")}
            >
              สินค้าที่ยังโพสต์อยู่
            </div>
            <div
              className={
                typeproduct === "product-nonactive"
                  ? "text-home-23"
                  : "text-home-22"
              }
              onClick={() => setTypeProduct("product-nonactive")}
            >
              สินค้าที่ครบอายุโพสต์
            </div>
            <div
              className="container-home-desktop-8"
              onClick={() => setSort(!sort)}
            >
              <div className="text-home-13">เรียงลำดับ</div>
              <img
                src="./icon_10.png"
                alt="tools"
                className="logo-select"
                height={10}
                width={20}
              />
            </div>
            {sort === true && (
              <div className="container-home-10">
                <div className="text-home-20" onClick={clickSortDateNow}>
                  เรียงตามวันที่โพสต์ล่าสุด
                </div>
                <div className="text-home-20" onClick={clickSortDateLast}>
                  เรียงตามวันที่โพสต์ก่อนหน้า
                </div>
                <div className="text-home-20" onClick={clickSortFirstCharacter}>
                  เรียงตามตัวอักษร ก > ฮ
                </div>
                <div className="text-home-20" onClick={clickSortLastCharacter}>
                  เรียงตามตัวอักษร ฮ > ก
                </div>
              </div>
            )}
          </div>
          <div className="heard-home-5">
            {/* start product-active */}
            {typeproduct === "product-active" &&
              (postactive?.length > 0 ? (
                postactive.map((item, index) => {
                  const news1 = item?.created_at.substring(0, 4);
                  const news2 = item?.created_at.substring(5, 7);
                  const news3 = item?.created_at.substring(8, 10);
                  return (
                    <div className="heard-home-6" key={index}>
                      <div
                        className="heard-home-7"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={170}
                          width={200}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="heard-home-8">
                        <div className="text-home-16">รายละเอียดสินค้า</div>
                        <div className="text-home-17">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            Batch: {item?.number_batch}
                          </div>
                          <div className="text-home-17">
                            จำนวน: {item?.product_amount} ถัง
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            เฉดสี: {item?.product_shade}
                          </div>
                          <div className="text-home-17">
                            ขนาด: {item?.product_size}
                          </div>
                        </div>
                        <div className="text-home-18">รายละเอียดร้านค้า</div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            ร้านค้า: {item?.companyname}
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            จังหวัด: {item?.provincename}
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            เขต/อำเภอ: {item?.districtname}
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            วันที่โพสต์: {news3 + "/" + news2 + "/" + news1}
                          </div>
                        </div>
                        <div className="heard-home-8">
                          <div className="text-home-17">
                            หมายเหตุ: {item?.message_detail}
                          </div>
                        </div>
                      </div>
                      <div className="heard-home-9">
                        <div className="btn-post-home-2">
                          <img
                            src="./icon_11.png"
                            alt="phone"
                            width={23}
                            height={20}
                            className="logo-phone-2"
                          />
                          โทร: {item?.tel}
                        </div>
                        <button
                          className="btn-post-home-3"
                          onClick={() => clickClosePost(item)}
                        >
                          ปิดการโพสต์
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              ))}
            {/* end product-active */}

            {/* start product-nonactive */}
            {typeproduct === "product-nonactive" &&
              (postinactive?.length > 0 ? (
                postinactive.map((item, index) => {
                  const news1 = item?.created_at.substring(0, 4);
                  const news2 = item?.created_at.substring(5, 7);
                  const news3 = item?.created_at.substring(8, 10);
                  return (
                    <div className="heard-home-6" key={index}>
                      <div
                        className="heard-home-7"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={170}
                          width={200}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="heard-home-8">
                        <div className="text-home-16">รายละเอียดสินค้า</div>
                        <div className="text-home-17">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            Batch: {item?.number_batch}
                          </div>
                          <div className="text-home-17">
                            จำนวน: {item?.product_amount} ถัง
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            เฉดสี: {item?.product_shade}
                          </div>
                          <div className="text-home-17">
                            ขนาด: {item?.product_size}
                          </div>
                        </div>

                        <div className="text-home-18">รายละเอียดร้านค้า</div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            ร้านค้า: {item?.companyname}
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            จังหวัด: {item?.provincename}
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            เขต/อำเภอ: {item?.districtname}
                          </div>
                        </div>
                        <div className="heard-home-10">
                          <div className="text-home-17">
                            วันที่โพสต์: {news3 + "/" + news2 + "/" + news1}
                          </div>
                        </div>
                        <div className="heard-home-8">
                          <div className="text-home-17">
                            หมายเหตุ: {item?.message_detail}
                          </div>
                        </div>
                      </div>
                      <div className="heard-home-9">
                        <div className="btn-post-home-2">
                          <img
                            src="./icon_11.png"
                            alt="phone"
                            width={23}
                            height={20}
                            className="logo-phone-2"
                          />
                          โทร: {item?.tel}
                        </div>
                        <button
                          className="btn-post-home-4"
                          onClick={() => clickEditPost(item)}
                        >
                          แก้ไขโพสต์เพื่อโพสต์อีกครั้ง
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              ))}
            {/* end product-nonactive */}
          </div>
        </div>
      </div>
      {/* start modal view image */}
      <Modal
        show={showmodalviewimage}
        onHide={() => setShowModalViewImage(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">รูปภาพ</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={viewimage} />
        </Modal.Body>
      </Modal>
      {/* end modal view image */}
      {/* start modal logout */}
      <Modal
        show={closepostmodal}
        onHide={() => setClosePostModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              <div className="text-home-20">คุณต้องการปิดการโพสต์?</div>
              <button className="btn-post-1" onClick={clickConfirmClosePost}>
                ยืนยัน
              </button>
              <button
                className="btn-post-2"
                onClick={() => setClosePostModal(false)}
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal logout */}

      {/* start modal edit post */}
      <Modal
        show={showmodaleditpost}
        onHide={() => setShowModalEditPost(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">แก้ไขโพสต์</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div
              className={
                imageupload?.length > 2 ? "image-menu" : "image-menu-disable"
              }
            >
              {/* File input element hidden by default */}
              <input
                type="file"
                id="fileInput"
                ref={fileinputref}
                style={{ display: "none" }}
                onChange={clickShowFile}
                multiple
              />
              {/* Image element acting as the clickable button */}
              <div className="container-post-image-2">
                <img
                  src="../../icon_6.png"
                  alt="Upload"
                  height={130}
                  width={140}
                  onClick={clickUploadImage}
                  style={{ cursor: "pointer" }}
                  className="image-menu-5"
                />
                {imageupload?.length === 0 && (
                  <div className="text-post-image">
                    *หมายเหตุ กรุณาแนบรูปภาพ
                  </div>
                )}
              </div>
              {imageupload?.length > 0
                ? imageupload?.map((item, index) => {
                    return (
                      <div className="image-menu-2" key={index}>
                        <img
                          src="../../icon_19.png"
                          height={20}
                          width={28}
                          alt="delete"
                          className="image-menu-4"
                          onClick={() => clickDeleteImage(index)}
                        />
                        <img
                          src={URL.createObjectURL(item)}
                          height={130}
                          width={140}
                          alt="upload"
                          className="image-menu-3"
                        />
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="text-post-1">รายละเอียดสินค้า</div>
            <div className="input-post">
              <input
                type="text"
                className={
                  barcode.length < 13 ? "input-post-1-error" : "input-post-1"
                }
                placeholder="รหัส Barcode"
                value={barcode}
                onChange={onChangeBarcode}
                maxLength={13}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="ชื่อสินค้า"
                disabled
                value={nameproductth}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="ขนาด"
                disabled
                value={sizeproduct}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="เฉดสี"
                disabled
                value={shadeproduct}
              />
              <input
                type="number"
                className={
                  amountproduct < 1 ? "input-post-1-error" : "input-post-1"
                }
                placeholder="จำนวน"
                maxLength={5}
                value={amountproduct}
                min={1}
                onChange={(e) => {
                  if (!e.target.value.match(/^([0-9])+$/i)) {
                    setAmountProduct("");
                  } else {
                    setAmountProduct(e.target.value);
                  }
                }}
              />
              <input
                type="text"
                className={
                  numberbatchproduct === ""
                    ? "input-post-1-error"
                    : "input-post-1"
                }
                placeholder="เลขที่ Batch"
                maxLength={20}
                value={numberbatchproduct}
                onChange={(e) => setNumberBatchProduct(e.target.value)}
              />
            </div>
            <div className="text-post-2">ระบุเขต</div>
            <div className="input-post">
              <Select
                defaultValue={selectprovin}
                value={selectprovin}
                styles={customstyles}
                isDisabled={true}
              />
              <Select
                defaultValue={selectdistrict}
                value={selectdistrict}
                styles={customstyles}
                isDisabled={true}
              />
            </div>
            <div className="text-post-3">รายละเอียดการจัดส่ง</div>
            <div className="body-select-1">
              {transporttype === "destination" ? (
                <div className="body-select-2">
                  <img
                    src="./icon_16.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-8">
                      ร้านค้าต้นทางจัดส่งสินค้าให้
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="body-select-4"
                  onClick={() => setTranSportType("destination")}
                >
                  <img
                    src="./icon_16.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-5">
                      ร้านค้าต้นทางจัดส่งสินค้าให้
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="body-select-1">
              {transporttype === "yourself" ? (
                <div className="body-select-2">
                  <img
                    src="./icon_17.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-8">ร้านค้าปลายทางมารับสินค้า</div>
                  </div>
                </div>
              ) : (
                <div
                  className="body-select-4"
                  onClick={() => setTranSportType("yourself")}
                >
                  <img
                    src="./icon_17.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-5">ร้านค้าปลายทางมารับสินค้า</div>
                  </div>
                </div>
              )}
            </div>
            <div className="body-select-5">
              <textarea
                className="textarea-post-1"
                placeholder="ข้อความเพิ่มเติม"
                rows="4"
                cols="50"
                value={messagedetail}
                maxLength={250}
                onChange={(e) => setMessageDetail(e.target.value)}
              />
            </div>
            <div className="body-select-6">
              <div className="body-select-7">
                <div className="text-post-5">กดปุ่ม</div>
                <div className="text-post-6">โพสต์สินค้า</div>
                <div className="text-post-5">เพื่อยอมรับ</div>
              </div>
              <div className="text-post-5">เงื่อนไขของการแลกเปลี่ยนสินค้า</div>
            </div>
            <div className="body-select-8">
              <button
                className="btn-post-1"
                onClick={clickConfirmPost}
                disabled={loading}
              >
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "โพสต์อีกครั้ง"
                )}
              </button>
              <button
                className="btn-post-2"
                onClick={() => setShowModalEditPost(false)}
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal edit post */}
    </div>
  );
};

export default HistoryPostDesktopPage;
