import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { forgotpassword } from "../../api/authApi";
import { Spinner } from "react-bootstrap";

const NewPasswordMobilePage = () => {
  const [isreset, setIsReset] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const changePassword = (e) => {
    try {
      setPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeConfirmPassword = (e) => {
    try {
      setConfirmPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirm = async () => {
    try {
      if (password === "" || password === null || password.length < 6) {
        return toast.error("กรุณาระบุรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (
        confirmpassword === "" ||
        confirmpassword === null ||
        confirmpassword.length < 6
      ) {
        return toast.error("กรุณาระบุยืนยันรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const resForgotPassword = await forgotpassword(password, confirmpassword);
      if (resForgotPassword?.status_code === 200) {
        setLoading(false);
        setIsReset(true);
        toast.success(resForgotPassword?.message, {
          position: "top-center",
          autoClose: 3000,
        });
        setTimeout(() => {
          history.replace("/");
        }, 3000);
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div id="newspassword-mobile">
      <ToastContainer />
      <img
        src={`${process.env.REACT_APP_FND_HOST}/bg_1.png`}
        alt="logo"
        className="bg-resetpassword"
      />
      <div className="container-resetpassword">
        <div className="heard-resetpassword">
          <img
            src={`${process.env.REACT_APP_FND_HOST}/logo_1.png`}
            alt="logo"
            width={50}
            height={40}
            className="logo-login"
          />
          <div className="text-resetpassword-1">New Password</div>
        </div>
        {isreset === false ? (
          <>
            <input
              type="text"
              className="input-resetpassword-1"
              placeholder="รหัสผ่าน *"
              value={password}
              onChange={changePassword}
            />
            <input
              type="text"
              className="input-resetpassword-1"
              placeholder="ยืนยันรหัสผ่าน *"
              value={confirmpassword}
              onChange={changeConfirmPassword}
            />
            <button
              className="btn-login"
              onClick={clickConfirm}
              disabled={loading}
            >
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                "ยืนยัน"
              )}
            </button>
          </>
        ) : (
          <>
            <div className="text-resetpassword-2">เรียบร้อย</div>
            <div className="text-resetpassword-3">
              ระบบได้เปลี่ยนรหัสผ่านของท่านแล้ว
            </div>
            <img
              src={`${process.env.REACT_APP_FND_HOST}/icon_1.png`}
              alt="logo"
              width={50}
              height={40}
              className="logo-resetpassword"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NewPasswordMobilePage;
