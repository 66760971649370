import React, { useState, useEffect, useCallback } from "react";
import MenuBarAdminDesktop from "../../components/MenuBarAdminDesktop";
import MaterialTable from "material-table";
import { refreshtoken } from "../../api/authApi";
import { masteradmin, importproductadmin } from "../../api/adminApi";
import XLSX from "xlsx";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
const ListProductDesktopPage = () => {
  const [loading, setLoading] = useState(false);
  const [allproduct, setAllProduct] = useState([]);
  const [importmodal, setImportModal] = useState(false);
  const [mockupload, setMockUpload] = useState([]);
  let controllerRef = null;

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const productRes = await fetchAllProduct(controllerRef?.signal);
      if (productRes) {
        setAllProduct(productRes);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAllProduct = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await masteradmin(signal);
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const tableOptions = {
    actionsColumnIndex: 0, // Set the index of the actions column
    toolbarButtonAlignment: "left", // Align the toolbar buttons to the left
    paging: true,
    pageSize: 10, // make initial page size
    emptyRowsWhenPaging: false, // To avoid of having empty rows
    pageSizeOptions: [10, 30, 50, 100], // rows selection options
  };

  useEffect(() => {
    getDataDefault();

    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickExportFile = async () => {
    let data_new = [];
    // eslint-disable-next-line no-unused-vars
    for await (const [_, item] of allproduct.entries()) {
      data_new.push({
        บาร์โค้ด: item?.barcode,
        หมายเลขวัสดุ: item?.material_no,
        "ชื่อสินค้า(en)": item?.product_name_en,
        "ชื่อสินค้า(th)": item?.product_name_th,
        เบอร์เฉด: item?.product_shade,
        "ขนาด(en)": item?.product_size_en,
        "ขนาด(th)": item?.product_size_th,
        ประเภทสี: item?.product_color,
        ประเภทฟิล์ม: item?.type_film,
        การใช้งานสี: item?.type_outside_inside,
      });
    }

    const dataWS = XLSX.utils.json_to_sheet(data_new, {
      header: [
        "บาร์โค้ด",
        "หมายเลขวัสดุ",
        "ชื่อสินค้า(en)",
        "ชื่อสินค้า(th)",
        "เบอร์เฉด",
        "ขนาด(en)",
        "ขนาด(th)",
        "ประเภทสี",
        "ประเภทฟิล์ม",
        "การใช้งานสี",
      ],
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, dataWS, "ProductMaster");
    XLSX.writeFile(wb, "productmaster.xlsx");
  };

  const changeFileImport = (e) => {
    try {
      const filesImport = e.target.files[0];
      if (
        filesImport.type !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setMockUpload([]);
        return toast.error("รองรับนามสกุลไฟล์ .xls และ .xlsx!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (filesImport) {
        setLoading(true);
        let fileReader = new FileReader();
        fileReader.readAsBinaryString(filesImport);
        fileReader.onload = (event) => {
          let data = event.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          workbook.SheetNames.forEach((sheet) => {
            let rowObject = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheet]
            );
            setMockUpload(rowObject);
          });
        };
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmUpload = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      let data_ok = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of mockupload.entries()) {
        data_ok.push({
          barcode: item["บาร์โค้ด"],
          material_no: item["หมายเลขวัสดุ"],
          product_name_en: item["ชื่อสินค้า(en)"],
          product_size_en: item["ขนาด(en)"],
          product_name_th: item["ชื่อสินค้า(th)"],
          product_size_th: item["ขนาด(th)"],
          product_shade: item["เบอร์เฉด"],
          product_color: item["ประเภทสี"],
          type_outside_inside: item["การใช้งานสี"],
          type_film: item["ประเภทฟิล์ม"],
        });
      }
      await refreshtoken(controllerRef?.signal);
      const resImport = await importproductadmin(
        data_ok,
        controllerRef?.signal
      );
      if (resImport?.status_code === 200) {
        const productRes = await fetchAllProduct(controllerRef?.signal);
        if (productRes) {
          setAllProduct(productRes);
        }
        setMockUpload([]);
        setImportModal(false);
        setLoading(false);
        return toast.success(resImport?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickCancelUpload = async () => {
    try {
      setMockUpload([]);
      setImportModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <MenuBarAdminDesktop />
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          paddingTop: 15,
          paddingBottom: 30,
        }}
      >
        <div className="bg-login">
          <button
            className="btn-post-5"
            onClick={() => setImportModal(!importmodal)}
          >
            นำเข้า
          </button>
          <button
            className="btn-post-6"
            onClick={clickExportFile}
            disabled={allproduct?.length > 0 ? false : true}
          >
            ส่งออก
          </button>
        </div>
        <MaterialTable
          title="สินค้า"
          isLoading={loading}
          localization={{
            body: {
              emptyDataSourceMessage: "ไม่พบข้อมูล",
            },
            toolbar: {
              searchPlaceholder: "ค้นหา...",
            },
            pagination: {
              labelRowsSelect: "แถว",
              firstTooltip: "หน้าแรก",
              previousTooltip: "หน้าที่แล้ว",
              nextTooltip: "หน้าต่อไป",
              lastTooltip: "หน้าสุดท้าย",
            },
            header: {
              actions: "Actions",
            },
          }}
          columns={[
            { title: "บาร์โค้ด", field: "barcode" },
            { title: "หมายเลขวัสดุ", field: "material_no" },
            { title: "ชื่อสินค้า (en)", field: "product_name_en" },
            { title: "ชื่อสินค้า (th)", field: "product_name_th" },
            { title: "เบอร์เฉด", field: "product_shade" },
            { title: "ขนาด (en)", field: "product_size_en" },
            { title: "ขนาด (th)", field: "product_size_th" },
            { title: "ประเภทสี", field: "product_color" },
            { title: "ประเภทฟิล์ม", field: "type_film" },
            { title: "การใช้งานสี", field: "type_outside_inside" },
          ]}
          data={allproduct}
          options={tableOptions}
        />
      </div>
      {/* start modal import */}
      <Modal
        show={importmodal}
        onHide={clickCancelUpload}
        backdrop="static"
        keyboard={false}
        size={mockupload?.length > 0 ? "xl" : ""}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">นำเข้า</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <input
              id="importBrandInput"
              className={mockupload?.length > 0 ? "file-input" : ""}
              type="file"
              accept=".xls,.xlsx"
              onChange={changeFileImport}
            />
            {mockupload?.length > 0 && (
              <MaterialTable
                title=""
                isLoading={loading}
                localization={{
                  body: {
                    emptyDataSourceMessage: "ไม่พบข้อมูล",
                  },
                  toolbar: {
                    searchPlaceholder: "ค้นหา...",
                  },
                  pagination: {
                    labelRowsSelect: "แถว",
                    firstTooltip: "หน้าแรก",
                    previousTooltip: "หน้าที่แล้ว",
                    nextTooltip: "หน้าต่อไป",
                    lastTooltip: "หน้าสุดท้าย",
                  },
                  header: {
                    actions: "Actions",
                  },
                }}
                columns={[
                  { title: "บาร์โค้ด", field: "บาร์โค้ด" },
                  { title: "หมายเลขวัสดุ", field: "หมายเลขวัสดุ" },
                  { title: "ชื่อสินค้า (en)", field: "ชื่อสินค้า(en)" },
                  { title: "ชื่อสินค้า (th)", field: "ชื่อสินค้า(th)" },
                  { title: "เบอร์เฉด", field: "เบอร์เฉด" },
                  { title: "ขนาด (en)", field: "ขนาด(en)" },
                  { title: "ขนาด (th)", field: "ขนาด(th)" },
                  { title: "ประเภทสี", field: "ประเภทสี" },
                  { title: "ประเภทฟิล์ม", field: "ประเภทฟิล์ม" },
                  { title: "การใช้งานสี", field: "การใช้งานสี" },
                ]}
                data={mockupload}
                options={tableOptions}
              />
            )}
          </div>
        </Modal.Body>
        {mockupload?.length > 0 && (
          <Modal.Footer className="d-flex justify-content-center">
            <button className="btn-liststore-2" onClick={clickConfirmUpload}>
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                "ตกลง"
              )}
            </button>
            <button className="btn-liststore-1" onClick={clickCancelUpload}>
              ยกเลิก
            </button>
          </Modal.Footer>
        )}
      </Modal>
      {/* end modal import */}
    </>
  );
};

export default ListProductDesktopPage;
