import React, { useState, useRef, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import {
  announce,
  upload,
  bybarcode,
  all,
  callphone,
  brand,
  shade,
  film,
  size,
} from "../../api/productApi";
import { refreshtoken, logout } from "../../api/authApi";
import { Spinner } from "react-bootstrap";
import ImageGallery from "react-image-gallery";

const HomeMobilePage = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [nameproductth, setNameProductTh] = useState("");
  const [nameproducten, setNameProductEn] = useState("");
  const [sizeproduct, setSizeProduct] = useState("");
  const [shadeproduct, setShadeProduct] = useState("");
  const [amountproduct, setAmountProduct] = useState("");
  const [numberbatchproduct, setNumberBatchProduct] = useState("");
  const [selectprovin, setSelectProvin] = useState("");
  const [selectdistrict, setSelectDistrict] = useState("");
  const [salesgroupid, setSalesGroupId] = useState("");
  const [transporttype, setTranSportType] = useState("destination");
  const [messagedetail, setMessageDetail] = useState("");
  const [storetel, setStoreTel] = useState("");
  const [showmodal, setShowModal] = useState(false);
  const [imageupload, setImageUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logoutmodal, setLogOutModal] = useState(false);
  const [masterpost, setMasterpost] = useState([]);
  const [masterbrand, setMasterBrand] = useState([]);
  const [mastershade, setMasterShade] = useState([]);
  const [masterfilm, setMasterFilm] = useState([]);
  const [mastersize, setMasterSize] = useState([]);
  const [masterbrandtemp, setMasterBrandTemp] = useState([]);
  const [mastershadetemp, setMasterShadeTemp] = useState([]);
  const [mastershadeselect, setMasterShadeSelect] = useState([]);
  const [masterfilmtemp, setMasterFilmTemp] = useState([]);
  const [mastersizetemp, setMasterSizeTemp] = useState([]);
  const [showmodalbrand, setShowModalBrand] = useState(false);
  const [showmodalshade, setShowModalShade] = useState(false);
  const [showmodalfilmtype, setShowModalFilmType] = useState(false);
  const [showmodalsize, setShowModalSize] = useState(false);
  const [showmodalviewimage, setShowModalViewImage] = useState(false);
  const [dataexteriorpaint, setDataExteriorPaint] = useState([]);
  const [datainteriorpaint, setDataInteriorPaint] = useState([]);
  const [datawoodstain, setDataWoodStain] = useState([]);
  const [dataspray, setDataSpray] = useState([]);
  const [dataspraypain, setDataSprayPain] = useState([]);
  const [dataglazedpaint, setDataGlazedPaint] = useState([]);
  const [amountsearch, setAmountSearch] = useState("");
  const [amountbrand, setAmountBrand] = useState([]);
  const [amountshade, setAmountShade] = useState([]);
  const [amountfilm, setAmountFilm] = useState([]);
  const [amountsize, setAmountSize] = useState([]);
  const [viewimage, setViewImage] = useState([]);
  const [viewall, setViewAll] = useState("");
  const fileinputref = useRef(null);
  let controllerRef = null;

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "20px",
      borderRadius: "10px",
      border: "1px solid #b5adad",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 235,
      height: 40,
      fontSize: 14,
      fontFamily: "Kanit",
      "&:hover": {
        border: "2px solid #1C5BBD",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C5BBD" : "white",
      fontFamily: "Kanit",
      fontSize: 14,
      color: state.isSelected ? "white" : "#1C5BBD",
      "&:hover": {
        backgroundColor: "#1C5BBD",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1C5BBD",
    }),
  };

  const clickPostProduct = () => {
    try {
      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeBarcode = async (e) => {
    try {
      setBarcode(e.target.value);
      if (e.target.value.length > 12) {
        await refreshtoken(controllerRef?.signal);
        const data_bybarcode = await bybarcode(e.target.value);
        if (data_bybarcode) {
          setNameProductTh(data_bybarcode?.product_name_th);
          setNameProductEn(data_bybarcode?.product_name_en);
          setSizeProduct(data_bybarcode?.product_size_th);
          setShadeProduct(data_bybarcode?.product_shade);
        } else {
          setBarcode("");
          setNameProductTh("");
          setNameProductEn("");
          setSizeProduct("");
          setShadeProduct("");
          return toast.error("ไม่พบรหัส Barcode นี้ในระบบ!", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      } else {
        setNameProductTh("");
        setNameProductEn("");
        setSizeProduct("");
        setShadeProduct("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelPost = () => {
    try {
      setBarcode("");
      setNameProductTh("");
      setNameProductEn("");
      setSizeProduct("");
      setShadeProduct("");
      setAmountProduct("");
      setNumberBatchProduct("");
      setTranSportType("destination");
      setMessageDetail("");
      setImageUpload([]);
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clickMenu = () => {
    try {
      setMenu(!menu);
    } catch (error) {
      console.log(error);
    }
  };

  const loadImageAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        let base64Image = e.target.result;
        await refreshtoken(controllerRef?.signal);
        const resPhoto = await upload(base64Image);
        resolve(resPhoto?.data);
      };
      reader.readAsDataURL(file);
    });
  };

  const clickConfirmPost = async () => {
    try {
      if (imageupload.length < 1) {
        return toast.error("อัพโหลดรูปภาพอย่างน้อย 1 รูป!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (barcode === "" || barcode === null || barcode.length < 13) {
        return toast.error("กรุณาระบุบาร์โค้ดสินค้า!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (amountproduct === "" || amountproduct === null || amountproduct < 1) {
        return toast.error("กรุณาระบุจำนวน!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (numberbatchproduct === "" || numberbatchproduct === null) {
        return toast.error("กรุณาระบุเลขที่ Batch!", {
          position: "top-center",
          autoClose: 3000,
        });
      }

      setLoading(true);

      let data_images = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, r] of imageupload.entries()) {
        const resImage = await loadImageAsync(r);
        data_images.push(resImage);
      }

      const resAnnounce = await announce(
        barcode,
        nameproductth,
        nameproducten,
        sizeproduct,
        shadeproduct,
        amountproduct,
        numberbatchproduct,
        salesgroupid,
        selectprovin?.value,
        selectdistrict?.value,
        transporttype === "destination" ? 1 : 2,
        messagedetail,
        storetel,
        JSON.stringify(data_images)
      );

      if (resAnnounce?.status_code === 201) {
        setBarcode("");
        setNameProductTh("");
        setNameProductEn("");
        setSizeProduct("");
        setShadeProduct("");
        setAmountProduct("");
        setNumberBatchProduct("");
        setTranSportType("destination");
        setMessageDetail("");
        setImageUpload([]);
        setLoading(false);
        setShowModal(false);
        getDataDefault();
        toast.success(resAnnounce?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDataDefault = async () => {
    try {
      setLoading(true);
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const postRes = await fetchAllPost(controllerRef?.signal);
      setMasterpost(postRes);
      let dataSame1 = [];
      let dataSame2 = [];
      let dataSame3 = [];
      let dataSame4 = [];
      let dataSame5 = [];
      let dataSame6 = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of postRes.entries()) {
        if (
          JSON.stringify(item?.product_color).toLowerCase().includes("สีน้ำ") >
            0 &&
          JSON.stringify(item?.type_outside_inside)
            .toLowerCase()
            .includes("ภายนอก") > 0
        ) {
          dataSame1.push(item);
        }
        if (
          JSON.stringify(item?.product_color).toLowerCase().includes("สีน้ำ") >
            0 &&
          JSON.stringify(item?.type_outside_inside)
            .toLowerCase()
            .includes("ภายใน") > 0
        ) {
          dataSame2.push(item);
        }
        if (
          JSON.stringify(item?.product_color)
            .toLowerCase()
            .includes("สีย้อมไม้") > 0
        ) {
          dataSame3.push(item);
        }
        if (
          JSON.stringify(item?.product_color)
            .toLowerCase()
            .includes("สีพ่นอุตสาหกรรม") > 0
        ) {
          dataSame4.push(item);
        }
        if (
          JSON.stringify(item?.product_color).toLowerCase().includes("สเปรย์") >
          0
        ) {
          dataSame5.push(item);
        }
        if (
          JSON.stringify(item?.product_color)
            .toLowerCase()
            .includes("สีเคลือบ") > 0
        ) {
          dataSame6.push(item);
        }
      }
      setDataExteriorPaint(dataSame1);
      setDataInteriorPaint(dataSame2);
      setDataWoodStain(dataSame3);
      setDataSprayPain(dataSame4);
      setDataSpray(dataSame5);
      setDataGlazedPaint(dataSame6);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getDataMaster = async () => {
    try {
      // eslint-disable-next-line no-undef
      controllerRef = new AbortController();
      const profile = await localStorage.getItem("profile");
      if (profile) {
        setSelectProvin({
          value: JSON.parse(profile)?.token?.province_id,
          label: JSON.parse(profile)?.province_name,
        });
        setSelectDistrict({
          value: JSON.parse(profile)?.token?.district_id,
          label: JSON.parse(profile)?.district_name,
        });
        setSalesGroupId(JSON.parse(profile)?.token?.sales_group_id);
        setStoreTel(JSON.parse(profile)?.token?.tel);
      }
      const brandRes = await fetchAllBrand(controllerRef?.signal);
      if (brandRes) {
        setMasterBrand(brandRes);
        setMasterBrandTemp(brandRes);
      }
      const shadeRes = await fetchAllShade(controllerRef?.signal);
      if (shadeRes) {
        setMasterShade(shadeRes);
        setMasterShadeTemp(shadeRes);
      }
      const filmRes = await fetchAllFilm(controllerRef?.signal);
      if (filmRes) {
        setMasterFilm(filmRes);
        setMasterFilmTemp(filmRes);
      }
      const sizeRes = await fetchAllSize(controllerRef?.signal);
      if (sizeRes) {
        setMasterSize(sizeRes);
        setMasterSizeTemp(sizeRes);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sortDataAll = async () => {
    try {
      setLoading(true);
      let master_temp = [];
      if (masterpost?.length > 0) {
        master_temp = [...masterpost];
      }

      let dataSame1 = [];
      let dataSame2 = [];
      let dataSame3 = [];
      let dataSame4 = [];
      let dataSame5 = [];
      let dataSame6 = [];

      // start check type product
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of master_temp.entries()) {
        if (
          JSON.stringify(item?.product_color).toLowerCase().includes("สีน้ำ") >
            0 &&
          JSON.stringify(item?.type_outside_inside)
            .toLowerCase()
            .includes("ภายนอก") > 0
        ) {
          dataSame1.push(item);
        }
        if (
          JSON.stringify(item?.product_color).toLowerCase().includes("สีน้ำ") >
            0 &&
          JSON.stringify(item?.type_outside_inside)
            .toLowerCase()
            .includes("ภายใน") > 0
        ) {
          dataSame2.push(item);
        }
        if (
          JSON.stringify(item?.product_name_th)
            .toLowerCase()
            .includes("สีย้อมไม้") > 0
        ) {
          dataSame3.push(item);
        }
        if (
          JSON.stringify(item?.product_color)
            .toLowerCase()
            .includes("สีพ่นอุตสาหกรรม") > 0
        ) {
          dataSame4.push(item);
        }
        if (
          JSON.stringify(item?.product_color).toLowerCase().includes("สเปรย์") >
          0
        ) {
          dataSame5.push(item);
        }
        if (
          JSON.stringify(item?.product_name_th)
            .toLowerCase()
            .includes("สีเคลือบ") > 0
        ) {
          dataSame6.push(item);
        }
      }
      // end check type product

      // start check search
      if (amountsearch !== "" && amountsearch !== null) {
        // ค้นหาที่ master post
        let data_same1 = [];
        let data_same2 = [];
        let data_same3 = [];
        let data_same4 = [];
        let data_same5 = [];
        let data_same6 = [];
        // -------------- start data_same1 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame1.entries()) {
          if (
            JSON.stringify(rmp?.product_name_th)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same1.push(rmp);
          } else if (
            JSON.stringify(rmp?.number_batch)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same1.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_amount).includes(
              amountsearch.toLowerCase()
            ) > 0
          ) {
            data_same1.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_shade)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same1.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_size)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same1.push(rmp);
          } else if (
            JSON.stringify(rmp?.companyname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same1.push(rmp);
          } else if (
            JSON.stringify(rmp?.provincename)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same1.push(rmp);
          } else if (
            JSON.stringify(rmp?.districtname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same1.push(rmp);
          }
        }
        const uniqueData1 = data_same1.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        // -------------- end data_same1 ---------------
        // -------------- start data_same2 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame2.entries()) {
          if (
            JSON.stringify(rmp?.product_name_th)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same2.push(rmp);
          } else if (
            JSON.stringify(rmp?.number_batch)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same2.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_amount).includes(
              amountsearch.toLowerCase()
            ) > 0
          ) {
            data_same2.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_shade)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same2.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_size)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same2.push(rmp);
          } else if (
            JSON.stringify(rmp?.companyname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same2.push(rmp);
          } else if (
            JSON.stringify(rmp?.provincename)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same2.push(rmp);
          } else if (
            JSON.stringify(rmp?.districtname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same2.push(rmp);
          }
        }
        const uniqueData2 = data_same2.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        // -------------- end data_same2 ---------------
        // -------------- start data_same3 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame3.entries()) {
          if (
            JSON.stringify(rmp?.product_name_th)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same3.push(rmp);
          } else if (
            JSON.stringify(rmp?.number_batch)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same3.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_amount).includes(
              amountsearch.toLowerCase()
            ) > 0
          ) {
            data_same3.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_shade)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same3.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_size)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same3.push(rmp);
          } else if (
            JSON.stringify(rmp?.companyname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same3.push(rmp);
          } else if (
            JSON.stringify(rmp?.provincename)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same3.push(rmp);
          } else if (
            JSON.stringify(rmp?.districtname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same3.push(rmp);
          }
        }
        const uniqueData3 = data_same3.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        // -------------- end data_same3 ---------------
        // -------------- start data_same4 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame4.entries()) {
          if (
            JSON.stringify(rmp?.product_name_th)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same4.push(rmp);
          } else if (
            JSON.stringify(rmp?.number_batch)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same4.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_amount).includes(
              amountsearch.toLowerCase()
            ) > 0
          ) {
            data_same4.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_shade)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same4.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_size)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same4.push(rmp);
          } else if (
            JSON.stringify(rmp?.companyname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same4.push(rmp);
          } else if (
            JSON.stringify(rmp?.provincename)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same4.push(rmp);
          } else if (
            JSON.stringify(rmp?.districtname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same4.push(rmp);
          }
        }
        const uniqueData4 = data_same4.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        // -------------- end data_same4 ---------------
        // -------------- start data_same5 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame5.entries()) {
          if (
            JSON.stringify(rmp?.product_name_th)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same5.push(rmp);
          } else if (
            JSON.stringify(rmp?.number_batch)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same5.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_amount).includes(
              amountsearch.toLowerCase()
            ) > 0
          ) {
            data_same5.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_shade)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same5.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_size)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same5.push(rmp);
          } else if (
            JSON.stringify(rmp?.companyname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same5.push(rmp);
          } else if (
            JSON.stringify(rmp?.provincename)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same5.push(rmp);
          } else if (
            JSON.stringify(rmp?.districtname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same5.push(rmp);
          }
        }
        const uniqueData5 = data_same5.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        // -------------- end data_same5 ---------------
        // -------------- start data_same6 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame6.entries()) {
          if (
            JSON.stringify(rmp?.product_name_th)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same6.push(rmp);
          } else if (
            JSON.stringify(rmp?.number_batch)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same6.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_amount).includes(
              amountsearch.toLowerCase()
            ) > 0
          ) {
            data_same6.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_shade)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same6.push(rmp);
          } else if (
            JSON.stringify(rmp?.product_size)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same6.push(rmp);
          } else if (
            JSON.stringify(rmp?.companyname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same6.push(rmp);
          } else if (
            JSON.stringify(rmp?.provincename)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same6.push(rmp);
          } else if (
            JSON.stringify(rmp?.districtname)
              .toLowerCase()
              .includes(amountsearch.toLowerCase()) > 0
          ) {
            data_same6.push(rmp);
          }
        }
        const uniqueData6 = data_same6.filter((obj, index, self) => {
          return (
            index ===
            self.findIndex(
              (item) => item.id === obj.id // Change 'name' to the desired property
            )
          );
        });
        // -------------- end data_same6 ---------------
        dataSame1 = uniqueData1;
        dataSame2 = uniqueData2;
        dataSame3 = uniqueData3;
        dataSame4 = uniqueData4;
        dataSame5 = uniqueData5;
        dataSame6 = uniqueData6;
      }
      // end check search

      // start check brand
      if (amountbrand?.length > 0) {
        // ค้นหาที่ master post
        let data_same1 = [];
        let data_same2 = [];
        let data_same3 = [];
        let data_same4 = [];
        let data_same5 = [];
        let data_same6 = [];
        // -------------- start data_same1 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame1.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountbrand.entries()) {
            if (
              JSON.stringify(rmp?.product_name_en)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same1.push(rmp);
            }
          }
        }
        // -------------- end data_same1 ---------------
        // -------------- start data_same2 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame2.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountbrand.entries()) {
            if (
              JSON.stringify(rmp?.product_name_en)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same2.push(rmp);
            }
          }
        }
        // -------------- end data_same2 ---------------
        // -------------- start data_same3 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame3.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountbrand.entries()) {
            if (
              JSON.stringify(rmp?.product_name_en)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same3.push(rmp);
            }
          }
        }
        // -------------- end data_same3 ---------------
        // -------------- start data_same4 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame4.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountbrand.entries()) {
            if (
              JSON.stringify(rmp?.product_name_en)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same4.push(rmp);
            }
          }
        }
        // -------------- end data_same4 ---------------
        // -------------- start data_same5 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame5.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountbrand.entries()) {
            if (
              JSON.stringify(rmp?.product_name_en)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same5.push(rmp);
            }
          }
        }
        // -------------- end data_same5 ---------------
        // -------------- start data_same6 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame6.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountbrand.entries()) {
            if (
              JSON.stringify(rmp?.product_name_en)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same6.push(rmp);
            }
          }
        }
        // -------------- end data_same6 ---------------
        dataSame1 = data_same1;
        dataSame2 = data_same2;
        dataSame3 = data_same3;
        dataSame4 = data_same4;
        dataSame5 = data_same5;
        dataSame6 = data_same6;
      }
      // end check brand

      // start check shade
      if (amountshade?.length > 0) {
        // ค้นหาที่ master post
        let data_same1 = [];
        let data_same2 = [];
        let data_same3 = [];
        let data_same4 = [];
        let data_same5 = [];
        let data_same6 = [];
        // -------------- start data_same1 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame1.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountshade.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same1.push(rmp);
            }
          }
        }
        // -------------- end data_same1 ---------------
        // -------------- start data_same2 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame2.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountshade.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same2.push(rmp);
            }
          }
        }
        // -------------- end data_same2 ---------------
        // -------------- start data_same3 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame3.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountshade.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same3.push(rmp);
            }
          }
        }
        // -------------- end data_same3 ---------------
        // -------------- start data_same4 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame4.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountshade.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same4.push(rmp);
            }
          }
        }
        // -------------- end data_same4 ---------------
        // -------------- start data_same5 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame5.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountshade.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same5.push(rmp);
            }
          }
        }
        // -------------- end data_same5 ---------------
        // -------------- start data_same6 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame6.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountshade.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same6.push(rmp);
            }
          }
        }
        // -------------- end data_same6 ---------------
        dataSame1 = data_same1;
        dataSame2 = data_same2;
        dataSame3 = data_same3;
        dataSame4 = data_same4;
        dataSame5 = data_same5;
        dataSame6 = data_same6;
      }
      // end check shade

      // start check film type
      if (amountfilm?.length > 0) {
        // ค้นหาที่ master post
        let data_same1 = [];
        let data_same2 = [];
        let data_same3 = [];
        let data_same4 = [];
        let data_same5 = [];
        let data_same6 = [];
        // -------------- start data_same1 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame1.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountfilm.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same1.push(rmp);
            }
          }
        }
        // -------------- end data_same1 ---------------
        // -------------- start data_same2 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame2.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountfilm.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same2.push(rmp);
            }
          }
        }
        // -------------- end data_same2 ---------------
        // -------------- start data_same3 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame3.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountfilm.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same3.push(rmp);
            }
          }
        }
        // -------------- end data_same3 ---------------
        // -------------- start data_same4 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame4.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountfilm.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same4.push(rmp);
            }
          }
        }
        // -------------- end data_same4 ---------------
        // -------------- start data_same5 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame5.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountfilm.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same5.push(rmp);
            }
          }
        }
        // -------------- end data_same5 ---------------
        // -------------- start data_same6 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame6.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountfilm.entries()) {
            if (
              JSON.stringify(rmp?.product_name_th)
                .toLowerCase()
                .includes(rs?.value_search.toLowerCase()) > 0
            ) {
              data_same6.push(rmp);
            }
          }
        }
        // -------------- end data_same6 ---------------
        dataSame1 = data_same1;
        dataSame2 = data_same2;
        dataSame3 = data_same3;
        dataSame4 = data_same4;
        dataSame5 = data_same5;
        dataSame6 = data_same6;
      }
      // end check film type

      // start check size
      if (amountsize?.length > 0) {
        // ค้นหาที่ master post
        let data_same1 = [];
        let data_same2 = [];
        let data_same3 = [];
        let data_same4 = [];
        let data_same5 = [];
        let data_same6 = [];
        // -------------- start data_same1 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame1.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountsize.entries()) {
            if (
              JSON.stringify(rmp?.product_size).includes(
                JSON.stringify(rs?.value_search)
              ) > 0
            ) {
              data_same1.push(rmp);
            }
          }
        }
        // -------------- end data_same1 ---------------
        // -------------- start data_same2 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame2.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountsize.entries()) {
            if (
              JSON.stringify(rmp?.product_size).includes(
                JSON.stringify(rs?.value_search)
              ) > 0
            ) {
              data_same2.push(rmp);
            }
          }
        }
        // -------------- end data_same2 ---------------
        // -------------- start data_same3 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame3.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountsize.entries()) {
            if (
              JSON.stringify(rmp?.product_size).includes(
                JSON.stringify(rs?.value_search)
              ) > 0
            ) {
              data_same3.push(rmp);
            }
          }
        }
        // -------------- end data_same3 ---------------
        // -------------- start data_same4 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame4.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountsize.entries()) {
            if (
              JSON.stringify(rmp?.product_size).includes(
                JSON.stringify(rs?.value_search)
              ) > 0
            ) {
              data_same4.push(rmp);
            }
          }
        }
        // -------------- end data_same4 ---------------
        // -------------- start data_same5 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame5.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountsize.entries()) {
            if (
              JSON.stringify(rmp?.product_size).includes(
                JSON.stringify(rs?.value_search)
              ) > 0
            ) {
              data_same5.push(rmp);
            }
          }
        }
        // -------------- end data_same5 ---------------
        // -------------- start data_same6 ---------------
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rmp] of dataSame6.entries()) {
          // eslint-disable-next-line no-unused-vars
          for await (const [_, rs] of amountsize.entries()) {
            if (
              JSON.stringify(rmp?.product_size).includes(
                JSON.stringify(rs?.value_search)
              ) > 0
            ) {
              data_same6.push(rmp);
            }
          }
        }
        // -------------- end data_same6 ---------------
        dataSame1 = data_same1;
        dataSame2 = data_same2;
        dataSame3 = data_same3;
        dataSame4 = data_same4;
        dataSame5 = data_same5;
        dataSame6 = data_same6;
      }
      // end check size

      setDataExteriorPaint(dataSame1);
      setDataInteriorPaint(dataSame2);
      setDataWoodStain(dataSame3);
      setDataSprayPain(dataSame4);
      setDataSpray(dataSame5);
      setDataGlazedPaint(dataSame6);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchAllPost = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await all(signal);
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllBrand = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await brand(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllShade = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await shade(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllFilm = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await film(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchAllSize = useCallback(async (signal) => {
    try {
      await refreshtoken(signal);
      const _data = await size(signal);
      // eslint-disable-next-line array-callback-return
      await _data.map((item) => {
        item.checked = false;
      });
      if (_data) {
        return _data;
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const changeSearch = async (e) => {
    try {
      setLoading(true);
      setAmountSearch(e.target.value);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeBrand = async (data, index) => {
    try {
      let data_mock = [...masterbrand];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterBrand(data_mock);
    } catch (error) {
      console.log(error);
    }
  };

  const changeShade = async (e, data, index) => {
    try {
      let data_mock = [...mastershade];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterShade(data_mock);
      if (e.target.checked === true) {
        let data_clone = [...mastershadeselect];
        data_clone.push({ ...data, checked: e.target.checked });
        setMasterShadeSelect(data_clone);
      } else {
        let data_clone = [...mastershadeselect];
        let indexShade = data_clone.findIndex((x) => x.id === data?.id);
        data_clone.splice(indexShade, 1);
        setMasterShadeSelect(data_clone);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeFilm = async (data, index) => {
    try {
      let data_mock = [...masterfilm];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterFilm(data_mock);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSize = async (data, index) => {
    try {
      let data_mock = [...mastersize];
      data_mock[index] = {
        ...data_mock[index],
        checked: !data_mock[index]?.checked,
      };
      setMasterSize(data_mock);
    } catch (error) {
      console.log(error);
    }
  };

  const clickSelectBrand = async () => {
    try {
      setLoading(true);
      if (masterbrand?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await masterbrand.filter((x) => x.checked === true);
        setAmountBrand(data_selete);
        setShowModalBrand(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectShade = async () => {
    try {
      setLoading(true);
      if (mastershade?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await mastershadeselect.filter(
          (x) => x.checked === true
        );
        setAmountShade(data_selete);
        setShowModalShade(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectFilmType = async () => {
    try {
      setLoading(true);
      if (masterfilm?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await masterfilm.filter((x) => x.checked === true);
        setAmountFilm(data_selete);
        setShowModalFilmType(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickSelectSize = async () => {
    try {
      setLoading(true);
      if (mastersize?.length > 0) {
        // เอาตัวที่เลือกมา
        const data_selete = await mastersize.filter((x) => x.checked === true);
        setAmountSize(data_selete);
        setShowModalSize(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDataDefault();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDataMaster();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sortDataAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountsearch, amountbrand, amountshade, amountfilm, amountsize]);

  const clickConfirmLogout = async () => {
    try {
      await refreshtoken(controllerRef?.signal);
      await logout();
      setMenu(!menu);
      setLogOutModal(false);
      history.replace("/");
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelLogout = () => {
    try {
      setLogOutModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clickPhone = async (data) => {
    try {
      await refreshtoken(controllerRef?.signal);
      const resPhone = await callphone(data?.tel, data?.id);
      if (resPhone?.status_code === 201) {
        window.open(`tel:${data?.tel}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickViewImage = async (data) => {
    try {
      if (JSON.parse(data)?.length > 0) {
        let data_images = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, rimg] of JSON.parse(data).entries()) {
          data_images.push({
            original: `${process.env.REACT_APP_API_HOST}/images/${rimg}`,
            thumbnail: `${process.env.REACT_APP_API_HOST}/images/${rimg}`,
          });
        }
        setViewImage(data_images);
        setShowModalViewImage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickUploadImage = () => {
    try {
      fileinputref.current.click();
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowFile = async (event) => {
    try {
      let lastArray = [...event.target.files];
      if (lastArray?.length > 0) {
        let data_ok = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, r] of lastArray.entries()) {
          if (r?.size / 1024 / 1024 > 2) {
            document.getElementById("fileInput").value = "";
            return toast.error("ขนาดรูปภาพเกิน 2MB!", {
              position: "top-center",
              autoClose: 3000,
            });
          } else if (
            r?.type !== "image/jpg" &&
            r?.type !== "image/jpeg" &&
            r?.type !== "image/png"
          ) {
            return toast.error("รองรับเฉพาะ .jpg, .jpeg, .png!", {
              position: "top-center",
              autoClose: 3000,
            });
          } else {
            data_ok.push(r);
          }
        }
        let clone_data = [...imageupload];
        const new_data = data_ok.concat(clone_data);
        if (new_data?.length > 6) {
          document.getElementById("fileInput").value = "";
          return toast.error("เพิ่มรูปภาพสูงสุด 6 รูปเท่านั้น!", {
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          setImageUpload(new_data);
        }
      }
      document.getElementById("fileInput").value = "";
    } catch (error) {
      console.log(error);
    }
  };

  const clickDeleteImage = (index) => {
    try {
      let clone_data = [...imageupload];
      clone_data.splice(index, 1);
      setImageUpload(clone_data);
    } catch (error) {
      console.log(error);
    }
  };

  const clickClearSelectProduct = async () => {
    try {
      setMasterBrand(masterbrandtemp);
      setMasterShade(mastershadetemp);
      setMasterShadeSelect([]);
      setMasterFilm(masterfilmtemp);
      setMasterSize(mastersizetemp);
      setAmountBrand([]);
      setAmountShade([]);
      setAmountFilm([]);
      setAmountSize([]);
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowModalShade = async () => {
    try {
      const data_selete = await amountshade.filter((x) => x.checked === true);
      let news_data = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, item] of mastershadetemp.entries()) {
        const data_same = await data_selete.filter((x) => x.id === item?.id);
        if (data_same?.length > 0) {
          news_data.push({
            ...item,
            checked: data_same[0]?.checked,
          });
        } else {
          news_data.push(item);
        }
      }
      setMasterShade(news_data);
      setShowModalShade(true);
    } catch (error) {
      console.log(error);
    }
  };

  const changeSearchShade = async (e) => {
    try {
      setLoading(true);
      if (e.target.value?.length > 0) {
        let dataSame = [];
        const data_selete = await mastershadeselect.filter(
          (x) => x.checked === true
        );
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of mastershadetemp.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let new_data = {
              ...item,
              checked: !item.checked,
            };
            if (
              JSON.stringify(item?.product_shade)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(new_data);
            }
          } else {
            if (
              JSON.stringify(item?.product_shade)
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) > 0
            ) {
              dataSame.push(item);
            }
          }
        }
        setMasterShade(dataSame);
      } else {
        const data_selete = await mastershadeselect.filter(
          (x) => x.checked === true
        );
        let dataSame = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, item] of mastershadetemp.entries()) {
          const data_same = await data_selete.filter((x) => x.id === item?.id);
          if (data_same?.length > 0) {
            let data_ok = {
              ...item,
              checked: !item?.checked,
            };
            dataSame.push(data_ok);
          } else {
            dataSame.push(item);
          }
        }
        setMasterShade(dataSame);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div id="home-mobile">
      <ToastContainer />
      <Modal
        show={showmodal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">โพสต์สินค้า</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div
              className={
                imageupload?.length > 1 ? "image-menu" : "image-menu-disable"
              }
            >
              {/* File input element hidden by default */}
              <input
                type="file"
                id="fileInput"
                ref={fileinputref}
                style={{ display: "none" }}
                onChange={clickShowFile}
                multiple
              />
              {/* Image element acting as the clickable button */}
              <div className="container-post-image-2">
                <img
                  src="../../icon_6.png"
                  alt="Upload"
                  height={130}
                  width={140}
                  onClick={clickUploadImage}
                  style={{ cursor: "pointer" }}
                  className="image-menu-5"
                />
                {imageupload?.length === 0 && (
                  <div className="text-post-image">
                    *หมายเหตุ กรุณาแนบรูปภาพ
                  </div>
                )}
              </div>
              {imageupload?.length > 0
                ? imageupload?.map((item, index) => {
                    return (
                      <div className="image-menu-2" key={index}>
                        <img
                          src="../../icon_19.png"
                          height={20}
                          width={28}
                          alt="delete"
                          className="image-menu-4"
                          onClick={() => clickDeleteImage(index)}
                        />
                        <img
                          src={URL.createObjectURL(item)}
                          height={130}
                          width={140}
                          alt="upload"
                          className="image-menu-3"
                        />
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="text-post-1">รายละเอียดสินค้า</div>
            <div className="input-post">
              <input
                type="text"
                className={
                  barcode.length < 13 ? "input-post-1-error" : "input-post-1"
                }
                placeholder="รหัส Barcode"
                value={barcode}
                onChange={onChangeBarcode}
                maxLength={13}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="ชื่อสินค้า"
                disabled
                value={nameproductth}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="ขนาด"
                disabled
                value={sizeproduct}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="เฉดสี"
                disabled
                value={shadeproduct}
              />
              <input
                type="number"
                className={
                  amountproduct < 1 ? "input-post-1-error" : "input-post-1"
                }
                placeholder="จำนวน"
                maxLength={5}
                value={amountproduct}
                min={1}
                onChange={(e) => {
                  if (!e.target.value.match(/^([0-9])+$/i)) {
                    setAmountProduct("");
                  } else {
                    setAmountProduct(e.target.value);
                  }
                }}
              />
              <input
                type="text"
                className={
                  numberbatchproduct === ""
                    ? "input-post-1-error"
                    : "input-post-1"
                }
                placeholder="เลขที่ Batch"
                maxLength={20}
                value={numberbatchproduct}
                onChange={(e) => setNumberBatchProduct(e.target.value)}
              />
            </div>
            <div className="text-post-2">ระบุเขต</div>
            <div className="input-post">
              <Select
                defaultValue={selectprovin}
                value={selectprovin}
                styles={customstyles}
                isDisabled={true}
              />
              <Select
                defaultValue={selectdistrict}
                value={selectdistrict}
                styles={customstyles}
                isDisabled={true}
              />
            </div>
            <div className="text-post-3">รายละเอียดการจัดส่ง</div>
            <div className="body-select-1">
              {transporttype === "destination" ? (
                <div className="body-select-2">
                  <img
                    src="./icon_16.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-8">
                      ร้านค้าต้นทางจัดส่งสินค้าให้
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="body-select-4"
                  onClick={() => setTranSportType("destination")}
                >
                  <img
                    src="./icon_16.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-5">
                      ร้านค้าต้นทางจัดส่งสินค้าให้
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="body-select-1">
              {transporttype === "yourself" ? (
                <div className="body-select-2">
                  <img
                    src="./icon_17.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-8">ร้านค้าปลายทางมารับสินค้า</div>
                  </div>
                </div>
              ) : (
                <div
                  className="body-select-4"
                  onClick={() => setTranSportType("yourself")}
                >
                  <img
                    src="./icon_17.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-5">ร้านค้าปลายทางมารับสินค้า</div>
                  </div>
                </div>
              )}
            </div>
            <div className="body-select-5">
              <textarea
                className="textarea-post-1"
                placeholder="ข้อความเพิ่มเติม"
                rows="4"
                cols="50"
                value={messagedetail}
                maxLength={250}
                onChange={(e) => setMessageDetail(e.target.value)}
              />
            </div>
            <div className="body-select-6">
              <div className="body-select-7">
                <div className="text-post-5">กดปุ่ม</div>
                <div className="text-post-6">โพสต์สินค้า</div>
                <div className="text-post-5">เพื่อยอมรับ</div>
              </div>
              <div className="text-post-5">เงื่อนไขของการแลกเปลี่ยนสินค้า</div>
            </div>
            <div className="body-select-8">
              <button
                className="btn-post-1"
                onClick={clickConfirmPost}
                disabled={loading}
              >
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "โพสต์สินค้า"
                )}
              </button>
              <button className="btn-post-2" onClick={clickCancelPost}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={logoutmodal}
        onHide={() => setLogOutModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              <div className="text-home-20">คุณต้องการออกจากระบบ?</div>
              <button className="btn-post-1" onClick={clickConfirmLogout}>
                ยืนยัน
              </button>
              <button className="btn-post-2" onClick={clickCancelLogout}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* start modal brand */}
      <Modal
        show={showmodalbrand}
        onHide={() => setShowModalBrand(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">ยี่ห้อ/แบรนด์</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              {masterbrand?.length > 0 ? (
                masterbrand.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">
                        {item?.product_brand_name}
                      </div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={() => changeBrand(item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {masterbrand?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectBrand}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal brand */}
      {/* start modal shade */}
      <Modal
        show={showmodalshade}
        onHide={() => setShowModalShade(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">เบอร์เฉด</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="container-home-search-2">
              <input
                type="text"
                className="input-home-5"
                placeholder="ค้นหาสินค้าที่ต้องการ"
                onChange={changeSearchShade}
              />
              <img
                src="./icon_7.png"
                alt="logo"
                width={18}
                height={18}
                className="logo-search"
              />
            </div>
            <div className="body-select-8">
              {mastershade?.length > 0 ? (
                mastershade.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">{item?.product_shade}</div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={(e) => changeShade(e, item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {mastershade?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectShade}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal shade */}
      {/* start modal film type */}
      <Modal
        show={showmodalfilmtype}
        onHide={() => setShowModalFilmType(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">ประเภทฟิล์ม</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              {masterfilm?.length > 0 ? (
                masterfilm.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">{item?.product_film}</div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={() => changeFilm(item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {masterfilm?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectFilmType}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal film type */}
      {/* start modal size */}
      <Modal
        show={showmodalsize}
        onHide={() => setShowModalSize(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">ขนาด</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              {mastersize?.length > 0 ? (
                mastersize.map((item, index) => {
                  return (
                    <label className="container" key={index}>
                      <div className="text-home-25">
                        {item?.product_size_name}
                      </div>
                      <input
                        type="checkbox"
                        checked={item?.checked}
                        onChange={() => changeSize(item, index)}
                      />
                      <span className="checkmark" />
                    </label>
                  );
                })
              ) : (
                <div className="bottom-login">
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    <div className="text-home-16">ไม่พบข้อมูล</div>
                  )}
                </div>
              )}
              {mastersize?.length > 0 && (
                <button
                  className="btn-post-1"
                  onClick={clickSelectSize}
                  disabled={loading}
                >
                  {loading === true ? (
                    <Spinner animation="border" variant="primary" />
                  ) : (
                    "เลือก"
                  )}
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal size */}
      {/* start modal view image */}
      <Modal
        show={showmodalviewimage}
        onHide={() => setShowModalViewImage(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">รูปภาพ</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageGallery items={viewimage} />
        </Modal.Body>
      </Modal>
      {/* end modal view image */}
      <div className="heard-home">
        <div className="heard-menu">
          <div className="text-home-1" onClick={clickPostProduct}>
            โพสต์สินค้า
          </div>
          <img
            src="./logo_1.png"
            alt="logo"
            width={41}
            height={34}
            className="logo-toa"
          />
          <img
            src="./icon_2.png"
            alt="logo"
            width={30}
            height={20}
            className="logo-menu"
            onClick={clickMenu}
          />
          <div
            className="container-home-6"
            style={{ display: menu === true ? "block" : "none" }}
          >
            <div
              className="text-home-20"
              onClick={() => {
                history.replace("/home");
                setMenu(!menu);
              }}
            >
              หน้าหลัก
            </div>
            <div
              className="text-home-20"
              onClick={() => {
                history.replace("/history");
                setMenu(!menu);
              }}
            >
              ประวัติการโพสต์
            </div>
            {/* <div className="text-home-20">วิธีการใช้งาน</div>
            <div className="text-home-20">นโยบายการใช้งาน</div> */}
            <div
              className="text-home-20"
              onClick={() => {
                setMenu(false);
                setLogOutModal(true);
              }}
            >
              ออกจากระบบ
            </div>
          </div>
        </div>
      </div>
      <div className="container-home">
        <input
          type="text"
          className="input-home-4"
          placeholder="ค้นหาสินค้าที่ต้องการ"
          onChange={changeSearch}
        />
        <img
          src="./icon_7.png"
          alt="logo"
          width={18}
          height={18}
          className="logo-search"
        />
      </div>
      <div className="heard-home-2">
        <div className="container-home-desktop-5">
          <div className="container-home-select-product">
            <div className="text-home-12">เลือกสินค้า</div>
            {amountbrand?.length > 0 ||
            amountshade?.length > 0 ||
            amountfilm?.length > 0 ||
            amountsize?.length > 0 ? (
              <button
                className="btn-filerter-home-1"
                onClick={clickClearSelectProduct}
              >
                ล้างค่า
              </button>
            ) : null}
          </div>
        </div>
        <div className="content-text-home">
          <div
            className="container-home-mobile-1"
            onClick={() => setShowModalBrand(true)}
          >
            <div className="text-home-7">ยี่ห้อ/แบรนด์</div>
            {amountbrand?.length > 0 && (
              <div className="text-home-27">{amountbrand?.length}</div>
            )}
          </div>
          <div
            className="container-home-mobile-1"
            onClick={() => setShowModalFilmType(true)}
          >
            <div className="text-home-7">ประเภทฟิล์ม</div>
            {amountfilm?.length > 0 && (
              <div className="text-home-27">{amountfilm?.length}</div>
            )}
          </div>
          <div
            className="container-home-mobile-1"
            onClick={() => setShowModalSize(true)}
          >
            <div className="text-home-7">ขนาด</div>
            {amountsize?.length > 0 && (
              <div className="text-home-27">{amountsize?.length}</div>
            )}
          </div>
          <div
            className="container-home-mobile-1"
            onClick={clickShowModalShade}
          >
            <div className="text-home-7">เบอร์เฉด</div>
            {amountshade?.length > 0 && (
              <div className="text-home-27">{amountshade?.length}</div>
            )}
          </div>
        </div>
      </div>

      {viewall === "" ? (
        <div className="heard-home-3">
          <div className="text-home-8">สีน้ำทาภายนอก</div>
          <div
            className="text-home-9"
            onClick={() => setViewAll("สีน้ำทาภายนอก")}
          >
            ดูทั้งหมด
          </div>
        </div>
      ) : (
        viewall === "สีน้ำทาภายนอก" && (
          <div className="heard-home-3">
            <div className="text-home-8">สีน้ำทาภายนอก</div>
            <div className="text-home-28" onClick={() => setViewAll("")}>
              x
            </div>
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="body-home">
          {/* start body data */}
          {dataexteriorpaint?.length > 0 ? (
            dataexteriorpaint.map((item, index) => {
              return (
                <div className="container-home-2" key={index}>
                  <div className="container-home-3">
                    <div
                      className="container-home-mobile-2"
                      onClick={() => clickViewImage(item?.product_picture)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_HOST}/images/${
                          JSON.parse(item?.product_picture)[0]
                        }`}
                        height={100}
                        width={100}
                        alt="logo"
                        className="logo-image-home"
                      />
                      <div className="heard-home-12">
                        <div className="text-post-5">ดูเพิ่มเติม</div>
                        <img
                          src="./icon_18.png"
                          alt="view"
                          width={17}
                          height={15}
                          className="logo-phone-2"
                        />
                      </div>
                    </div>
                    <div className="container-home-5">
                      <div className="text-home-10">
                        แบรนด์: {item?.product_name_th}
                      </div>
                      <div className="text-home-10">
                        Batch: {item?.number_batch}
                      </div>
                      <div className="text-home-10">
                        จำนวน: {item?.product_amount} ถัง
                      </div>
                      <div className="text-home-10">
                        เฉดสี: {item?.product_shade}
                      </div>
                      <div className="text-home-10">
                        ขนาด: {item?.product_size}
                      </div>
                      <div className="text-home-10">
                        ร้านค้า: {item?.companyname}
                      </div>
                      <div className="text-home-10">
                        จังหวัด: {item?.provincename}
                      </div>
                      <div className="text-home-10">
                        เขต: {item?.districtname}
                      </div>
                    </div>
                  </div>
                  <div className="text-home-24">
                    {item?.message_detail.length > 0
                      ? "*หมายเหตุ: " + item?.message_detail
                      : ""}
                  </div>
                  <div className="container-home-11">
                    <div
                      className="container-home-4"
                      onClick={() => clickPhone(item)}
                    >
                      <img
                        src="./icon_8.png"
                        alt="phone"
                        width={15}
                        height={14}
                        className="logo-phone"
                      />
                      <div className="text-home-5">โทร: {item?.tel}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bottom-login">
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <div className="text-home-16">ไม่พบข้อมูล</div>
              )}
            </div>
          )}
          {/* end body data */}
        </div>
      ) : (
        viewall === "สีน้ำทาภายนอก" && (
          <div className="body-home-2">
            {/* start body data */}
            {dataexteriorpaint?.length > 0 ? (
              dataexteriorpaint.map((item, index) => {
                return (
                  <div className="container-home-2" key={index}>
                    <div className="container-home-3">
                      <div
                        className="container-home-mobile-2"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={100}
                          width={100}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="container-home-5">
                        <div className="text-home-10">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="text-home-10">
                          Batch: {item?.number_batch}
                        </div>
                        <div className="text-home-10">
                          จำนวน: {item?.product_amount} ถัง
                        </div>
                        <div className="text-home-10">
                          เฉดสี: {item?.product_shade}
                        </div>
                        <div className="text-home-10">
                          ขนาด: {item?.product_size}
                        </div>
                        <div className="text-home-10">
                          ร้านค้า: {item?.companyname}
                        </div>
                        <div className="text-home-10">
                          จังหวัด: {item?.provincename}
                        </div>
                        <div className="text-home-10">
                          เขต: {item?.districtname}
                        </div>
                      </div>
                    </div>
                    <div className="text-home-24">
                      {item?.message_detail.length > 0
                        ? "*หมายเหตุ: " + item?.message_detail
                        : ""}
                    </div>
                    <div className="container-home-11">
                      <div
                        className="container-home-4"
                        onClick={() => clickPhone(item)}
                      >
                        <img
                          src="./icon_8.png"
                          alt="phone"
                          width={15}
                          height={14}
                          className="logo-phone"
                        />
                        <div className="text-home-5">โทร: {item?.tel}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bottom-login">
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <div className="text-home-16">ไม่พบข้อมูล</div>
                )}
              </div>
            )}
            {/* end body data */}
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="heard-home-3">
          <div className="text-home-8">สีน้ำทาภายใน</div>
          <div
            className="text-home-9"
            onClick={() => setViewAll("สีน้ำทาภายใน")}
          >
            ดูทั้งหมด
          </div>
        </div>
      ) : (
        viewall === "สีน้ำทาภายใน" && (
          <div className="heard-home-3">
            <div className="text-home-8">สีน้ำทาภายใน</div>
            <div className="text-home-28" onClick={() => setViewAll("")}>
              x
            </div>
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="body-home">
          {/* start body data */}
          {datainteriorpaint?.length > 0 ? (
            datainteriorpaint.map((item, index) => {
              return (
                <div className="container-home-2" key={index}>
                  <div className="container-home-3">
                    <div
                      className="container-home-mobile-2"
                      onClick={() => clickViewImage(item?.product_picture)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_HOST}/images/${
                          JSON.parse(item?.product_picture)[0]
                        }`}
                        height={100}
                        width={100}
                        alt="logo"
                        className="logo-image-home"
                      />
                      <div className="heard-home-12">
                        <div className="text-post-5">ดูเพิ่มเติม</div>
                        <img
                          src="./icon_18.png"
                          alt="view"
                          width={17}
                          height={15}
                          className="logo-phone-2"
                        />
                      </div>
                    </div>
                    <div className="container-home-5">
                      <div className="text-home-10">
                        แบรนด์: {item?.product_name_th}
                      </div>
                      <div className="text-home-10">
                        Batch: {item?.number_batch}
                      </div>
                      <div className="text-home-10">
                        จำนวน: {item?.product_amount} ถัง
                      </div>
                      <div className="text-home-10">
                        เฉดสี: {item?.product_shade}
                      </div>
                      <div className="text-home-10">
                        ขนาด: {item?.product_size}
                      </div>
                      <div className="text-home-10">
                        ร้านค้า: {item?.companyname}
                      </div>
                      <div className="text-home-10">
                        จังหวัด: {item?.provincename}
                      </div>
                      <div className="text-home-10">
                        เขต: {item?.districtname}
                      </div>
                    </div>
                  </div>
                  <div className="text-home-24">
                    {item?.message_detail.length > 0
                      ? "*หมายเหตุ: " + item?.message_detail
                      : ""}
                  </div>
                  <div className="container-home-11">
                    <div
                      className="container-home-4"
                      onClick={() => clickPhone(item)}
                    >
                      <img
                        src="./icon_8.png"
                        alt="phone"
                        width={15}
                        height={14}
                        className="logo-phone"
                      />
                      <div className="text-home-5">โทร: {item?.tel}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bottom-login">
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <div className="text-home-16">ไม่พบข้อมูล</div>
              )}
            </div>
          )}
          {/* end body data */}
        </div>
      ) : (
        viewall === "สีน้ำทาภายใน" && (
          <div className="body-home-2">
            {/* start body data */}
            {datainteriorpaint?.length > 0 ? (
              datainteriorpaint.map((item, index) => {
                return (
                  <div className="container-home-2" key={index}>
                    <div className="container-home-3">
                      <div
                        className="container-home-mobile-2"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={100}
                          width={100}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="container-home-5">
                        <div className="text-home-10">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="text-home-10">
                          Batch: {item?.number_batch}
                        </div>
                        <div className="text-home-10">
                          จำนวน: {item?.product_amount} ถัง
                        </div>
                        <div className="text-home-10">
                          เฉดสี: {item?.product_shade}
                        </div>
                        <div className="text-home-10">
                          ขนาด: {item?.product_size}
                        </div>
                        <div className="text-home-10">
                          ร้านค้า: {item?.companyname}
                        </div>
                        <div className="text-home-10">
                          จังหวัด: {item?.provincename}
                        </div>
                        <div className="text-home-10">
                          เขต: {item?.districtname}
                        </div>
                      </div>
                    </div>
                    <div className="text-home-24">
                      {item?.message_detail.length > 0
                        ? "*หมายเหตุ: " + item?.message_detail
                        : ""}
                    </div>
                    <div className="container-home-11">
                      <div
                        className="container-home-4"
                        onClick={() => clickPhone(item)}
                      >
                        <img
                          src="./icon_8.png"
                          alt="phone"
                          width={15}
                          height={14}
                          className="logo-phone"
                        />
                        <div className="text-home-5">โทร: {item?.tel}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bottom-login">
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <div className="text-home-16">ไม่พบข้อมูล</div>
                )}
              </div>
            )}
            {/* end body data */}
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="heard-home-3">
          <div className="text-home-8">สีย้อมไม้</div>
          <div className="text-home-9" onClick={() => setViewAll("สีย้อมไม้")}>
            ดูทั้งหมด
          </div>
        </div>
      ) : (
        viewall === "สีย้อมไม้" && (
          <div className="heard-home-3">
            <div className="text-home-8">สีย้อมไม้</div>
            <div className="text-home-28" onClick={() => setViewAll("")}>
              x
            </div>
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="body-home">
          {/* start body data */}
          {datawoodstain?.length > 0 ? (
            datawoodstain.map((item, index) => {
              return (
                <div className="container-home-2" key={index}>
                  <div className="container-home-3">
                    <div
                      className="container-home-mobile-2"
                      onClick={() => clickViewImage(item?.product_picture)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_HOST}/images/${
                          JSON.parse(item?.product_picture)[0]
                        }`}
                        height={100}
                        width={100}
                        alt="logo"
                        className="logo-image-home"
                      />
                      <div className="heard-home-12">
                        <div className="text-post-5">ดูเพิ่มเติม</div>
                        <img
                          src="./icon_18.png"
                          alt="view"
                          width={17}
                          height={15}
                          className="logo-phone-2"
                        />
                      </div>
                    </div>
                    <div className="container-home-5">
                      <div className="text-home-10">
                        แบรนด์: {item?.product_name_th}
                      </div>
                      <div className="text-home-10">
                        Batch: {item?.number_batch}
                      </div>
                      <div className="text-home-10">
                        จำนวน: {item?.product_amount} ถัง
                      </div>
                      <div className="text-home-10">
                        เฉดสี: {item?.product_shade}
                      </div>
                      <div className="text-home-10">
                        ขนาด: {item?.product_size}
                      </div>
                      <div className="text-home-10">
                        ร้านค้า: {item?.companyname}
                      </div>
                      <div className="text-home-10">
                        จังหวัด: {item?.provincename}
                      </div>
                      <div className="text-home-10">
                        เขต: {item?.districtname}
                      </div>
                    </div>
                  </div>
                  <div className="text-home-24">
                    {item?.message_detail.length > 0
                      ? "*หมายเหตุ: " + item?.message_detail
                      : ""}
                  </div>
                  <div className="container-home-11">
                    <div
                      className="container-home-4"
                      onClick={() => clickPhone(item)}
                    >
                      <img
                        src="./icon_8.png"
                        alt="phone"
                        width={15}
                        height={14}
                        className="logo-phone"
                      />
                      <div className="text-home-5">โทร: {item?.tel}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bottom-login">
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <div className="text-home-16">ไม่พบข้อมูล</div>
              )}
            </div>
          )}
          {/* end body data */}
        </div>
      ) : (
        viewall === "สีย้อมไม้" && (
          <div className="body-home-2">
            {/* start body data */}
            {datawoodstain?.length > 0 ? (
              datawoodstain.map((item, index) => {
                return (
                  <div className="container-home-2" key={index}>
                    <div className="container-home-3">
                      <div
                        className="container-home-mobile-2"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={100}
                          width={100}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="container-home-5">
                        <div className="text-home-10">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="text-home-10">
                          Batch: {item?.number_batch}
                        </div>
                        <div className="text-home-10">
                          จำนวน: {item?.product_amount} ถัง
                        </div>
                        <div className="text-home-10">
                          เฉดสี: {item?.product_shade}
                        </div>
                        <div className="text-home-10">
                          ขนาด: {item?.product_size}
                        </div>
                        <div className="text-home-10">
                          ร้านค้า: {item?.companyname}
                        </div>
                        <div className="text-home-10">
                          จังหวัด: {item?.provincename}
                        </div>
                        <div className="text-home-10">
                          เขต: {item?.districtname}
                        </div>
                      </div>
                    </div>
                    <div className="text-home-24">
                      {item?.message_detail.length > 0
                        ? "*หมายเหตุ: " + item?.message_detail
                        : ""}
                    </div>
                    <div className="container-home-11">
                      <div
                        className="container-home-4"
                        onClick={() => clickPhone(item)}
                      >
                        <img
                          src="./icon_8.png"
                          alt="phone"
                          width={15}
                          height={14}
                          className="logo-phone"
                        />
                        <div className="text-home-5">โทร: {item?.tel}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bottom-login">
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <div className="text-home-16">ไม่พบข้อมูล</div>
                )}
              </div>
            )}
            {/* end body data */}
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="heard-home-3">
          <div className="text-home-8">สีเคลือบเงา</div>
          <div
            className="text-home-9"
            onClick={() => setViewAll("สีเคลือบเงา")}
          >
            ดูทั้งหมด
          </div>
        </div>
      ) : (
        viewall === "สีเคลือบเงา" && (
          <div className="heard-home-3">
            <div className="text-home-8">สีเคลือบเงา</div>
            <div className="text-home-28" onClick={() => setViewAll("")}>
              x
            </div>
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="body-home">
          {/* start body data */}
          {dataglazedpaint?.length > 0 ? (
            dataglazedpaint.map((item, index) => {
              return (
                <div className="container-home-2" key={index}>
                  <div className="container-home-3">
                    <div
                      className="container-home-mobile-2"
                      onClick={() => clickViewImage(item?.product_picture)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_HOST}/images/${
                          JSON.parse(item?.product_picture)[0]
                        }`}
                        height={100}
                        width={100}
                        alt="logo"
                        className="logo-image-home"
                      />
                      <div className="heard-home-12">
                        <div className="text-post-5">ดูเพิ่มเติม</div>
                        <img
                          src="./icon_18.png"
                          alt="view"
                          width={17}
                          height={15}
                          className="logo-phone-2"
                        />
                      </div>
                    </div>
                    <div className="container-home-5">
                      <div className="text-home-10">
                        แบรนด์: {item?.product_name_th}
                      </div>
                      <div className="text-home-10">
                        Batch: {item?.number_batch}
                      </div>
                      <div className="text-home-10">
                        จำนวน: {item?.product_amount} ถัง
                      </div>
                      <div className="text-home-10">
                        เฉดสี: {item?.product_shade}
                      </div>
                      <div className="text-home-10">
                        ขนาด: {item?.product_size}
                      </div>
                      <div className="text-home-10">
                        ร้านค้า: {item?.companyname}
                      </div>
                      <div className="text-home-10">
                        จังหวัด: {item?.provincename}
                      </div>
                      <div className="text-home-10">
                        เขต: {item?.districtname}
                      </div>
                    </div>
                  </div>
                  <div className="text-home-24">
                    {item?.message_detail.length > 0
                      ? "*หมายเหตุ: " + item?.message_detail
                      : ""}
                  </div>
                  <div className="container-home-11">
                    <div
                      className="container-home-4"
                      onClick={() => clickPhone(item)}
                    >
                      <img
                        src="./icon_8.png"
                        alt="phone"
                        width={15}
                        height={14}
                        className="logo-phone"
                      />
                      <div className="text-home-5">โทร: {item?.tel}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bottom-login">
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <div className="text-home-16">ไม่พบข้อมูล</div>
              )}
            </div>
          )}
          {/* end body data */}
        </div>
      ) : (
        viewall === "สีเคลือบเงา" && (
          <div className="body-home-2">
            {/* start body data */}
            {dataglazedpaint?.length > 0 ? (
              dataglazedpaint.map((item, index) => {
                return (
                  <div className="container-home-2" key={index}>
                    <div className="container-home-3">
                      <div
                        className="container-home-mobile-2"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={100}
                          width={100}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="container-home-5">
                        <div className="text-home-10">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="text-home-10">
                          Batch: {item?.number_batch}
                        </div>
                        <div className="text-home-10">
                          จำนวน: {item?.product_amount} ถัง
                        </div>
                        <div className="text-home-10">
                          เฉดสี: {item?.product_shade}
                        </div>
                        <div className="text-home-10">
                          ขนาด: {item?.product_size}
                        </div>
                        <div className="text-home-10">
                          ร้านค้า: {item?.companyname}
                        </div>
                        <div className="text-home-10">
                          จังหวัด: {item?.provincename}
                        </div>
                        <div className="text-home-10">
                          เขต: {item?.districtname}
                        </div>
                      </div>
                    </div>
                    <div className="text-home-24">
                      {item?.message_detail.length > 0
                        ? "*หมายเหตุ: " + item?.message_detail
                        : ""}
                    </div>
                    <div className="container-home-11">
                      <div
                        className="container-home-4"
                        onClick={() => clickPhone(item)}
                      >
                        <img
                          src="./icon_8.png"
                          alt="phone"
                          width={15}
                          height={14}
                          className="logo-phone"
                        />
                        <div className="text-home-5">โทร: {item?.tel}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bottom-login">
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <div className="text-home-16">ไม่พบข้อมูล</div>
                )}
              </div>
            )}
            {/* end body data */}
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="heard-home-3">
          <div className="text-home-8">สีพ่นอุตสาหกรรม</div>
          <div
            className="text-home-9"
            onClick={() => setViewAll("สีพ่นอุตสาหกรรม")}
          >
            ดูทั้งหมด
          </div>
        </div>
      ) : (
        viewall === "สีพ่นอุตสาหกรรม" && (
          <div className="heard-home-3">
            <div className="text-home-8">สีพ่นอุตสาหกรรม</div>
            <div className="text-home-28" onClick={() => setViewAll("")}>
              x
            </div>
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="body-home">
          {/* start body data */}
          {dataspraypain?.length > 0 ? (
            dataspraypain.map((item, index) => {
              return (
                <div className="container-home-2" key={index}>
                  <div className="container-home-3">
                    <div
                      className="container-home-mobile-2"
                      onClick={() => clickViewImage(item?.product_picture)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_HOST}/images/${
                          JSON.parse(item?.product_picture)[0]
                        }`}
                        height={100}
                        width={100}
                        alt="logo"
                        className="logo-image-home"
                      />
                      <div className="heard-home-12">
                        <div className="text-post-5">ดูเพิ่มเติม</div>
                        <img
                          src="./icon_18.png"
                          alt="view"
                          width={17}
                          height={15}
                          className="logo-phone-2"
                        />
                      </div>
                    </div>
                    <div className="container-home-5">
                      <div className="text-home-10">
                        แบรนด์: {item?.product_name_th}
                      </div>
                      <div className="text-home-10">
                        Batch: {item?.number_batch}
                      </div>
                      <div className="text-home-10">
                        จำนวน: {item?.product_amount} ถัง
                      </div>
                      <div className="text-home-10">
                        เฉดสี: {item?.product_shade}
                      </div>
                      <div className="text-home-10">
                        ขนาด: {item?.product_size}
                      </div>
                      <div className="text-home-10">
                        ร้านค้า: {item?.companyname}
                      </div>
                      <div className="text-home-10">
                        จังหวัด: {item?.provincename}
                      </div>
                      <div className="text-home-10">
                        เขต: {item?.districtname}
                      </div>
                    </div>
                  </div>
                  <div className="text-home-24">
                    {item?.message_detail.length > 0
                      ? "*หมายเหตุ: " + item?.message_detail
                      : ""}
                  </div>
                  <div className="container-home-11">
                    <div
                      className="container-home-4"
                      onClick={() => clickPhone(item)}
                    >
                      <img
                        src="./icon_8.png"
                        alt="phone"
                        width={15}
                        height={14}
                        className="logo-phone"
                      />
                      <div className="text-home-5">โทร: {item?.tel}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bottom-login">
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <div className="text-home-16">ไม่พบข้อมูล</div>
              )}
            </div>
          )}
          {/* end body data */}
        </div>
      ) : (
        viewall === "สีพ่นอุตสาหกรรม" && (
          <div className="body-home-2">
            {/* start body data */}
            {dataspraypain?.length > 0 ? (
              dataspraypain.map((item, index) => {
                return (
                  <div className="container-home-2" key={index}>
                    <div className="container-home-3">
                      <div
                        className="container-home-mobile-2"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={100}
                          width={100}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="container-home-5">
                        <div className="text-home-10">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="text-home-10">
                          Batch: {item?.number_batch}
                        </div>
                        <div className="text-home-10">
                          จำนวน: {item?.product_amount} ถัง
                        </div>
                        <div className="text-home-10">
                          เฉดสี: {item?.product_shade}
                        </div>
                        <div className="text-home-10">
                          ขนาด: {item?.product_size}
                        </div>
                        <div className="text-home-10">
                          ร้านค้า: {item?.companyname}
                        </div>
                        <div className="text-home-10">
                          จังหวัด: {item?.provincename}
                        </div>
                        <div className="text-home-10">
                          เขต: {item?.districtname}
                        </div>
                      </div>
                    </div>
                    <div className="text-home-24">
                      {item?.message_detail.length > 0
                        ? "*หมายเหตุ: " + item?.message_detail
                        : ""}
                    </div>
                    <div className="container-home-11">
                      <div
                        className="container-home-4"
                        onClick={() => clickPhone(item)}
                      >
                        <img
                          src="./icon_8.png"
                          alt="phone"
                          width={15}
                          height={14}
                          className="logo-phone"
                        />
                        <div className="text-home-5">โทร: {item?.tel}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bottom-login">
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <div className="text-home-16">ไม่พบข้อมูล</div>
                )}
              </div>
            )}
            {/* end body data */}
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="heard-home-3">
          <div className="text-home-8">สเปรย์</div>
          <div className="text-home-9" onClick={() => setViewAll("สเปรย์")}>
            ดูทั้งหมด
          </div>
        </div>
      ) : (
        viewall === "สเปรย์" && (
          <div className="heard-home-3">
            <div className="text-home-8">สเปรย์</div>
            <div className="text-home-28" onClick={() => setViewAll("")}>
              x
            </div>
          </div>
        )
      )}

      {viewall === "" ? (
        <div className="body-home">
          {/* start body data */}
          {dataspray?.length > 0 ? (
            dataspray.map((item, index) => {
              return (
                <div className="container-home-2" key={index}>
                  <div className="container-home-3">
                    <div
                      className="container-home-mobile-2"
                      onClick={() => clickViewImage(item?.product_picture)}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_HOST}/images/${
                          JSON.parse(item?.product_picture)[0]
                        }`}
                        height={100}
                        width={100}
                        alt="logo"
                        className="logo-image-home"
                      />
                      <div className="heard-home-12">
                        <div className="text-post-5">ดูเพิ่มเติม</div>
                        <img
                          src="./icon_18.png"
                          alt="view"
                          width={17}
                          height={15}
                          className="logo-phone-2"
                        />
                      </div>
                    </div>
                    <div className="container-home-5">
                      <div className="text-home-10">
                        แบรนด์: {item?.product_name_th}
                      </div>
                      <div className="text-home-10">
                        Batch: {item?.number_batch}
                      </div>
                      <div className="text-home-10">
                        จำนวน: {item?.product_amount} ถัง
                      </div>
                      <div className="text-home-10">
                        เฉดสี: {item?.product_shade}
                      </div>
                      <div className="text-home-10">
                        ขนาด: {item?.product_size}
                      </div>
                      <div className="text-home-10">
                        ร้านค้า: {item?.companyname}
                      </div>
                      <div className="text-home-10">
                        จังหวัด: {item?.provincename}
                      </div>
                      <div className="text-home-10">
                        เขต: {item?.districtname}
                      </div>
                    </div>
                  </div>
                  <div className="text-home-24">
                    {item?.message_detail.length > 0
                      ? "*หมายเหตุ: " + item?.message_detail
                      : ""}
                  </div>
                  <div className="container-home-11">
                    <div
                      className="container-home-4"
                      onClick={() => clickPhone(item)}
                    >
                      <img
                        src="./icon_8.png"
                        alt="phone"
                        width={15}
                        height={14}
                        className="logo-phone"
                      />
                      <div className="text-home-5">โทร: {item?.tel}</div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="bottom-login">
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                <div className="text-home-16">ไม่พบข้อมูล</div>
              )}
            </div>
          )}
          {/* end body data */}
        </div>
      ) : (
        viewall === "สเปรย์" && (
          <div className="body-home-2">
            {/* start body data */}
            {dataspray?.length > 0 ? (
              dataspray.map((item, index) => {
                return (
                  <div className="container-home-2" key={index}>
                    <div className="container-home-3">
                      <div
                        className="container-home-mobile-2"
                        onClick={() => clickViewImage(item?.product_picture)}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_HOST}/images/${
                            JSON.parse(item?.product_picture)[0]
                          }`}
                          height={100}
                          width={100}
                          alt="logo"
                          className="logo-image-home"
                        />
                        <div className="heard-home-12">
                          <div className="text-post-5">ดูเพิ่มเติม</div>
                          <img
                            src="./icon_18.png"
                            alt="view"
                            width={17}
                            height={15}
                            className="logo-phone-2"
                          />
                        </div>
                      </div>
                      <div className="container-home-5">
                        <div className="text-home-10">
                          แบรนด์: {item?.product_name_th}
                        </div>
                        <div className="text-home-10">
                          Batch: {item?.number_batch}
                        </div>
                        <div className="text-home-10">
                          จำนวน: {item?.product_amount} ถัง
                        </div>
                        <div className="text-home-10">
                          เฉดสี: {item?.product_shade}
                        </div>
                        <div className="text-home-10">
                          ขนาด: {item?.product_size}
                        </div>
                        <div className="text-home-10">
                          ร้านค้า: {item?.companyname}
                        </div>
                        <div className="text-home-10">
                          จังหวัด: {item?.provincename}
                        </div>
                        <div className="text-home-10">
                          เขต: {item?.districtname}
                        </div>
                      </div>
                    </div>
                    <div className="text-home-24">
                      {item?.message_detail.length > 0
                        ? "*หมายเหตุ: " + item?.message_detail
                        : ""}
                    </div>
                    <div className="container-home-11">
                      <div
                        className="container-home-4"
                        onClick={() => clickPhone(item)}
                      >
                        <img
                          src="./icon_8.png"
                          alt="phone"
                          width={15}
                          height={14}
                          className="logo-phone"
                        />
                        <div className="text-home-5">โทร: {item?.tel}</div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bottom-login">
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  <div className="text-home-16">ไม่พบข้อมูล</div>
                )}
              </div>
            )}
            {/* end body data */}
          </div>
        )
      )}
    </div>
  );
};

export default HomeMobilePage;
