import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import { announce, upload, bybarcode } from "../api/productApi";
import { refreshtoken, logout, forgotpasswordauthen } from "../api/authApi";
import { Spinner } from "react-bootstrap";

const MenuBarDesktop = ({ getdata }) => {
  const history = useHistory();
  const [barcode, setBarcode] = useState("");
  const [nameproductth, setNameProductTh] = useState("");
  const [nameproducten, setNameProductEn] = useState("");
  const [sizeproduct, setSizeProduct] = useState("");
  const [shadeproduct, setShadeProduct] = useState("");
  const [amountproduct, setAmountProduct] = useState("");
  const [numberbatchproduct, setNumberBatchProduct] = useState("");
  const [selectprovin, setSelectProvin] = useState({});
  const [selectdistrict, setSelectDistrict] = useState({});
  const [salesgroupid, setSalesGroupId] = useState("");
  const [transporttype, setTranSportType] = useState("destination");
  const [messagedetail, setMessageDetail] = useState("");
  const [storetel, setStoreTel] = useState("");
  const [showmodal, setShowModal] = useState(false);
  const [showmodalnewpassword, setShowModalNewPassword] = useState(false);
  const [showmodalprofile, setShowModalProfile] = useState(false);
  const [imageupload, setImageUpload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState(false);
  const [logoutmodal, setLogOutModal] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [profile, setProfile] = useState({});
  const fileinputref = useRef(null);
  let controllerRef = null;
  controllerRef = new AbortController();

  const customstyles = {
    control: (provided, state) => ({
      ...provided,
      marginTop: "20px",
      borderRadius: "10px",
      border: "1px solid #b5adad",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 235,
      height: 40,
      fontSize: 14,
      fontFamily: "Kanit",
      "&:hover": {
        border: "2px solid #1C5BBD",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#1C5BBD" : "white",
      fontFamily: "Kanit",
      fontSize: 14,
      color: state.isSelected ? "white" : "#1C5BBD",
      "&:hover": {
        backgroundColor: "#1C5BBD",
        color: "white",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#1C5BBD",
    }),
  };

  const changePassword = (e) => {
    try {
      setPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeConfirmPassword = (e) => {
    try {
      setConfirmPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirm = async () => {
    try {
      if (password === "" || password === null || password.length < 6) {
        return toast.error("กรุณาระบุรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (
        confirmpassword === "" ||
        confirmpassword === null ||
        confirmpassword.length < 6
      ) {
        return toast.error("กรุณาระบุยืนยันรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      await refreshtoken(controllerRef?.signal);
      const resForgotPassword = await forgotpasswordauthen(
        password,
        confirmpassword
      );
      if (resForgotPassword?.status_code === 200) {
        setLoading(false);
        setShowModalNewPassword(false);
        toast.success(resForgotPassword?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickHome = () => {
    try {
      history.replace("/home");
    } catch (error) {
      console.log(error);
    }
  };

  const clickHistory = () => {
    try {
      history.replace("/history");
    } catch (error) {
      console.log(error);
    }
  };

  // const clickHowToUse = () => {
  //   try {
  //     // history.replace("/howtouse");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const clickUsagePolicy = () => {
  //   try {
  //     // history.replace("/usagepolicy");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const clickPostProduct = () => {
    try {
      setShowModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeBarcode = async (e) => {
    try {
      setBarcode(e.target.value);
      if (e.target.value.length > 12) {
        await refreshtoken(controllerRef?.signal);
        const data_bybarcode = await bybarcode(e.target.value);
        if (data_bybarcode) {
          setNameProductTh(data_bybarcode?.product_name_th);
          setNameProductEn(data_bybarcode?.product_name_en);
          setSizeProduct(data_bybarcode?.product_size_th);
          setShadeProduct(data_bybarcode?.product_shade);
        } else {
          setBarcode("");
          setNameProductTh("");
          setNameProductEn("");
          setSizeProduct("");
          setShadeProduct("");
          return toast.error("ไม่พบรหัส Barcode นี้ในระบบ!", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      } else {
        setNameProductTh("");
        setNameProductEn("");
        setSizeProduct("");
        setShadeProduct("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelPost = () => {
    try {
      setBarcode("");
      setNameProductTh("");
      setNameProductEn("");
      setSizeProduct("");
      setShadeProduct("");
      setAmountProduct("");
      setNumberBatchProduct("");
      setTranSportType("destination");
      setMessageDetail("");
      setImageUpload([]);
      setShowModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const loadImageAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        let base64Image = e.target.result;
        await refreshtoken(controllerRef?.signal);
        const resPhoto = await upload(base64Image);
        resolve(resPhoto?.data);
      };
      reader.readAsDataURL(file);
    });
  };

  const clickConfirmPost = async () => {
    try {
      if (imageupload.length < 1) {
        return toast.error("อัพโหลดรูปภาพอย่างน้อย 1 รูป!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (barcode === "" || barcode === null || barcode.length < 13) {
        return toast.error("กรุณาระบุบาร์โค้ดสินค้า!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (amountproduct === "" || amountproduct === null || amountproduct < 1) {
        return toast.error("กรุณาระบุจำนวน!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (numberbatchproduct === "" || numberbatchproduct === null) {
        return toast.error("กรุณาระบุเลขที่ Batch!", {
          position: "top-center",
          autoClose: 3000,
        });
      }

      setLoading(true);

      let data_images = [];
      // eslint-disable-next-line no-unused-vars
      for await (const [_, r] of imageupload.entries()) {
        const resImage = await loadImageAsync(r);
        data_images.push(resImage);
      }

      const resAnnounce = await announce(
        barcode,
        nameproductth,
        nameproducten,
        sizeproduct,
        shadeproduct,
        amountproduct,
        numberbatchproduct,
        salesgroupid,
        selectprovin?.value,
        selectdistrict?.value,
        transporttype === "destination" ? 1 : 2,
        messagedetail,
        storetel,
        JSON.stringify(data_images)
      );

      if (resAnnounce?.status_code === 201) {
        setBarcode("");
        setNameProductTh("");
        setNameProductEn("");
        setSizeProduct("");
        setShadeProduct("");
        setAmountProduct("");
        setNumberBatchProduct("");
        setTranSportType("destination");
        setMessageDetail("");
        setImageUpload([]);
        setLoading(false);
        setShowModal(false);
        getdata();
        toast.success(resAnnounce?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const clickMenu = () => {
    try {
      setMenu(!menu);
    } catch (error) {
      console.log(error);
    }
  };

  const getDataDefault = async () => {
    try {
      const profile = await localStorage.getItem("profile");
      if (profile) {
        setProfile(JSON.parse(profile));
        setSelectProvin({
          value: JSON.parse(profile)?.token?.province_id,
          label: JSON.parse(profile)?.province_name,
        });
        setSelectDistrict({
          value: JSON.parse(profile)?.token?.district_id,
          label: JSON.parse(profile)?.district_name,
        });
        setSalesGroupId(JSON.parse(profile)?.token?.sales_group_id);
        setStoreTel(JSON.parse(profile)?.token?.tel);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataDefault();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickConfirmLogout = async () => {
    try {
      await refreshtoken(controllerRef?.signal);
      await logout();
      setMenu(!menu);
      setLogOutModal(false);
      history.replace("/");
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelLogout = () => {
    try {
      setLogOutModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const clickUploadImage = () => {
    try {
      fileinputref.current.click();
    } catch (error) {
      console.log(error);
    }
  };

  const clickShowFile = async (event) => {
    try {
      let lastArray = [...event.target.files];
      if (lastArray?.length > 0) {
        let data_ok = [];
        // eslint-disable-next-line no-unused-vars
        for await (const [_, r] of lastArray.entries()) {
          if (r?.size / 1024 / 1024 > 2) {
            document.getElementById("fileInput").value = "";
            return toast.error("ขนาดรูปภาพเกิน 2MB!", {
              position: "top-center",
              autoClose: 3000,
            });
          } else if (
            r?.type !== "image/jpg" &&
            r?.type !== "image/jpeg" &&
            r?.type !== "image/png"
          ) {
            return toast.error("รองรับเฉพาะ .jpg, .jpeg, .png!", {
              position: "top-center",
              autoClose: 3000,
            });
          } else {
            data_ok.push(r);
          }
        }
        let clone_data = [...imageupload];
        const new_data = data_ok.concat(clone_data);
        if (new_data?.length > 6) {
          document.getElementById("fileInput").value = "";
          return toast.error("เพิ่มรูปภาพสูงสุด 6 รูปเท่านั้น!", {
            position: "top-center",
            autoClose: 3000,
          });
        } else {
          setImageUpload(new_data);
        }
      }
      document.getElementById("fileInput").value = "";
    } catch (error) {
      console.log(error);
    }
  };

  const clickDeleteImage = (index) => {
    try {
      let clone_data = [...imageupload];
      clone_data.splice(index, 1);
      setImageUpload(clone_data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="heard-home-desktop">
      <ToastContainer />
      {/* start modal post */}
      <Modal
        show={showmodal}
        onHide={() => setShowModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">โพสต์สินค้า</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-post">
            <div
              className={
                imageupload?.length > 2 ? "image-menu" : "image-menu-disable"
              }
            >
              {/* File input element hidden by default */}
              <input
                type="file"
                id="fileInput"
                ref={fileinputref}
                style={{ display: "none" }}
                onChange={clickShowFile}
                multiple
              />
              {/* Image element acting as the clickable button */}
              <div className="container-post-image-2">
                <img
                  src="../../icon_6.png"
                  alt="Upload"
                  height={130}
                  width={140}
                  onClick={clickUploadImage}
                  style={{ cursor: "pointer" }}
                  className="image-menu-5"
                />
                {imageupload?.length === 0 && (
                  <div className="text-post-image">
                    *หมายเหตุ กรุณาแนบรูปภาพ
                  </div>
                )}
              </div>
              {imageupload?.length > 0
                ? imageupload?.map((item, index) => {
                    return (
                      <div className="image-menu-2" key={index}>
                        <img
                          src="../../icon_19.png"
                          height={20}
                          width={28}
                          alt="delete"
                          className="image-menu-4"
                          onClick={() => clickDeleteImage(index)}
                        />
                        <img
                          src={URL.createObjectURL(item)}
                          height={130}
                          width={140}
                          alt="upload"
                          className="image-menu-3"
                        />
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="text-post-1">รายละเอียดสินค้า</div>
            <div className="input-post">
              <input
                type="text"
                className={
                  barcode.length < 13 ? "input-post-1-error" : "input-post-1"
                }
                placeholder="รหัส Barcode"
                value={barcode}
                onChange={onChangeBarcode}
                maxLength={13}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="ชื่อสินค้า"
                disabled
                value={nameproductth}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="ขนาด"
                disabled
                value={sizeproduct}
              />
              <input
                type="text"
                className="input-post-1"
                placeholder="เฉดสี"
                disabled
                value={shadeproduct}
              />
              <input
                type="text"
                className={
                  amountproduct < 1 ? "input-post-1-error" : "input-post-1"
                }
                placeholder="จำนวน"
                maxLength={5}
                value={amountproduct}
                min={1}
                onChange={(e) => {
                  if (!e.target.value.match(/^([0-9])+$/i)) {
                    setAmountProduct("");
                  } else {
                    setAmountProduct(e.target.value);
                  }
                }}
              />
              <input
                type="text"
                className={
                  numberbatchproduct === ""
                    ? "input-post-1-error"
                    : "input-post-1"
                }
                placeholder="เลขที่ Batch"
                maxLength={20}
                value={numberbatchproduct}
                onChange={(e) => setNumberBatchProduct(e.target.value)}
              />
            </div>
            <div className="text-post-2">ระบุเขต</div>
            <div className="input-post">
              <Select
                defaultValue={selectprovin}
                value={selectprovin}
                styles={customstyles}
                isDisabled={true}
              />
              <Select
                defaultValue={selectdistrict}
                value={selectdistrict}
                styles={customstyles}
                isDisabled={true}
              />
            </div>
            <div className="text-post-3">รายละเอียดการจัดส่ง</div>
            <div className="body-select-1">
              {transporttype === "destination" ? (
                <div className="body-select-2">
                  <img
                    src="./icon_16.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-8">
                      ร้านค้าต้นทางจัดส่งสินค้าให้
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="body-select-4"
                  onClick={() => setTranSportType("destination")}
                >
                  <img
                    src="./icon_16.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-5">
                      ร้านค้าต้นทางจัดส่งสินค้าให้
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="body-select-1">
              {transporttype === "yourself" ? (
                <div className="body-select-2">
                  <img
                    src="./icon_17.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-8">ร้านค้าปลายทางมารับสินค้า</div>
                  </div>
                </div>
              ) : (
                <div
                  className="body-select-4"
                  onClick={() => setTranSportType("yourself")}
                >
                  <img
                    src="./icon_17.png"
                    alt="logo"
                    width={40}
                    height={40}
                    className="icon-post"
                  />
                  <div className="body-select-3">
                    <div className="text-post-5">ร้านค้าปลายทางมารับสินค้า</div>
                  </div>
                </div>
              )}
            </div>
            <div className="body-select-5">
              <textarea
                className="textarea-post-1"
                placeholder="ข้อความเพิ่มเติม"
                rows="4"
                cols="50"
                value={messagedetail}
                maxLength={250}
                onChange={(e) => setMessageDetail(e.target.value)}
              />
            </div>
            <div className="body-select-6">
              <div className="body-select-7">
                <div className="text-post-5">กดปุ่ม</div>
                <div className="text-post-6">โพสต์สินค้า</div>
                <div className="text-post-5">เพื่อยอมรับ</div>
              </div>
              <div className="text-post-5">เงื่อนไขของการแลกเปลี่ยนสินค้า</div>
            </div>
            <div className="body-select-8">
              <button
                className="btn-post-1"
                onClick={clickConfirmPost}
                disabled={loading}
              >
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "โพสต์สินค้า"
                )}
              </button>
              <button className="btn-post-2" onClick={clickCancelPost}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal post */}
      {/* start modal newpassword */}
      <Modal
        show={showmodalnewpassword}
        onHide={() => setShowModalNewPassword(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">New Password</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-home-desktop-2">
            <div className="content-home-desktop-1">
              <input
                type="password"
                className="input-resetpassword-3"
                placeholder="รหัสผ่าน *"
                value={password}
                onChange={changePassword}
              />
              <input
                type="password"
                className="input-resetpassword-2"
                placeholder="ยืนยันรหัสผ่าน *"
                value={confirmpassword}
                onChange={changeConfirmPassword}
              />
              <button
                className="btn-login-2"
                onClick={clickConfirm}
                disabled={loading}
              >
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "ยืนยัน"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal newpassword */}
      {/* start modal profile */}
      <Modal
        show={showmodalprofile}
        onHide={() => setShowModalProfile(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">Profile</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">รหัสร้านค้า :</div>
            <div className="content-home-desktop-text-1">
              {profile?.token?.bp_code}
            </div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">ชื่อร้านค้า :</div>
            <div className="content-home-desktop-text-1">
              {profile?.token?.name}
            </div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">Email :</div>
            <div className="content-home-desktop-text-1">
              {profile?.token?.email}
            </div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">เบอร์โทรติดต่อ :</div>
            <div className="content-home-desktop-text-1">
              {profile?.token?.tel}
            </div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">จังหวัด :</div>
            <div className="content-home-desktop-text-1">
              {profile?.province_name}
            </div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">เขต/อำเภอ :</div>
            <div className="content-home-desktop-text-1">
              {profile?.district_name}
            </div>
          </div>
          <div className="content-home-desktop-4">
            <button
              className="btn-login-2"
              onClick={() => setShowModalProfile(false)}
            >
              ปิด
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal profile */}
      {/* start modal logout */}
      <Modal
        show={logoutmodal}
        onHide={() => setLogOutModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              <div className="text-home-20">คุณต้องการออกจากระบบ?</div>
              <button className="btn-post-1" onClick={clickConfirmLogout}>
                ยืนยัน
              </button>
              <button className="btn-post-2" onClick={clickCancelLogout}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal logout */}
      <img
        src="./logo_1.png"
        alt="logo"
        width={50}
        height={42}
        className="logo-toa"
      />
      <div className="text-home-11" onClick={clickHome}>
        หน้าหลัก
      </div>
      <div className="text-home-11" onClick={clickHistory}>
        ประวัติการโพสต์
      </div>
      {/* <div className="text-home-11" onClick={clickHowToUse}>
        วิธีการใช้งาน
      </div>
      <div className="text-home-11" onClick={clickUsagePolicy}>
        นโยบายการใช้งาน
      </div> */}
      <button className="btn-post-home-1" onClick={clickPostProduct}>
        โพสต์สินค้า
      </button>
      <img
        src="./icon_9.png"
        alt="logo"
        width={30}
        height={27}
        className="logo-profile"
        onClick={clickMenu}
      />
      <div
        className="container-home-8"
        style={{ display: menu === true ? "block" : "none" }}
      >
        <div className="heard-home-11">
          <img
            src="./icon_13.png"
            alt="logo"
            height={16}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
              setShowModalProfile(true);
            }}
          >
            โปรไฟล์
          </div>
        </div>
        <div className="heard-home-11">
          <img
            src="./icon_14.png"
            alt="logo"
            width={20}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
              setPassword("");
              setConfirmPassword("");
              setShowModalNewPassword(true);
            }}
          >
            เปลี่ยนรหัสผ่าน
          </div>
        </div>
        <div className="heard-home-11">
          <img
            src="./icon_15.png"
            alt="logo"
            height={20}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
              setLogOutModal(true);
            }}
          >
            ออกจากระบบ
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBarDesktop;
