import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { refreshtoken, forgotpasswordemployeeauthen } from "../api/authApi";
import { Spinner } from "react-bootstrap";

const MenuBarEmployeeDesktop = () => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [logoutmodal, setLogOutModal] = useState(false);
  const [showmodalnewpassword, setShowModalNewPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [showmodalprofile, setShowModalProfile] = useState(false);
  const [profile, setProfile] = useState({});
  let controllerRef = null;
  controllerRef = new AbortController();

  const getDataDefault = async () => {
    try {
      const resProfile = await localStorage.getItem("profile");
      if (resProfile) {
        setProfile(JSON.parse(resProfile));
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataDefault();
    return () => controllerRef?.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const clickHome = () => {
  //   try {
  //     history.replace("/employeehome");
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const clickMenu = () => {
    try {
      setMenu(!menu);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirmLogout = async () => {
    try {
      setMenu(!menu);
      setLogOutModal(false);
      history.replace("/employee");
    } catch (error) {
      console.log(error);
    }
  };

  const clickCancelLogout = () => {
    try {
      setLogOutModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const changePassword = (e) => {
    try {
      setPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeConfirmPassword = (e) => {
    try {
      setConfirmPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirm = async () => {
    try {
      if (password === "" || password === null || password.length < 6) {
        return toast.error("กรุณาระบุรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (
        confirmpassword === "" ||
        confirmpassword === null ||
        confirmpassword.length < 6
      ) {
        return toast.error("กรุณาระบุยืนยันรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      await refreshtoken(controllerRef?.signal);
      const resForgotPassword = await forgotpasswordemployeeauthen(
        password,
        confirmpassword
      );
      if (resForgotPassword?.status_code === 200) {
        setLoading(false);
        setShowModalNewPassword(false);
        toast.success(resForgotPassword?.message, {
          position: "top-center",
          autoClose: 3000,
        });
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="heard-home-desktop">
      <ToastContainer />
      {/* start modal profile */}
      <Modal
        show={showmodalprofile}
        onHide={() => setShowModalProfile(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">Profile</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">รหัสพนักงาน :</div>
            <div className="content-home-desktop-text-1">{profile?.emp_id}</div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">ชื่อ-นามสกุล :</div>
            <div className="content-home-desktop-text-1">
              {profile?.firstname + " " + profile?.lastname}
            </div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">Email :</div>
            <div className="content-home-desktop-text-1">{profile?.email}</div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">ตำแหน่ง :</div>
            <div className="content-home-desktop-text-1">
              {profile?.position}
            </div>
          </div>
          <div className="content-home-desktop-3">
            <div className="content-home-desktop-text-1">กลุ่มงานขาย :</div>
            <div className="content-home-desktop-text-1">
              {profile?.sales_group_description}
            </div>
          </div>
          <div className="content-home-desktop-4">
            <button
              className="btn-login-2"
              onClick={() => setShowModalProfile(false)}
            >
              ปิด
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal profile */}
      {/* start modal newpassword */}
      <Modal
        show={showmodalnewpassword}
        onHide={() => setShowModalNewPassword(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="text-home-21">New Password</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content-home-desktop-2">
            <div className="content-home-desktop-1">
              <input
                type="password"
                className="input-resetpassword-3"
                placeholder="รหัสผ่าน *"
                value={password}
                onChange={changePassword}
              />
              <input
                type="password"
                className="input-resetpassword-2"
                placeholder="ยืนยันรหัสผ่าน *"
                value={confirmpassword}
                onChange={changeConfirmPassword}
              />
              <button
                className="btn-login-2"
                onClick={clickConfirm}
                disabled={loading}
              >
                {loading === true ? (
                  <Spinner animation="border" variant="primary" />
                ) : (
                  "ยืนยัน"
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal newpassword */}
      {/* start modal logout */}
      <Modal
        show={logoutmodal}
        onHide={() => setLogOutModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="content-post">
            <div className="body-select-8">
              <div className="text-home-20">คุณต้องการออกจากระบบ?</div>
              <button className="btn-post-1" onClick={clickConfirmLogout}>
                ยืนยัน
              </button>
              <button className="btn-post-2" onClick={clickCancelLogout}>
                ยกเลิก
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* end modal logout */}
      <img
        src="./logo_1.png"
        alt="logo"
        width={50}
        height={42}
        className="logo-toa"
      />
      {/* <div className="text-home-11" onClick={clickHome}>
        หน้าหลัก
      </div> */}
      <img
        src="./icon_9.png"
        alt="logo"
        width={30}
        height={27}
        className="logo-profile"
        onClick={clickMenu}
      />
      <div
        className="container-home-8"
        style={{ display: menu === true ? "block" : "none" }}
      >
        <div className="heard-home-11">
          <img
            src="./icon_13.png"
            alt="logo"
            height={16}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={async () => {
              setMenu(false);
              setShowModalProfile(true);
            }}
          >
            โปรไฟล์
          </div>
        </div>
        <div className="heard-home-11">
          <img
            src="./icon_14.png"
            alt="logo"
            width={20}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
              setPassword("");
              setConfirmPassword("");
              setShowModalNewPassword(true);
            }}
          >
            เปลี่ยนรหัสผ่าน
          </div>
        </div>
        <div className="heard-home-11">
          <img
            src="./icon_15.png"
            alt="logo"
            height={20}
            className="logo-image-profile"
          />
          <div
            className="text-home-20"
            onClick={() => {
              setMenu(false);
              setLogOutModal(true);
            }}
          >
            ออกจากระบบ
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuBarEmployeeDesktop;
