import axios from "axios";
const prefix = "admins";

const STORE_URL = `${prefix}/store`;
const PROVINCE_URL = `${prefix}/province-admin`;
const DISTRICT_URL = `${prefix}/district-admin`;
const SALESGROUP_URL = `${prefix}/sales-group-admin`;
const REGISTERSTORE_URL = `${prefix}/register-store`;
const UPDATESTATUSSTORE_URL = `${prefix}/update-status-store`;
const UPDATESTATUSEMPLOYEE_URL = `${prefix}/update-status-employee`;
const DELETESTORE_URL = `${prefix}/delete-store`;
const DELETEEMPLOYEE_URL = `${prefix}/delete-employee`;
const UPDATESTORE_URL = `${prefix}/update-store`;
const BRANDADMIN_URL = `${prefix}/brand-admin`;
const FILMADMIN_URL = `${prefix}/film-admin`;
const MASTERADMIN_URL = `${prefix}/master-admin`;
const SHADEADMIN_URL = `${prefix}/shade-admin`;
const SIZEADMIN_URL = `${prefix}/size-admin`;
const IMPORTPRODUCTADMIN_URL = `${prefix}/import-product-admin`;
const IMPORTBRANDADMIN_URL = `${prefix}/import-brand-admin`;
const IMPORTFILMADMIN_URL = `${prefix}/import-film-admin`;
const IMPORTSIZEADMIN_URL = `${prefix}/import-size-admin`;
const IMPORTSHADEADMIN_URL = `${prefix}/import-shade-admin`;
const IMPORTSALESGROUPADMIN_URL = `${prefix}/import-sales-group-admin`;
const REGISTEREMPLOYEE_URL = `${prefix}/register-employee`;
const EMPLOYEE_URL = `${prefix}/employee`;
const UPDATEEMPLOYEE_URL = `${prefix}/update-employee`;
const FORGOT_PASSWORD_ADMIN_AUTHEN_URL = `${prefix}/forgot-password-admin-authen`;
const REPORTPHONE_URL = `${prefix}/report-phone`;
const REPORTINOUT_URL = `${prefix}/report-inout`;

export async function store(signal) {
  try {
    const { data } = await axios.get(STORE_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function provinceadmin(signal) {
  try {
    const { data } = await axios.get(PROVINCE_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function districtadmin(signal) {
  try {
    const { data } = await axios.get(DISTRICT_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function salesgroupadmin(signal) {
  try {
    const { data } = await axios.get(SALESGROUP_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function registerstore(
  numberstore,
  passwordstore,
  namestore,
  emailstore,
  phonestore,
  selectgroupsale,
  selectprovince,
  selectdistrict,
  statusstore
) {
  try {
    const { data } = await axios.post(REGISTERSTORE_URL, {
      bp_code: numberstore,
      name: namestore,
      email: emailstore,
      password: passwordstore,
      tel: phonestore,
      sales_group_id: selectgroupsale?.value,
      sales_group_description: selectgroupsale?.label,
      sales_distric: selectdistrict?.label,
      district_id: selectdistrict?.value,
      province_id: selectprovince?.value,
      status: statusstore === true ? 1 : 0,
    });
    if (data?.status_code === 201) {
      return {
        status_code: 200,
        message: "บันทึกสำเร็จ",
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return error;
  }
}

export async function updatestatusstore(id, status, signal) {
  try {
    const { data } = await axios.put(UPDATESTATUSSTORE_URL, {
      id,
      status,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function updatestatusemployee(id, status, signal) {
  try {
    const { data } = await axios.put(UPDATESTATUSEMPLOYEE_URL, {
      id,
      status,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function deletestore(id, signal) {
  try {
    const { data } = await axios.put(DELETESTORE_URL, {
      id,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function deleteemployee(id, signal) {
  try {
    const { data } = await axios.put(DELETEEMPLOYEE_URL, {
      id,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function updatestore(
  numberstore,
  passwordstore,
  namestore,
  emailstore,
  phonestore,
  selectgroupsale,
  selectprovince,
  selectdistrict,
  statusstore
) {
  try {
    const { data } = await axios.put(UPDATESTORE_URL, {
      bp_code: numberstore,
      name: namestore,
      email: emailstore,
      password: passwordstore,
      tel: phonestore,
      sales_group_id: selectgroupsale?.value,
      sales_group_description: selectgroupsale?.label,
      sales_distric: selectdistrict?.label,
      district_id: selectdistrict?.value,
      province_id: selectprovince?.value,
      status: statusstore === true ? 1 : 0,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function brandadmin(signal) {
  try {
    const { data } = await axios.get(BRANDADMIN_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function filmadmin(signal) {
  try {
    const { data } = await axios.get(FILMADMIN_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function masteradmin(signal) {
  try {
    const { data } = await axios.get(MASTERADMIN_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function shadeadmin(signal) {
  try {
    const { data } = await axios.get(SHADEADMIN_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function sizeadmin(signal) {
  try {
    const { data } = await axios.get(SIZEADMIN_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function importproductadmin(dataimport, signal) {
  try {
    const { data } = await axios.post(IMPORTPRODUCTADMIN_URL, {
      dataimport,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function importbrandadmin(dataimport, signal) {
  try {
    const { data } = await axios.post(IMPORTBRANDADMIN_URL, {
      dataimport,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function importfilmadmin(dataimport, signal) {
  try {
    const { data } = await axios.post(IMPORTFILMADMIN_URL, {
      dataimport,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function importsizeadmin(dataimport, signal) {
  try {
    const { data } = await axios.post(IMPORTSIZEADMIN_URL, {
      dataimport,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function importshadeadmin(dataimport, signal) {
  try {
    const { data } = await axios.post(IMPORTSHADEADMIN_URL, {
      dataimport,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function importsalesgroupadmin(dataimport, signal) {
  try {
    const { data } = await axios.post(IMPORTSALESGROUPADMIN_URL, {
      dataimport,
      signal,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function registeremployee(
  emp_id,
  password,
  firstname,
  lastname,
  email,
  selectlevel,
  selectposition,
  selectgroupsale,
  statusemployee
) {
  try {
    const { data } = await axios.post(REGISTEREMPLOYEE_URL, {
      emp_id: emp_id,
      password: password,
      firstname: firstname,
      lastname: lastname,
      email: email,
      level: selectlevel?.label,
      position: selectposition?.label,
      sales_group_id: selectgroupsale?.value,
      sales_group_description: selectgroupsale?.label,
      status: statusemployee === true ? 1 : 0,
    });
    if (data?.status_code === 201) {
      return {
        status_code: 200,
        message: "บันทึกสำเร็จ",
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return error;
  }
}

export async function employee(signal) {
  try {
    const { data } = await axios.get(EMPLOYEE_URL, {
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function updateemployee(
  emp_id,
  password,
  firstname,
  lastname,
  email,
  level,
  position,
  selectgroupsale,
  statusemployee
) {
  try {
    const { data } = await axios.put(UPDATEEMPLOYEE_URL, {
      emp_id: emp_id,
      password: password,
      firstname: firstname,
      lastname: lastname,
      email: email,
      level: level,
      position: position,
      sales_group_id: selectgroupsale?.value,
      sales_group_description: selectgroupsale?.label,
      status: statusemployee === true ? 1 : 0,
    });
    if (data?.status_code === 200) {
      return data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function forgotpasswordadminauthen(
  new_password,
  confirm_new_password
) {
  try {
    const { data } = await axios.post(FORGOT_PASSWORD_ADMIN_AUTHEN_URL, {
      new_password: new_password,
      confirm_new_password: confirm_new_password,
    });
    if (data?.status_code === 200) {
      return {
        status_code: 200,
        message: "แก้ไขข้อมูลสำเร็จ",
      };
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function reportphone(startdate, enddate, signal) {
  try {
    const { data } = await axios.post(REPORTPHONE_URL, {
      startdate,
      enddate,
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}

export async function reportinout(startdate, enddate, signal) {
  try {
    const { data } = await axios.post(REPORTINOUT_URL, {
      startdate,
      enddate,
      signal,
    });
    if (data?.status_code === 200) {
      return data?.data;
    } else {
      return undefined;
    }
  } catch (error) {
    return null;
  }
}
