import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { forgotpassword } from "../../api/authApi";
import { Spinner } from "react-bootstrap";

const NewPasswordDesktopPage = () => {
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const changePassword = (e) => {
    try {
      setPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const changeConfirmPassword = (e) => {
    try {
      setConfirmPassword(e.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const clickConfirm = async () => {
    try {
      if (password === "" || password === null || password.length < 6) {
        return toast.error("กรุณาระบุรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      if (
        confirmpassword === "" ||
        confirmpassword === null ||
        confirmpassword.length < 6
      ) {
        return toast.error("กรุณาระบุยืนยันรหัสผ่านมากกว่า 6 หลัก!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
      setLoading(true);
      const resForgotPassword = await forgotpassword(password, confirmpassword);
      if (resForgotPassword?.status_code === 200) {
        setLoading(false);
        toast.success(resForgotPassword?.message, {
          position: "top-center",
          autoClose: 3000,
        });
        setTimeout(() => {
          history.replace("/");
        }, 3000);
      } else {
        setLoading(false);
        return toast.error("ผิดพลาด กรุณาลองใหม่อีกครั้ง!", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <div id="newspassword-desktop">
      <ToastContainer />
      <div className="bg-login">
        <div className="bg-login-right">
          <div className="container-resetpassword-desktop">
            <div className="heard-resetpassword">
              <img
                src={`${process.env.REACT_APP_FND_HOST}/logo_1.png`}
                alt="logo"
                width={50}
                height={40}
                className="logo-login"
              />
              <div className="text-resetpassword-1">New Password</div>
            </div>
            <input
              type="password"
              className="input-resetpassword-2"
              placeholder="รหัสผ่าน *"
              value={password}
              onChange={changePassword}
            />
            <input
              type="password"
              className="input-resetpassword-2"
              placeholder="ยืนยันรหัสผ่าน *"
              value={confirmpassword}
              onChange={changeConfirmPassword}
            />
            <button
              className="btn-login-2"
              onClick={clickConfirm}
              disabled={loading}
            >
              {loading === true ? (
                <Spinner animation="border" variant="primary" />
              ) : (
                "ยืนยัน"
              )}
            </button>
          </div>
        </div>
        <div className="bg-login-left">
          <img
            src={`${process.env.REACT_APP_FND_HOST}/bg_2.png`}
            alt="logo"
            className="bg-login-desktop"
          />
        </div>
      </div>
    </div>
  );
};

export default NewPasswordDesktopPage;
